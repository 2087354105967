import { Layout, Flex } from "antd";
import chatButton from "../../Assets/Images/chat_button.svg";

import styles from './Headerr.module.scss';


const { Header } = Layout;

const Headerr = () => {
  return (
    <Header className={styles.container}>
      <Flex align="center" className={styles.headerTextContainer}>

      <div className={styles.headerText}>
        Partner Logo
      </div>
      <img src={chatButton} alt="chat_button" className={styles.chatBtn}/>
      </Flex>
      

    </Header>
  );
};

export default Headerr;
