import React, { useState } from "react";
import "./UnderstandingUpload.scss";

import upload_doc from "../../Assets/Images/upload_doc.png";
import upload1 from "../../Assets/Images/upload1.png";
import upload2 from "../../Assets/Images/upload2.png";
import upload3 from "../../Assets/Images/upload3.png";
import upload_done from "../../Assets/Images/upload_done.svg";
import no_license from "../../Assets/Images/no_license.png";
import { Layout } from "antd/lib";
import { Content } from "antd/lib/layout/layout";
import { Button, Checkbox, Flex } from "antd";
import FooterMain from "../Footer/FooterMain";

const UnderstandingUpload: React.FC = () => {
  const [isNoLicense, setIsNoLicense] = useState(false);
  const [step, setStep] = useState(0);

  return (
    <>
      <Layout
        className="understanding-upload-container"
        onClick={() => {
          if (step === 0) {
            setIsNoLicense(true);
            setStep((prev) => prev + 1);
          }
        }}
      >
        <Content className="layout">
          <h1 className="understanding-title">Understanding your business</h1>
          <div className="content-container">
            <div className="item">
              <Flex gap="10px" align="center" className="title-container">
                <h5>
                  {step === 0
                    ? "Please upload Articles of Organization"
                    : "Articles of Organization"}
                </h5>
                {step === 1 && <img src={upload_done} alt="upload_done" />}
              </Flex>

              <img
                className="img_main"
                src={step === 0 ? upload_doc : upload1}
                alt="upload_doc"
              />
            </div>
          
            {/* <div className="item">
              <Flex gap="10px" align="center" className="title-container">
                <h5>
                  {step === 0
                    ? "Please upload Certificate of Organization"
                    : "Certificate of Organization"}
                </h5>
                {step === 1 && <img src={upload_done} alt="upload_done" />}
              </Flex>

              <img className="img_main" src={upload_doc} alt="upload_doc" />
            </div>
            <div className="item">
              <Flex gap="10px" align="center" className="title-container">
                <h5>
                  {step === 0
                    ? "Please upload Certificate of Formation"
                    : "Certificate of Formation"}
                </h5>
                {step === 1 && <img src={upload_done} alt="upload_done" />}
              </Flex>

              <img className="img_main" src={upload_doc} alt="upload_doc" />
            </div>
            <div className="item">
              <Flex gap="10px" align="center" className="title-container">
                <h5 className={isNoLicense ? "inactive" : ""}>
                  {" "}
                  {isNoLicense
                    ? "Business License"
                    : "Please upload Articles of Organization"}
                </h5>
              </Flex>
              <img
                className="img_main"
                src={isNoLicense ? no_license : upload_doc}
                alt="upload_doc"
              />
              <Flex className="checkboxContainer">
                <label style={{ display: "flex" }}>
                  <Checkbox
                    checked={isNoLicense}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsNoLicense((prev) => !prev);
                    }}
                  />
                  <div className="checkboxLabel">No License required</div>
                </label>
              </Flex>
            </div> */}

            
          </div>
        </Content>
      </Layout>
      <FooterMain
        // nextPath="understandingbusinessscan"
        nextPath="beneficial"
        currentStep={6}
        totalSteps={8}
        isProgressBarEnabled={true}
        isConfirmBtnInactive={step === 0}
        nextButtonText="Next"
        name="Business documents"
      />
    </>
  );
};

export default UnderstandingUpload;
