import React, { useState } from "react";
import left_logo from "../../Assets/Images/left_logo.png";
import "./ResponsiveNoStepsHeader.scss";
import DeleteUserPopup from "../StartPage/DeleteUserPopup/DeleteUserPopup";
import { Row, Col } from "antd";
import goBackArrow from "../../Assets/Images/DashboardImages/gobackarrow.png";
import { useWindowSize } from "react-use";
import { useLocation } from "react-router-dom";
import { IResponsiveNoStepsHeader } from "../../Contracts/Contracts";

const ResponsiveNoStepsHeader = (props: IResponsiveNoStepsHeader) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const { width, height } = useWindowSize();
  const location = useLocation();

  const handleTripleClick = () => {
    if (
      window.location.href.includes("/letsstartphone") &&
      props?.phoneNumber?.length === 14
    ) {
      setClickCount(clickCount + 1);
      if (clickCount === 2) {
        setIsDeleteModalVisible(true);
        setClickCount(0);
      }
    }
  };

  return (
    <div>
      {width > 640 ? (
        <Row className="responsive-header">
          <Col flex="auto"></Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "0px"
                : width >= 1025 && width <= 1500
                ? "10%"
                : "170px"
            }
          >
            <div onClick={handleTripleClick}>
              <img className="headerlogoImg" src={left_logo} alt="Logo" />
            </div>
          </Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "80%"
                : width >= 1025 && width <= 1500
                ? "80%"
                : "789px"
            }
          >
            <div
              className={
                props?.titleClassName
                  ? `${props.titleClassName} nosteps-header-content`
                  : "nosteps-header-content"
              }
            >
              {props.title}
            </div>
          </Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "10%"
                : width >= 1025 && width <= 1500
                ? ""
                : "276px"
            }
          >
            <div onClick={handleTripleClick}>
              <img className="rightHeaderlogoImg" src={left_logo} alt="Logo" />
            </div>
          </Col>
          <Col flex="auto"></Col>
        </Row>
      ) : (
        <Row className="responsive-mobile-header">
          <Col span={12}>
            <img className="backButton" src={goBackArrow} alt="Logo" />
          </Col>
          <Col
            span={12}
            className="mobileresponsivelogocontainer"
            onClick={handleTripleClick}
          >
            <img className="responsivelogo" src={left_logo} alt="Logo" />
          </Col>
        </Row>
      )}
      {isDeleteModalVisible && (
        <DeleteUserPopup
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          phoneNumber={props.phoneNumber}
        />
      )}
    </div>
  );
};

export default ResponsiveNoStepsHeader;
