import { Layout, Row, Typography, Checkbox, Flex, Col } from "antd";

import FooterMain from "../Footer/FooterMain";
import "./ProductSelection.scss";

const { Content } = Layout;

const ProductSelection = () => {
  return (
    <>
      <Content className="product-selection layout">
        <Row className="container">
          <Typography.Title level={1} className="title">
            Would you be interested in learning more about the following
            products?
          </Typography.Title>
          <Flex className="checkbox">
            <Col className="checkbox__col" flex={1}>
              <Checkbox className="checkbox__item" value={1}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">Merchant Services</div>
                  <div className="checkbox__title-small">Description text</div>
                </div>
              </Checkbox>
              <Checkbox className="checkbox__item" value={2}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">
                    Business Remote Deposit
                  </div>
                  <div className="checkbox__title-small">Description text</div>
                </div>
              </Checkbox>
              <Checkbox className="checkbox__item" value={3}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">Payroll Services</div>
                  <div className="checkbox__title-small">Description text</div>
                </div>
              </Checkbox>
            </Col>
            <Col className="checkbox__col" flex={1}>
              <Checkbox className="checkbox__item" value={4}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">
                    Business VISA credit card
                  </div>
                  <div className="checkbox__title-small">Description text</div>
                </div>
              </Checkbox>
              <Checkbox className="checkbox__item" value={5}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">
                    Business Line of Credit
                  </div>
                  <div className="checkbox__title-small">Description text</div>
                </div>
              </Checkbox>
              <Checkbox className="checkbox__item" value={5}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">Positive Pay</div>
                  <div className="checkbox__title-small">Description text</div>
                </div>
              </Checkbox>
            </Col>
          </Flex>
        </Row>
      </Content>
      <FooterMain
        nextPath="gettingtoknow"
        currentStep={2}
        totalSteps={8}
        isProgressBarEnabled={false}
      />
    </>
  );
};

export default ProductSelection;
