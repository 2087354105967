import { Checkbox, Flex, Form, Input, Layout } from "antd";

import identificationDocument from "../../../Assets/Images/IdentificationDocument.png";
import person from "../../../Assets/Images/person3.png";

const { Content } = Layout;

const Part3 = () => {
  return (
    <Content className="step2-container layout">
      <h2 className="container-title">3. Beneficial Owner(s) information</h2>

      <Flex vertical className="wrapper">
        <Flex gap="40px" className="person-id">
          <div className="person-id__container">
            <div className="title">Identification document</div>

            <img src={identificationDocument} alt="id document" />
          </div>

          <div className="person-id__container">
            <div className="title">Selfie</div>
            <img src={person} alt="person" />
          </div>
        </Flex>

        <Form className="form">
          <Flex className="form-wrrapper" vertical>
            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="firstname" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="First Name"
                      bordered={false}
                      value={"Jack"}
                      required
                    />
                    <span className="info_span placeholder">First Name</span>
                  </div>
                </Form.Item>
                <Form.Item name="middlename" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Middle Name"
                      bordered={false}
                      value={"-"}
                      required
                    />
                    <span className="info_span placeholder">Middle Name</span>
                  </div>
                </Form.Item>
                <Form.Item name="lastname" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Last Name"
                      bordered={false}
                      value={"Smith"}
                      required
                    />
                    <span className="info_span placeholder">Last Name</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex justify="space-between" gap="20px">
                <Form.Item name="SSN" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="SSN"
                      bordered={false}
                      value={"XXX-XX-0000"}
                      required
                    />
                    <span className="info_span placeholder">SSN</span>
                  </div>
                </Form.Item>
                <Form.Item name="DateOfBirth" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Date Of Birth "
                      bordered={false}
                      value={"07/08/1992"}
                      required
                    />
                    <span className="info_span placeholder">
                      Date Of Birth{" "}
                    </span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="phone" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Phone"
                      bordered={false}
                      value={"(000) 000-0000"}
                      required
                    />
                    <span className="info_span placeholder">Phone</span>
                  </div>
                </Form.Item>
                <Form.Item name="Email" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Email"
                      bordered={false}
                      value={"jasmith@gmail.com"}
                      required
                    />
                    <span className="info_span placeholder">Email</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              {/* <h5>Physical address of business</h5> */}
              <Flex gap='20px'>
                <Form.Item name="address" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Street Address"
                      bordered={false}
                      value={"720 Florida Ave NE"}
                      required
                    />
                    <span className="info_span placeholder">Street Address</span>
                  </div>
                </Form.Item>
               
                <Form.Item name="address" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Street Address2"
                      bordered={false}
                      value={"-"}
                      required
                    />
                    <span className="info_span placeholder">Street Address</span>
                  </div>
                </Form.Item>

                </Flex>
              <div className="city-state-zip">
                <Form.Item name="City" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="City"
                      bordered={false}
                      value={"Miami"}
                      required
                    />
                    <span className="info_span placeholder">City</span>
                  </div>
                </Form.Item>
                <Form.Item name="State" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="State"
                      bordered={false}
                      value={"FL"}
                      required
                    />
                    <span className="info_span placeholder">State</span>
                  </div>
                </Form.Item>
                <Form.Item name="ZIP" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="ZIP"
                      bordered={false}
                      value={"33156"}
                      required
                    />
                    <span className="info_span placeholder">ZIP</span>
                  </div>
                </Form.Item>
              </div>
{/* 
              <Flex className="checkboxContainer">
                <label style={{ display: "flex" }}>
                  <Checkbox checked={true} />
                  <div className="checkboxLabel">
                    Mailing address and physical address are the same
                  </div>
                </label>
              </Flex> */}
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="Employer" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Employer"
                      bordered={false}
                      value={"Adam's Coffee House LLC"}
                      required
                    />
                    <span className="info_span placeholder">Employer</span>
                  </div>
                </Form.Item>
                <Form.Item name="job" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Job title / Position at the company listed on this application"
                      bordered={false}
                      value={"Head Barista"}
                      required
                    />
                    <span className="info_span placeholder">
                      Job title / Position at the company listed on this
                      application
                    </span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <Form.Item name="Ownership" style={{ width: "20%" }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Ownership %"
                    bordered={false}
                    value={"75%"}
                    required
                  />
                  <span className="info_span placeholder">Ownership %</span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>

          <Flex className="form-wrrapper cerification-card" vertical gap="12px">
            <h4 className="title">Certification of Business Owners</h4>

            <p className="text">
              REQUIRED FEDERAL CERTIFICATION. (The person opening a new account
              for a legal entity with [Financial Institution] must complete this
              certification pursuant to federal law). To help the government
              fight financial crime, federal law requires the [Financial
              Institution] to obtain, verify and record information about the
              Beneficial Owners who own 25%, or more of the legal entity. Legal
              entities can be abused to conceal involvement in terrorist
              financing, money laundering, tax evasion, corruption, fraud and
              other financial crimes. Requiring the disclosure of key
              individuals who ultimately own or control a legal entity
              (beneficial owners) helps law enforcement investigate and
              prosecute these types of crimes.
            </p>

            <Flex justify="space-between" gap="20px" align="center">
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Jack Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    value={"Jack Smith"}
                    required
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>

          {/* <Flex className="form-wrrapper" vertical gap="40px">
            <h3>Customer information</h3>

            <Flex justify="space-between" gap="20px" align="center">
              <Form.Item name="Date" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Date"
                    bordered={false}
                    value={"07/10/2024"}
                    required
                  />
                  <span className="info_span placeholder">Date</span>
                </div>
              </Form.Item>
              <Form.Item name="Company" style={{ flex: 3 }}>
                <div className={`info_borderText application-inputBox`}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Name of Legal Entity for Which the Account is Being Opened "
                    bordered={false}
                    value={"Company name"}
                    required
                  />
                  <span className="info_span placeholder">
                    Name of Legal Entity for Which the Account is Being Opened{" "}
                  </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex> */}
        </Form>
      </Flex>
    </Content>
  );
};

export default Part3;
