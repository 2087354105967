import React from "react";
import { Modal } from "antd";
import "./PleaseNotePopup.scss";

const PleaseNotePopup: React.FC<{
  isPleaseNoteModalVisible: boolean;
  setIsPleaseNoteModalVisible: (value: boolean) => void;
}> = ({ isPleaseNoteModalVisible, setIsPleaseNoteModalVisible }) => {

  return (
    <Modal
      className="pleaseNote-popup"
      open={isPleaseNoteModalVisible}
      onCancel={() => setIsPleaseNoteModalVisible(false)}
      footer={[]}
      width={"80%"}
      centered
    >
      <div className="pleaseNote-textItem">Please note</div><br/>
      <div className="maximum-text">Maximum three beneficial owners can be added</div>
    </Modal>
  );
};

export default PleaseNotePopup;
