import { Layout, Radio } from "antd";
import { useState } from "react";

import './AccountType.scss';
import { RadioChangeEvent } from "antd/lib/radio";
import Link from "antd/lib/typography/Link";


const AccountType = () => {
  const [selectedValue, setSelectedValue] = useState<number | null>(null);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
  };

  return (
    <>
      <Radio.Group className="radio" onChange={handleRadioChange} value={selectedValue}>
        <Radio
          value={1}
          className="radio__item"
        >
          <div className="radio__item-wrap">
            <div className="radio__header">
              <div className="radio__title">
                NON-PROFIT <br />
                BUSINESS ACCOUNT
              </div>
              <p className="radio__subtitle">
                For Associations, Non-Profit Corporations
              </p>
            </div>
            <div className="radio__footer">
              <div className="radio__list">
                <div className="radio__listhead">xxx Free Items</div>
                <span>$.xx per Deposit</span>
                <span>$.xx per Deposited Check</span>
                <span>$.xx per Written Check</span>
              </div>
              <div className="radio__caption">
                ** Transaction Fees only apply after the first xxx items
              </div>
            </div>
          </div>
        </Radio>
        <Radio
          value={2}
          className="radio__item"
        >
          <div className="radio__item-wrap">
            <div className="radio__header">
              <div className="radio__title">
                BASIC<br />
                BUSINESS ACCOUNT
              </div>
            </div>
            <div className="radio__footer">
              <div className="radio__list">
                <div className="radio__listhead">xxx Free Items</div>
                <span>$.xx per Deposit</span>
                <span>$.xx per Deposited Check</span>
                <span>$.xx per Written Check</span>
              </div>
              <div className="radio__caption">
                ** Transaction Fees only apply after the first xxx items
              </div>
            </div>
          </div>
        </Radio>
        <Radio
          value={3}
          className="radio__item"
        >
          <div className="radio__item-wrap">
            <div className="radio__header">
              <div className="radio__title">
                PREMIER<br />
                BUSINESS ACCOUNT
              </div>
              <p className="radio__subtitle">
                Monthly Fee: $XX.00
              </p>
            </div>
            <div className="radio__footer">
              <div className="radio__list">
                <div className="radio__listhead">Itemized Transaction Fees</div>
                <span>$.xx per Deposit</span>
                <span>$.xx per Deposited Check</span>
                <span>$.xx per Written Check</span>
              </div>
              <div className="radio__caption">
                ** Transaction Fees are offset by Earnings Credit
              </div>
            </div>
          </div>
        </Radio>
        <Radio
          value={4}
          className="radio__item"
        >
          <div className="radio__item-wrap">
            <div className="radio__header">
              <div className="radio__title">
                HIGH-YIELD <br />
                BUSINESS ACCOUNT
              </div>
              <p className="radio__subtitle">
                Monthly Fee: $XX.00
              </p>
            </div>
            <div className="radio__footer">
              <div className="radio__list">
                <div className="radio__listhead">Itemized Transaction Fees</div>
                <span>$.xx per Deposit</span>
                <span>$.xx per Deposited Check</span>
                <span>$.xx per Written Check</span>
              </div>
              <div className="radio__caption">
                ** Earns High-Yield interest rate on Checking
              </div>
            </div>
          </div>
        </Radio>
      </Radio.Group>
      <div className="link">
        <Link>Click here for the rate and fee schedule </Link>
      </div>
    </>
  );
};

export default AccountType;
