import React from "react";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../Assets/Images/plusIcon.png";
import cameraIcon from "../../../Assets/Images/MobileCameraIcon.png";

import "./UploadDocumentsContainer.scss";

const UploadDocumentsContainer: React.FC<{ type: string }> = ({ type }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (type.toLowerCase() === "front") {
      navigate("/frontdoc");
    } else if (type.toLowerCase() === "back") {
      navigate("/backdoc");
    } else {
      navigate("/mobileselfie");
    }
  };

  return (
    <div className="upload-documents-container">
      <div className="upload-doc-title">
        {type.toLowerCase() === "front" || type.toLowerCase() === "back" ? (
          <div>
            Please press the button below to scan the {type} of your
            identification document
          </div>
        ) : (
          <div>
            Please remove any glasses, hats, or other facial accessories then
            press the button below to take a selfie
          </div>
        )}
      </div>
      <div className="upload-content">
        <div className="upload-circle-container">
          <div className="upload-circle" onClick={handleClick}>
            {type.toLowerCase() === "front" || type.toLowerCase() === "back" ? (
              <img src={plusIcon} alt="add" className="upload-plus-icon" />
            ) : (
              <img src={cameraIcon} alt="add" className="camera-icon" />
            )}
          </div>
        </div>
        <div>
          {type.toLowerCase() === "selfie" && (
            <div className="photo-description-text">Take a photo</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentsContainer;
