import React from "react";
import "./UploadFrontDocument.scss";
import { Layout } from "antd";
import UploadDocumentsContainer from "../UploadDocumentsContainer/UploadDocumentsContainer";
import MobileFooter from "../MobileFooter/MobileFooter";
import UploadScreenHeader from "../UploadScreenHeader/UploadScreenHeader";
import { RouteList } from "../../../../Redux/Selector";
import { useSelector } from "react-redux";
import { getCurrentRoutePath } from "../../Utility/Utility";

const { Content } = Layout;

const UploadFrontDocument: React.FC = () => {
  const routePaths = useSelector(RouteList);
  const currentScreen = window.location.pathname;
  const currentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );
  return (
    <Layout className="upload-frontdoc-container">
      <UploadScreenHeader gobackpath={currentRoutePath?.Previous} />
      <Content className="upload-frontdoc-content">
        <div className="upload-step-title">
          Getting
          <br /> to know you
        </div>
        <UploadDocumentsContainer type="front" />
      </Content>

      <MobileFooter />
    </Layout>
  );
};

export default UploadFrontDocument;
