import { useEffect, useState } from "react";
import "./CameraPermission.scss";
import { Button, Modal, Row, Col } from "antd";

const CameraPermission = (props: { cameraPermission: any; onToggle: any }) => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        props.onToggle(true);
      })
      .catch((error) => {
        if (error.name === "NotAllowedError") {
          setShowPopup(true);
          props.onToggle(false);
        }
      });
  }, []);

  const reloadPage = () => {
    window.location.reload();
  };

  const CameraPermissionPopup = (props: any) => {
    return (
      <Modal
        centered
        open={showPopup}
        width={900}
        className="NotificationPopup"
        closable={false}
        footer={[
          <Row>
            <Col span={24}>
              <Button className="single-popup-button" onClick={reloadPage}>
                OK
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <div className="popup-container">
          <div className="popup-content">
            <div className="idleTimer-Header">
              Loquat requires access to your camera for identity verification
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      {/* <div className="permission-denied">
                {props.cameraPermission === false ? null : (
                    <h3>Checking Camera Permission</h3>
                )}
            </div> */}
      <CameraPermissionPopup />
    </>
  );
};

export default CameraPermission;
