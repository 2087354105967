import { Layout, Row, Col, Button, Typography, Select } from "antd";
import { useState } from "react";


import { useNavigate, useLocation } from "react-router-dom";

import DateOfBirth from "./DateOfBirth/DateOfBirth";
import SignUpForm from "./SignUpForm/SignUpForm";
import OPTForm from "./OPTForm/OPTForm";
import OPTMethodSelect from "./OPTMethodSelect/OPTMethodSelect";
import CreateUser from "./CreateUser/CreateUser";


const SignUp = () => {

  const [step, setStep] = useState(0)
  const navigate = useNavigate();

  const handleBackClick = () => {
    setStep((prev)=> prev - 1)
  }

  const handleNextClick = () => {
    if(step < 4){
      setStep((prev)=> prev + 1)
    }else {
      navigate('/letsgetready');
    }

  }

  return (
    <>
    
    {step === 0 && <DateOfBirth handleNextClick={handleNextClick}/>}
    {step === 1 && <SignUpForm handleNextClick={handleNextClick} handleBackClick={handleBackClick}/>}
    {step === 2 && <OPTMethodSelect handleNextClick={handleNextClick} handleBackClick={handleBackClick}/>}
    {step === 3 && <OPTForm handleNextClick={handleNextClick} handleBackClick={handleBackClick}/>}
    {step === 4 && <CreateUser handleNextClick={handleNextClick} handleBackClick={handleBackClick}/>}

    </>
  );
};

export default SignUp;
