import React from "react";
import "./UnderstandingBusinessThankyou.scss";
import { Layout, } from "antd";
import connected from "../../Assets/Images/img.svg";

import FooterMain from "../Footer/FooterMain";



const { Content } = Layout;

const UnderstandingBusinessThankyou: React.FC = () => {



  return (
    <>
      <Content className="content-UnderstandingBusinessThankyou">
        <h1 className="getting-to-know-title">Understanding your business</h1>
        <img src={connected} alt="connected" />
        <h3 className='subtitle'>Thank you!</h3>
        <div className='text'>Your verification data has been successfully submitted.
          You can move on to the next step</div>
      </Content>
      <FooterMain nextPath="beneficial" currentStep={5} totalSteps={8} isProgressBarEnabled={true} nextButtonText="Next" isBackBtnDisabled={true}/>

    </>
  );
};

export default UnderstandingBusinessThankyou;
