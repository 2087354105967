import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import finalthankyou from "../../Assets/Images/new/finalStep.png";
import image from "../../Assets/Images/headerImages/finalThanks.png";

import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./EndThankYou.scss";

import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import { IStepsHeader } from "../../Contracts/Contracts";
import { useNavigate } from "react-router-dom";
import { getValueFromStorage } from "../Utility/Utility";
import logo from "../../Assets/Images/logo.svg";

const { Content } = Layout;
const headerValues: IStepsHeader = {
  title: "Thank You",
  titleClass: "letsheadingfinalthankyou",
  img: image,
};
const EndThankYou = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate();
  const businessName = getValueFromStorage("businessName");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60) % 60;
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleClick = () => {
    // navigate("/accountfunding");
  };
  return (
    <>
      <Content className="finalthankyou-content-1" onClick={handleClick}>
        <h1 className="title">Thank you!</h1>

        <div className="text">
          <p>
            Thank you for the information provided. We have started reviewing
            your application. We may need to contact you for more details. After
            your application is approved, you will get access to your personal
            account.
          </p>
        </div>

        <div className="finalthankyou-container">
          <p className="finaltimeshow">
            <span className="subtitle">
              Waiting for the application to be considered
            </span>
            <span className="finaltime">{formatTime(timeLeft)} </span>
          </p>
          <img className="finalthankimg" src={finalthankyou} alt="finallogo" />
        </div>
      </Content>
      <img src={logo} alt="logo" className={"logo"} />
    </>
  );
};

export default EndThankYou;
