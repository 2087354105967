import { Flex, Form, Input, Layout, Radio } from "antd";

import "./Questionary.scss";

const questions = [
  { text: "Is this business registered in a country outside the US?", value: 'Yes' },
  { text: "Are you registered as a Money Service Business or do you cash checks made payable to your customers?", value: 'No' },
  { text: "Will this business include the retail sale, creation, marketing or testing of marijuana or marijuana-related products (hemp, CBD, CBD oils)?", value: 'No' },
  { text: "Is this an Adult Entertainment Business?", value: 'No' },
  { text: "Does this business engage in internet gambling?", value: 'No' },
];

const { Content } = Layout;

const Questionary = () => {
  return (
    <Content className="questionary-content">
      <div className="container">
        <h2 className="container-title" style={{ marginTop: "120px" }}>
          Business account questionnaire
        </h2>

        <Flex vertical gap="20px" component={"form"}>

          {questions.map((el) => (
            <Flex
              className="radioContainer"
              align="center"
              justify="space-between"
            >
              <div className="label">
                {el.text}
              </div>
              <div className="radioWrapper">
                <Radio.Group className="radio" value={el.value}>
                  <Radio className="radio__item" value="Yes" disabled>
                    Yes
                  </Radio>
                  <Radio className="radio__item" value="No" disabled>
                    No
                  </Radio>
                </Radio.Group>
              </div>
            </Flex>
          ))}


          <Flex
            className="radioContainer"
            align="center"
            gap="32px"
            justify="space-between"
          >
            <div
              className="label"
              style={{ maxWidth: "800px", width: "100%", margin: 0 }}
            >
              What is the anticipated dollar amount of currency transactions per month?
            </div>
            <Form.Item style={{ width: "304px" }}>
              <div className={`info_borderText application-inputBox `}>
                <Input
                  className="info_inputTextClass"
                  placeholder="How many times per month?"
                  bordered={false}
                  value={"$10,000"}
                  required
                />
                <span className="info_span placeholder">
                  How many times per month?
                </span>
              </div>
            </Form.Item>

          </Flex>





        </Flex>




      </div>
    </Content>
  );
};

export default Questionary;
