export class ApiEndpoints {
  public static MasterData = {
    getLegalType() {
      return `todos/1`;
    },
    getDocumentType(countryCode: string | any) {
      return `masterdata/documenttypes?CountryCode=USA`;
    },
    getIndustries() {
      return `masterdata/industries`;
    },
    getCountryConfigurations(countryCode: string) {
      return `masterdata/countryconfigurations?CountryCode=${countryCode}`;
    },
    getStateCityDetailsFromZipcode(zipcode:string){
      return `masterdata/addressbyzipcode?zipcode=${zipcode}`;
    }
  };

  public static Geolocation = {
    setGeolocation() {
      return `geolocation/validate`;
    },
  };

  public static PhoneAuthentication = {
    getOtp() {
      return `auth/generateotp`;
    },
  };

  public static ValidateOtp = {
    validateOtp() {
      return `auth/validateotp`;
    },
  };

  public static UnderstandingUpload = {
    setUnderstandingUpload() {
      return `kyb/documents`;
    },
  };

  public static DocumentStatus = {
    setDocumentStatus() {
      return `kyb/savedocumentstatus`;
    },
  };

  public static Profile = {
    getPersonalInfo(userId: string) {
      return `profile/getprofile?UserID=${userId}`;
    },
    postPersonalInfo() {
      return `profile/postprofile`;
    },
    getUserAppStatus() {
      return `profile/user-application-statusv2`;
    },
    postLegaltype() {
      return `profile/userstate`;
    },
  };

  public static Business = {
    getBusinessInfo() {
      return `business/businesses`;
    },
    postBusinessInfo() {
      return `business/businesses`;
    },
    completeApplicationUrl() {
      return `/business/completeApplication`;
    },
    setBeneficiaryType() {
      return `/business/beneficiarytype`;
    },
  };

  public static Beneficiary = {
    getBeneficiaryListUrl() {
      return `/bo/beneficieryowners`;
    },
    addbeneficiaryUrl() {
      return `bo/beneficieryowners`;
    },
    deleteBeneficiaryUrl(userId: string) {
      return `/bo/beneficieryowners/${userId}`;
    },
    setBeneficiaryOwnerInvitation() {
      return `/bo/beneficieryowners/invite`;
    },
  };
  public static DigitalFinancial = {
    getLinkTokenUrl() {
      return `digitalfinancial/linkToken`;
    },
    getAccessTokenUrl() {
      return `digitalfinancial/accessToken`;
    },
  };

  public static Notification = {
    getNotificationWeblinkSMSUrl() {
      return `notification/SendWebLinkSMS`;
    },
    getWeblinkStatusUrl() {
      return `notification/GetWebLinkStatus`;
    },
    getWebLinkStatusDetailsUrl() {
      return `notification/GetWebLinkDetailsId`;
    },
    getUpdateWebStatusLinkDetailsUrl() {
      return `notification/UpdateWebLinkStatus`;
    },
  };

  public static KYCDocuments = {
    setKYCFrontDoc() {
      return `kyc/frontv2`;
    },
    setKYCBackDoc() {
      return `kyc/backv2`;
    },
    setKYCSelfie() {
      return `kyc/selfiev2`;
    },
  };

  public static FinalStep = {
    getUserDocument(documentTypeId: any) {
      return `profile/getuserdocument?documentTypeId=${documentTypeId}`;
    },
    getBusinessesInfo() {
      return `/Business/businesses`;
    },
    getKYBDocumentsInfo(documentTypeId: any, legalTypeId: any) {
      return `kyb/getkybdocument?businessDocumentTypeId=${documentTypeId}&legalTypeId=${legalTypeId}`;
    },
  };

  public static VirtualAccount = {
    getAddCard() {
      return `virtualaccount/addcard`;
    },
  };

  public static DeleteUser = {
    postDeleteUser() {
      return `admin/deleteuser`;
    },
  };
}
