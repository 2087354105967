
import Part1 from "./Part1/Part1";
import Part2 from "./Part2/Part2";
import Part3 from "./Part3/Part3";
import Part4 from "./Part4/Part4";


const FinalStepNew = () => {

  return (
    <>
      <Part1 />
      <Part2 />
      <Part3 />
      <Part4/>
    </>
  );
};

export default FinalStepNew;
