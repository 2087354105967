import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Row,
  Col,
  Input,
  Select,
  Form,
  Flex,
  Radio,
  Checkbox,
} from "antd";
import "./BusinessInfo.scss";
import {
  IBusinessInfoFormValues,
  businessInfoInitialvalues,
} from "../../Contracts/Contracts";
import {
  formatPhoneNumber,
  validatePhoneNumber,
  formattedEINValue,
  setValueInStorage,
  getValueFromStorage,
  FormErrorHandlingFunction,
  handleNumberKeyDown,
} from "../Utility/Utility";
import { RuleObject } from "antd/lib/form";
import data from "../../Components/PersonalInfo/States.json";
import FooterMain from "../Footer/FooterMain";
import { useNavigate } from "react-router-dom";
import Questionary from "../FinalStep/Part4/Questionary/Questionary";

const { Content } = Layout;
const size = "large";
const BusinessInfo = () => {
  const { getLoquatClient, setDocumentData } = useContext(ApplicationContext);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<IBusinessInfoFormValues | any>(
    businessInfoInitialvalues
  );
  const [industries, setIndustries] = useState<{
    industries: any[];
    subIndustries?: any[];
  }>({
    industries: [],
  });
  const [isAddressSame, setIsAddressSame] = useState<boolean>(true);
  const [selectedState, setSelectedState] = useState<any>({});
  const [isAdditionalQuestionActive, setIsAdditionalQuestionActive] =
    useState<any>(false);

  const [listValues, setListValues] = useState<{ legalTypes: any[] }>({
    legalTypes: [],
  });
  const legalTypeSelected = getValueFromStorage("DocumentData");
  const legalType = legalTypeSelected && JSON.parse(legalTypeSelected);
  const [selectedLegalType, setSelectedLegalType] = useState<any>(
    legalType?.legalType
  );

  const [formattedEIN, setFormattedEIN] = useState("");
  const [originalEIN, setOriginalEIN] = useState("");
  const [inputArray, setInputArray] = useState<any>([]);

  const [errorField, setErrorField] = useState<string[] | any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const response = {
      successResult: {
        legalTypes: [
          {
            legalTypeId: 1,
            legalTypeName: "General Partnership",
            documentType: [
              {
                documentTypeId: 1,
                documentTypeName: "Partnership Agreement",
              },
              {
                documentTypeId: 2,
                documentTypeName: "Fictitious Name Certificate or Statement",
              },
              {
                documentTypeId: 3,
                documentTypeName: "Certificate of Assumed Name",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
          {
            legalTypeId: 2,
            legalTypeName: "Limited Liability Partnership",
            documentType: [
              {
                documentTypeId: 5,
                documentTypeName: "An LLP Agreement",
              },
              {
                documentTypeId: 6,
                documentTypeName: "A Certificate of Incorporation",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
          {
            legalTypeId: 3,
            legalTypeName: "LLC (Limited Liabilty Company)",
            documentType: [
              {
                documentTypeId: 7,
                documentTypeName: "Articles of Organization",
              },
              {
                documentTypeId: 8,
                documentTypeName: "Certificate of Organization",
              },
              {
                documentTypeId: 9,
                documentTypeName: "Certificate of Formation",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
          {
            legalTypeId: 4,
            legalTypeName: "Corporation",
            documentType: [
              {
                documentTypeId: 10,
                documentTypeName: "Articles of Incorporation",
              },
              {
                documentTypeId: 11,
                documentTypeName: "Certificate of Good Standing",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
        ],
      },
    };

    setListValues(response?.successResult);

    setIndustries({
      industries: [
        { id: 1, name: "Accommodation and Food Services" },
        // { id: 2, name: "Industry name 2" },
        // { id: 3, name: "Industry name 3" },
        // { id: 4, name: "Technical services" },
      ],
      subIndustries: [
        // { id: 0, name: "Payroll Services" },

        { id: 1, name: "Snack and Non Alcoholic Beverages Bars" },
        // { id: 2, name: "Sub Industry name 2" },
        // { id: 3, name: "Sub Industry name 3" },
      ],
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      DBA: formValues.dba,
      employees: formValues.numberOfEmployees,
    });
  }, [form, formValues.dba, formValues.numberOfEmployees]);

  const validateEmail = (rule: RuleObject, value: string) => {
    return new Promise<void>((resolve, reject) => {
      if (!value) {
        reject("Email Address is required");
      } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
        reject("Invalid Email Address");
      } else {
        resolve();
      }
    });
  };

  const onChange = (fieldName: string, value: any) => {
    let updatedValues;
    if (fieldName.toLowerCase() === "phone") {
      updatedValues = {
        ...formValues,
        phone: formatPhoneNumber(value),
      };
    } else {
      updatedValues = {
        ...formValues,
        [fieldName]: value,
      };
    }
    if (fieldName.toLowerCase() === "state") {
      setSelectedState({ name: value });
    }
    form.setFieldsValue(updatedValues);
    setFormValues(updatedValues);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Backspace" && formValues?.phone.length <= 7) {
      event.preventDefault();
      form.setFieldsValue({ phone: "" });
      setFormValues((prevValues: IBusinessInfoFormValues) => ({
        ...prevValues,
        phone: "",
      }));
    }
  };
  const handleKeyPress = (e: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) => {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault();
    }
  };

  const handleSelectChange = (selectedValue: number) => {
    const selectedLegalType = listValues?.legalTypes?.find(
      (option) => option?.legalTypeId === selectedValue
    ) as any | undefined;
    if (selectedLegalType) {
      setSelectedLegalType(selectedLegalType);
      // setDummySelectedLegalType(selectedLegalType);
      // return;
      setDocumentData({
        documentTypes: selectedLegalType?.documentType,
        legalType: {
          legalTypeId: selectedLegalType.legalTypeId,
          legalTypeName: selectedLegalType.legalTypeName,
        },
      });
      const document = {
        documentTypes: selectedLegalType?.documentType,
        legalType: {
          legalTypeId: selectedLegalType.legalTypeId,
          legalTypeName: selectedLegalType.legalTypeName,
        },
      };
      const legalTypeId = selectedLegalType?.legalTypeId?.toString();
      setValueInStorage("SelectedLegalType", legalTypeId);
      const data = JSON.stringify(document);
      setValueInStorage("DocumentData", data);
    }
  };

  const handleChangeEIN = (event: any) => {
    let inputValue = event.target.value;
    let newArray = inputArray;
    const lastChar = inputValue[inputValue.length - 1];
    inputArray.push(lastChar);
    setInputArray(newArray);
    const returnValue = formattedEINValue(newArray.join(""));
    setFormattedEIN(returnValue);
    setOriginalEIN(newArray.join(""));
  };

  const handleFieldsError = () => {
    const resultArray = FormErrorHandlingFunction(form);
    setErrorField(resultArray);
  };

  const navigate = useNavigate();

  const handleNextClick = async () => {
    try {
      await form.validateFields();
      navigate(`/understandingbusiness`);
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <Content className="business-container layout">
        <h1 className="title">Understanding your business</h1>
        <div className="personal-button-container">
          <Form
            form={form}
            className="businessinfo-form"
            initialValues={formValues}
            onFieldsChange={handleFieldsError}
            scrollToFirstError={true}
          >
            <Flex style={{ marginBottom: "52px" }} vertical>
              <Flex>
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: "Business Name is required" },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("name")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Business Name"
                      onChange={(e) => onChange("name", e.target.value)}
                      onKeyDown={(e) => handleKeyPress(e)}
                      value={formValues.name}
                      required
                    />
                    <span className="info_span placeholder">Business Name</span>
                  </div>
                </Form.Item>
              </Flex>

              <Flex>
                <Form.Item
                  name="DBA"
                  rules={[
                    {
                      // required: true,
                      message: "Doing Business As (DBA) is required",
                    },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("DBA")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      value={formValues.DBA}
                      placeholder="Doing Business As (DBA)"
                      required
                    />
                    <span className="info_span placeholder">
                      Doing Business As (DBA)
                    </span>
                  </div>
                </Form.Item>
              </Flex>

              <Flex>
                <Form.Item
                  name="einTin"
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        // const formattedValue = originalEIN;
                        // const isValidLength = formattedValue.length === 9;
                        // const containsOnlyDigits = /^\d+$/.test(originalEIN);
                        // if (isValidLength && containsOnlyDigits) {
                        //   return Promise.resolve();
                        // } else if (formattedValue?.length && !isValidLength) {
                        //   return Promise.reject("Invalid EIN");
                        // } else if (originalEIN?.length && !containsOnlyDigits) {
                        //   return Promise.reject("Invalid EIN");
                        // } else {
                        //   return Promise.reject("EIN is required");
                        // }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("einTin")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      placeholder="EIN / TIN"
                      bordered={false}
                      onChange={handleChangeEIN}
                      // onChange={(e) => onChange("einTin", e.target.value)}
                      value={formValues.einTin || formattedEIN}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === 8 || e.keyCode === 46) {
                          setInputArray([]);
                          setOriginalEIN("");
                          setFormattedEIN("");
                        }
                      }}
                      maxLength={10}
                      required
                    />
                    <span className="info_span placeholder">EIN / TIN</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex style={{ marginBottom: "52px" }} vertical>
              <Flex>
                <div className="select_limit_main ">
                  <div className={`select-legal-type`}>
                    <span className="legal-type-label">Legal Type</span>
                    <Select
                      className="legal-type-select-width"
                      options={listValues.legalTypes.map((option) => ({
                        value: option.legalTypeId,
                        label: option.legalTypeName,
                      }))}
                      bordered={false}
                      onChange={handleSelectChange}
                      placeholder="Select Legal Type"
                      value={
                        // listValues?.legalTypes?.length > 0
                        //   ? selectedLegalType && selectedLegalType?.legalTypeId
                        //   : null
                        formValues?.legalTypeId
                      }
                    />
                  </div>
                </div>
              </Flex>

              <Flex gap="20px">
                <Form.Item
                  name="purpose"
                  rules={[{ required: true, message: "Purpose is required" }]}
                  style={{ width: "70%" }}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("purpose")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      value={formValues.purpose}
                      bordered={false}
                      placeholder="What is the primary purpose or function of this business?"
                      required
                    />
                    <span className="info_span placeholder">
                      What is the primary purpose or function of this business?
                    </span>
                  </div>
                </Form.Item>

                <Form.Item
                  name="employees"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Number Of Employees is required",
                  //   },
                  // ]}
                  style={{ width: "30%" }}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("employees")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      value={formValues.employees}
                      bordered={false}
                      placeholder="Number Of Employees"
                      required
                    />
                    <span className="info_span placeholder">
                      Number Of Employees
                    </span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex
              style={{ marginBottom: "52px", zIndex: 2, position: "relative" }}
              vertical
            >
              <Flex>
                <Form.Item
                  name="industryId"
                  rules={[
                    { required: true, message: "My Industry is required" },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("industryId")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                     <span className="info_span">My Industry</span>
                    <Space wrap className="fullWidth info_inputTextClass">
                      <Select
                        showSearch
                        size={size}
                        className="selectBusinessWidth"
                        placeholder="My Industry"
                        options={
                          industries?.industries.length > 0
                            ? industries.industries.map((industry) => ({
                                value: industry.id,
                                label: industry.name,
                              }))
                            : []
                        }
                        bordered={false}
                        onChange={(value) => onChange("industryId", value)}
                        // defaultValue={selectedOption?.id}
                        value={formValues?.industryId}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Flex>

              <Flex>
                <Form.Item
                  name="subIindustryId"
                  rules={[
                    { required: true, message: "Sub Industry is required" },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 &&
                      errorField.includes("subIindustryId")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                     <span className="info_span">Sub Industry</span>
                    <Space wrap className="fullWidth info_inputTextClass">
                      <Select
                        showSearch
                        size={size}
                        className="selectBusinessWidth"
                        placeholder="Sub Industry"
                        options={
                          industries?.industries.length > 0
                            ? industries.subIndustries?.map((industry) => ({
                                value: industry.id,
                                label: industry.name,
                              }))
                            : []
                        }
                        bordered={false}
                        onChange={(value) => {
                          onChange("subIindustryId", value);
                          setIsAdditionalQuestionActive(true);
                          // if (value === industries.subIndustries?.[0].id) {

                          //   setIsAdditionalQuestionActive(true);
                          // } else {
                          //   setIsAdditionalQuestionActive(false);
                          // }
                        }}
                        // defaultValue={selectedOption?.id}
                        value={formValues?.subIindustryId}
                        disabled={!formValues.industryId}
                      />
                    </Space>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            {/* {isAdditionalQuestionActive && ( */}
              <Flex vertical className="additionalQuestionsContainer">
          

                <Flex justify="space-between" className="radioContainer">
                  <div className="label">
                  Are you registered as a Money Service Business or do you cash checks made payable to your customers?
                  </div>

                  <Form.Item name="moneyService" className="radioWrapper">
                    <Radio.Group className="radio">
                      <Radio className="radio__item" value="yes">
                        Yes
                      </Radio>
                      <Radio className="radio__item" value="no">
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Flex>

                <Flex justify="space-between" className="radioContainer">
                  <div className="label">
                  Will this business include the retail sale, creation, marketing or testing of marijuana or marijuana-related products (hemp, CBD, CBD oils)?
                  </div>

                  <Form.Item name="marijuanaRelated" className="radioWrapper">
                    <Radio.Group className="radio">
                      <Radio className="radio__item" value="yes">
                        Yes
                      </Radio>
                      <Radio className="radio__item" value="no">
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Flex>

                <Flex justify="space-between" className="radioContainer">
                  <div className="label">
                  Is this an Adult Entertainment Business?
                  </div>
                  <Form.Item name="adultEntertainment" className="radioWrapper">
                    <Radio.Group className="radio">
                      <Radio className="radio__item" value="yes">
                        Yes
                      </Radio>
                      <Radio className="radio__item" value="no">
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Flex>
                <Flex justify="space-between" className="radioContainer">
                  <div className="label">
                  Does this business engage in internet gambling?
                  </div>
                  <Form.Item name="internetGambling" className="radioWrapper">
                    <Radio.Group className="radio">
                      <Radio className="radio__item" value="yes">
                        Yes
                      </Radio>
                      <Radio className="radio__item" value="no">
                        No
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Flex>
                <Flex justify="space-between" className="radioContainer" align="center">
                  <div className="label">
                  Is this business registered in a country outside the US?
                  </div>
                  <Form.Item name="name" style={{width: '304px'}}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        bordered={false}
                        prefix="$"
                      />
                      <span className="info_span placeholder">
                        Number Of Employees
                      </span>
                    </div>
                  </Form.Item>
                </Flex>


              </Flex> 
            {/* )} */}

            <Flex style={{ marginBottom: "40px", gap: "20px" }}>
              <Flex style={{ width: "50%" }}>
                <Form.Item
                  name="phone"
                  rules={[{ validator: validatePhoneNumber }]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("phone")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Phone"
                      onKeyDown={handleKeyDown}
                      onChange={(e) => onChange("phone", e.target.value)}
                      value={formValues.phone}
                      maxLength={14}
                      required
                    />
                    <span className="info_span placeholder">Phone</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex style={{ width: "50%" }}>
                <Form.Item name="email" rules={[{ validator: validateEmail }]}>
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("email")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Email"
                      onChange={(e) => onChange("email", e.target.value)}
                      value={formValues.email}
                      required
                    />
                    <span className="info_span placeholder">Email</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex style={{ marginBottom: "100px" }} vertical>
              <h4 className="inputContainerTitle">
                Physical Address of Business
              </h4>
              <Flex>
                <Form.Item
                  name="addressLine1"
                  rules={[
                    { required: true, message: "Street Address 1 is required" },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 &&
                      errorField.includes("addressLine1")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Street Address"
                      onChange={(e) => onChange("addressLine1", e.target.value)}
                      value={formValues.addressLine1}
                      required
                    />
                    <span className="info_span placeholder">
                      Street Address 1
                    </span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex>
                <Form.Item
                  name="addressLine1"
                  rules={[
                    { required: true, message: "Street Address 1 is required" },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 &&
                      errorField.includes("addressLine1")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Street Address"
                      onChange={(e) => onChange("addressLine1", e.target.value)}
                      value={'-'}
                      required
                    />
                    <span className="info_span placeholder">
                      Street Address 2
                    </span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex gap="20px">
                <Flex style={{ width: "50%" }}>
                  <Form.Item
                    name="city"
                    rules={[
                      { required: true, message: "City is required" },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: "Invalid City name",
                      },
                    ]}
                  >
                    <div
                      className={`info_borderText application-inputBox ${
                        errorField.length > 0 && errorField.includes("city")
                          ? "errorDiv"
                          : ""
                      }`}
                    >
                      <Input
                        className="info_inputTextClass"
                        bordered={false}
                        placeholder="City"
                        onChange={(e) => onChange("city", e.target.value)}
                        value={formValues.city}
                        required
                      />
                      <span className="info_span placeholder">City</span>
                    </div>
                  </Form.Item>
                </Flex>
                <Flex gap="20px" style={{ width: "50%" }}>
                  <Form.Item
                    name="state"
                    rules={[{ required: true, message: "State is required" }]}
                  >
                    <div
                      className={`info_borderText application-inputBox ${
                        errorField.length > 0 && errorField.includes("state")
                          ? "errorDiv"
                          : ""
                      }`}
                    >
                       <span className="info_span">State</span>
                      <Space
                        wrap
                        className="fullWidth info_inputTextClass select-input-padding select-dropdown"
                      >
                        <Select
                          size={size}
                          bordered={false}
                          value={formValues.state}
                          onChange={(value) => onChange("state", value)}
                          placeholder="Select State"
                          options={
                            data?.states.length > 0
                              ? data.states.map((state) => ({
                                  value: state.name,
                                  label: state.name,
                                }))
                              : []
                          }
                        />
                      </Space>
                    </div>
                  </Form.Item>

                  <Form.Item
                    name="zip"
                    rules={[
                      { required: true, message: "ZIP code is required" },
                      {
                        pattern: /^\d{5}$/,
                        message: "Invalid ZIP code",
                      },
                    ]}
                  >
                    <div
                      className={`info_borderText application-inputBox ${
                        errorField.length > 0 && errorField.includes("zip")
                          ? "errorDiv"
                          : ""
                      }`}
                    >
                      <Input
                        className="info_inputTextClass"
                        bordered={false}
                        placeholder="ZIP"
                        onChange={(e) => onChange("zip", e.target.value)}
                        value={formValues.zip}
                        onKeyDown={handleNumberKeyDown}
                        maxLength={5}
                        required
                      />
                      <span className="info_span placeholder">Zip</span>
                    </div>
                  </Form.Item>
                </Flex>
              </Flex>

              <Flex className="checkboxContainer">
                <label style={{ display: "flex" }}>
                  <Checkbox
                    onClick={(e) => {
                      setIsAddressSame((prev) => !prev);
                      if (!isAddressSame) {
                        const fieldsToRemove = [
                          "city2",
                          "state2",
                          "zip2",
                          "addressLine12",
                        ];
                        const filteredFields = errorField.filter(
                          (field: string) => !fieldsToRemove.includes(field)
                        );

                        setErrorField(filteredFields);
                      }
                    }}
                    checked={isAddressSame}
                  />
                  <div className="checkboxLabel">
                    Mailing address and physical address are the same
                  </div>
                </label>
              </Flex>
            </Flex>

            {!isAddressSame && (
              <Flex
                style={{ marginBottom: "100px", marginTop: "-43px" }}
                vertical
              >
                <h4 className="inputContainerTitle">
                  Mailing address of business
                </h4>
                <Flex gap="20px">
                  <Flex style={{ width: "50%" }}>
                    <Form.Item
                      name="city2"
                      rules={[
                        { required: true, message: "City is required" },
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message: "Invalid City name",
                        },
                      ]}
                    >
                      <div
                        className={`info_borderText application-inputBox ${
                          errorField.length > 0 && errorField.includes("city2")
                            ? "errorDiv"
                            : ""
                        }`}
                      >
                        <Input
                          className="info_inputTextClass"
                          bordered={false}
                          placeholder="City"
                          onChange={(e) => onChange("city2", e.target.value)}
                          value={formValues.city2}
                          required
                        />
                        <span className="info_span placeholder">City</span>
                      </div>
                    </Form.Item>
                  </Flex>
                  <Flex gap="20px" style={{ width: "50%" }}>
                    <Form.Item
                      name="state2"
                      rules={[{ required: true, message: "State is required" }]}
                    >
                      <div
                        className={`info_borderText application-inputBox ${
                          errorField.length > 0 && errorField.includes("state2")
                            ? "errorDiv"
                            : ""
                        }`}
                      >
                        {/* <span className="info_span">State</span> */}
                        <Space
                          wrap
                          className="fullWidth info_inputTextClass select-input-padding select-dropdown"
                        >
                          <Select
                            size={size}
                            bordered={false}
                            onChange={(value) => onChange("state2", value)}
                            placeholder="Select State"
                            options={
                              data?.states.length > 0
                                ? data.states.map((state) => ({
                                    value: state.name,
                                    label: state.name,
                                  }))
                                : []
                            }
                          />
                        </Space>
                      </div>
                    </Form.Item>

                    <Form.Item
                      name="zip2"
                      rules={[
                        { required: true, message: "ZIP code is required" },
                        {
                          pattern: /^\d{5}$/,
                          message: "Invalid ZIP code",
                        },
                      ]}
                    >
                      <div
                        className={`info_borderText application-inputBox ${
                          errorField.length > 0 && errorField.includes("zip2")
                            ? "errorDiv"
                            : ""
                        }`}
                      >
                        <Input
                          className="info_inputTextClass"
                          bordered={false}
                          placeholder="ZIP"
                          onChange={(e) => onChange("zip2", e.target.value)}
                          value={formValues.zip2}
                          onKeyDown={handleNumberKeyDown}
                          maxLength={5}
                          required
                        />
                        <span className="info_span placeholder">Zip</span>
                      </div>
                    </Form.Item>
                  </Flex>
                </Flex>

                <Flex>
                  <Form.Item
                    name="addressLine12"
                    rules={[
                      {
                        required: true,
                        message: "Street Address 1 is required",
                      },
                    ]}
                  >
                    <div
                      className={`info_borderText application-inputBox ${
                        errorField.length > 0 &&
                        errorField.includes("addressLine12")
                          ? "errorDiv"
                          : ""
                      }`}
                    >
                      <Input
                        className="info_inputTextClass"
                        bordered={false}
                        placeholder="Street Address"
                        onChange={(e) =>
                          onChange("addressLine12", e.target.value)
                        }
                        value={formValues.addressLine12}
                        required
                      />
                      <span className="info_span placeholder">
                        Street Address 1
                      </span>
                    </div>
                  </Form.Item>
                </Flex>
              </Flex>
            )}
          </Form>
        </div>
      </Content>
      <FooterMain
        currentStep={5}
        totalSteps={8}
        isProgressBarEnabled={true}
        isConfirmBtnInactive={errorField.length !== 0}
        onConfirmBtnClick={handleNextClick}
        name="Understanding your business"
      />
    </>
  );
};

export default BusinessInfo;
