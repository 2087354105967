import { createSlice } from '@reduxjs/toolkit';

const idleTimerReducer = createSlice({
    name: 'IdleTimer',
    initialState: {
        timeRemaining: 30,
    },
    reducers: {
        SET_COUNTDOWN_TIMER(state, action) {
            state.timeRemaining = action.payload;
        }
    },
});

export const { SET_COUNTDOWN_TIMER } = idleTimerReducer.actions;
export default idleTimerReducer.reducer;
