import React, { useState } from "react";
import { Layout, List, Avatar, Button, Flex, Modal } from "antd";
import logo from "../../../../Assets/Images/logo.svg";
import kyc1 from "../../../../Assets/Images/Smartphone_kyc_1.png";
import kyc2 from "../../../../Assets/Images/kyc_mistake.png";
import kyc3 from "../../../../Assets/Images/kyc3.png";
import kyc4 from "../../../../Assets/Images/kyc4.png";
import kyc5 from "../../../../Assets/Images/kyc5.png";
import kyc6 from "../../../../Assets/Images/kyc6.png";
import kyc7 from "../../../../Assets/Images/kyc7.png";

import "./LetsVerify.scss";

import { useNavigate, useLocation } from "react-router-dom";
import WebcamCapture from "../Webcam/Webcam";
const { Content } = Layout;

const LetsVerify = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [image, setImage] = useState({});

  const navigate = useNavigate();

  const onClick = () => {
    navigate("/understandingupload");
  };

  const handleNextClick = () => {
    if (step < 3) {
      setStep((prev) => prev + 1);
    } else if (step < 4 && isModalOpen) {
      setStep((prev) => prev + 1);
      setIsModalOpen(true);
    } else if (step < 6) {
      setStep((prev) => prev + 1);
    } else {
      navigate("/kyc-wait");
    }
  };

  const handlePhoto = (key: string, imgSrc: string) => {
    setImage((prev) => ({ ...prev, [key]: imgSrc }));
    setTimeout(() => {
      setStep((prev) => prev + 1);
    }, 500);
  };

  return (
    <Layout className="mobile-layout kyc-container">
      <Content className="mobile-content">
        {step === 0 && (
          <>
            <Flex vertical align="center">
              <h1 className="title">Let’s get you verified</h1>

              <div className="list-container">
                <h2 className="list-title">Before you start, please:</h2>
                <ul>
                  <li>
                    Prepare your Passport (U.S.) / Driver’s License / Government
                    Issued Identity Card / International Driver’s License
                    /Foreign Passport and I-94 form
                  </li>

                  <li>Make sure you are in a well-lit room</li>
                </ul>
              </div>

              <Button className="button" onClick={handleNextClick}>
                Let’s go!
              </Button>
            </Flex>
            <div className="footer">
              <img src={logo} alt="logo" className="logo" />
            </div>
          </>
        )}

        <Modal open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
          <div className="kycModal">
            <div className="wrapper">
              <h1 className="title">Image quality is low</h1>

              <span className="text">Please retake the picture</span>
            </div>

            <div className="btnWrapper">
              <Button
                className="button"
                onClick={() => {
                  handleNextClick();
                  setIsModalOpen(false);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      </Content>

      {/* {step === 1 && (
        <div className="imgKYCStepWrapper">
          <img
            className="imgKYCStep"
            src={kyc1}
            alt="img"
            onClick={handleNextClick}
          />
        </div>
      )} */}
      {step === 1 && (
        <div className="imgKYCStepWrapper">
          <WebcamCapture
            header={
              <div className="photo1-header">
                <div>Your identification document</div>
                <div>
                  Please hold the document here, it will scan automatically
                </div>
              </div>
            }
            onPhotoClick={(img) => handlePhoto("firstImage", img)}
          />
        </div>
      )}
      {step === 2 && (
        <div className="imgKYCStepWrapper">
          <div className="df">
            <div className="photo1-header">
              <div>Your identification document</div>
            </div>
            <img
              className="imgKYCStep"
              //@ts-ignore
              src={image.firstImage ?? null}
              alt="img"
              onClick={handleNextClick}
            />
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="imgKYCStepWrapper">
          <WebcamCapture
            header={
              <div className="photo1-header">
                <div>Back of your identification document</div>
                <div>
                  Please hold the document here, it will scan automatically
                </div>
              </div>
            }
            onPhotoClick={(img) => handlePhoto("secondImage", img)}
          />
        </div>
      )}
      {step === 4 && (
        <div className="imgKYCStepWrapper">
          <div className="df">
            <div className="photo1-header">
              <div>Back of your identification document</div>
            </div>
            <img
              className="imgKYCStep"
              //@ts-ignore
              src={image.secondImage ?? null}
              alt="img"
              onClick={handleNextClick}
            />
          </div>
        </div>
      )}
      {step === 5 && (
        <div className="imgKYCStepWrapper">
          <WebcamCapture
            isSelfie
            header={
              <div className="selfie-header">
                <div>
                  Please remove any glasses, hats, or other facial accessories
                  then press the button below to take a selfie
                </div>
              </div>
            }
            onPhotoClick={(img) => handlePhoto("thirdImage", img)}
          />
        </div>
      )}
      {step === 6 && (
        <div className="imgKYCStepWrapper">
          <div className="df">
            <div className="photo1-header">
              <div>Selfie Preview</div>
            </div>
            <img
              className="imgKYCStep"
              //@ts-ignore
              src={image.thirdImage ?? null}
              alt="img"
              onClick={handleNextClick}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default LetsVerify;
