import { Checkbox, Flex, Form, Input, Layout, Radio } from "antd";

import "./EnrollSignature.scss";

const { Content } = Layout;

const EnrollSignature = () => {
  return (
    <Content className="EnrollSignature-content">
      <h2 className="container-title" style={{ marginTop: "120px" }}>
        The undersigned elects to enroll in one of the following Business
        Account types:
      </h2>

      <Flex vertical component={"form"}>
        <Flex vertical className="checkboxWrapper" gap="20px">
          <Flex className="checkboxContainer">
            <label style={{ display: "flex" }}>
              <Checkbox checked={true} />
              <div className="checkboxLabel">
                Mailing address and physical address are the same
              </div>
            </label>
          </Flex>
          <Flex className="checkboxContainer">
            <label style={{ display: "flex" }}>
              <Checkbox checked={true} />
              <div className="checkboxLabel">
                Share Savings Account (required)
              </div>
            </label>
          </Flex>
        </Flex>

        <Flex vertical className="box">
          <h3 className="title">E-sign consent notice online statement</h3>

          <Flex className="checkboxContainer">
            <label style={{ display: "flex", alignItems: "start" }}>
              <Checkbox checked={true} />
              <div className="checkboxLabel">
                By checking this box, you consent to receive your Periodic
                Statements, Annual Notice of Billing Error Rights, Privacy
                Notice, Annual Electronic Funds Transfer Notice and IRS 1098,
                1099 and 5498 Notices electronically. Your consent to electronic
                documents applies to all your deposit accounts. You may access
                your statements as PDF documents through the Online Banking
                system or through Mobile Banking, which requires a web browser
                through your mobile device. You must have Adobe Acrobat ReaderTM
                software to access the statements and a printer or ability to
                download the statements for your records. The statements for the
                new month will be available on the 5th of each month. You have
                the right to request and receive periodic statements in paper
                form at any time or you may withdraw your consent for online
                statements by contacting any branch, by calling 1-800- 000-0000
                or by emailing
                <b>
                  {" "}
                  <a href="mailto:support@yourfi.com">support@yourfi.com</a>
                </b>
                . There are no fees or account restrictions for choosing to
                withdraw your consent for online statements or to request a
                paper statement. You understand that you will also be required
                to log in to Online Banking (or Mobile Banking under the
                requirements above) to confirm your consent and ability to
                access online statements.
              </div>
            </label>
          </Flex>

          <h3 className="title">Authorization for phone/text communication</h3>

          <Flex className="checkboxContainer">
            <label style={{ display: "flex", alignItems: "start" }}>
              <Checkbox checked={true} />
              <div className="checkboxLabel">
                By checking this box, I expressly consent and authorize
                [Financial Institution] and its representatives to contact me by
                email, telephone (including cell phone), text message, or
                automated dialing communications at any number I provide the
                [Financial Institution] for my accounts or services, now or in
                the future for purposes of assisting with my accounts, services
                and to prevent fraud on my account. I understand I may revoke my
                consent at any time by providing the [Financial Institution]
                notice of my revocation.
              </div>
            </label>
          </Flex>
        </Flex>

        <Flex vertical className="wrapper sinature-wrapper">
          <Flex className="form" gap="40px" vertical>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Adam Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Adam Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex className="form" gap="40px" vertical>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Jack Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Jack Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex className="form" gap="40px" vertical>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"John Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"John Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex className="form" gap="40px" vertical>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Jane Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Jane Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Content>
  );
};

export default EnrollSignature;
