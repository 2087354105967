import React from "react";
import { Layout, List, Avatar, Button } from "antd";
import tickIcon from "../../../Assets/Images/tick.png";
import data from "./MobileUnderstandingBusiness.json";
import MobileFooter from "../MobileFooter/MobileFooter";
import MobileHeader from "../MobileHeader/MobileHeader";
import "./MobileUnderstandingBusiness.scss";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getValueFromStorage,
  getCurrentRoutePath,
} from "../../Utility/Utility";

import { RouteList } from "../../../../Redux/Selector";
import { useSelector } from "react-redux";

const { Content } = Layout;

const MobileUnderstandingBusiness = () => {
  const navigate = useNavigate();
  const documentsList =
    getValueFromStorage("DocumentData") && getValueFromStorage("DocumentData");
  const documents = documentsList ? JSON.parse(documentsList) : [];

  const location = useLocation();
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  const list = documents?.documentTypes?.map(
    (item: any) => item.documentTypeName
  );

  const handleListItems = (title: string) => {
    const str =
      list?.slice(0, -1).join(" or ") + " and " + list[list?.length - 1];
    const result = title.replace("{###}", str);
    return result;
  };

  const handleFilesizeListItem = (title: string) => {
    const fileUploadSizeLimit = getValueFromStorage("FileUploadSizeLimit");
    const limitvalue = fileUploadSizeLimit ? fileUploadSizeLimit : "5";
    const result = title.replace("{limit}", limitvalue);
    return result;
  };

  const onClick = () => {
    navigate("/understandingupload");
  };
  return (
    <Layout className="mobile-layout">
      <MobileHeader />
      <Content className="mobile-content">
        <div className="mobile-content-container">
          <div className="understanding-business-content-text">
            Understanding <br />
            your business
          </div>
          <div className="mobile-subHeading">Before you start, please:</div>
          <List
            itemLayout="horizontal"
            dataSource={data.points}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={tickIcon} className="tick-icon" />}
                  title={
                    <div
                      className="content-text"
                      dangerouslySetInnerHTML={{
                        __html:
                          index === 0
                            ? handleListItems(item.title)
                            : index === 2
                            ? handleFilesizeListItem(item.title)
                            : item.title,
                      }}
                    ></div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
        {/* <div className="mobile-button-container">
          <Button
            className="mobile-button"
            block
            onClick={() => navigate(CurrentRoutePath?.Next)}
          >
            Let’s go!
          </Button>
        </div> */}
      </Content>
      <MobileFooter buttonName="Let’s go!" onClick={onClick} />
    </Layout>
  );
};

export default MobileUnderstandingBusiness;
