interface IHttpCallback {
  (response: any): void;
}

export class ServiceBase {
  protected _axios: any;
  constructor(axios: any) {
    this._axios = axios;
  }

  protected async doGet<T>(
    url: string,
    cb?: IHttpCallback | undefined
  ): Promise<T> {
    try {
      const axiosInstance = await this._axios;
      const response = await axiosInstance.get(url);
      if (cb) {
        cb(response);
      }
      return response.data as T;
    } catch (error) {
      throw toServiceError(error);
    }
  }

  protected async doPost<T>(
    url: string,
    body?: any,
    config?: any,
    cb?: IHttpCallback | undefined
  ): Promise<T> {
    try {
      const axiosInstance = await this._axios;
      const response = await axiosInstance.post(url, body, config);
      if (cb) {
        cb(response);
      }
      return response.data as T;
    } catch (error) {
      throw toServiceError(error);
    }
  }

  protected async doPut<T>(
    url: string,
    body: any,
    cb?: IHttpCallback | undefined
  ): Promise<T> {
    try {
      const axiosInstance = await this._axios;
      const response = await axiosInstance.put(url, body);
      if (cb) {
        cb(response);
      }
      return response.data as T;
    } catch (error) {
      throw toServiceError(error);
    }
  }

  protected async doDelete<T>(
    url: string,
    cb?: IHttpCallback | undefined
  ): Promise<T> {
    try {
      const axiosInstance = await this._axios;
      const response = await axiosInstance.delete(url);
      if (cb) {
        cb(response);
      }
      return response.data as T;
    } catch (error) {
      throw toServiceError(error);
    }
  }
}

/**
 * Base class for all errors thrown by a service.
 */
class ServiceError extends Error {
  constructor(message?: string | undefined) {
    super(message);
  }
}

/**
 * Thrown when the network connection is down.
 */
// class NetworkError extends Error {}

/**
 * Thrown when the resource is not found on the server.
 */
// class NotFoundError extends ServiceError {
//   constructor(message?: string | undefined) {
//     super(message || "Resource not found");
//   }
// }

/**
 * Thrown when the response.status is "401"
 * This usually occurs if the access token is not sent in the request or the access token expired or is invalid.
 */
// class UnauthorizedAccessError extends ServiceError {
//   constructor(message?: string | undefined) {
//     super(message || "Not authorized, please login again");
//   }
// }

/**
 * Thrown when the response.status is "403"
 */
// class AccessControlError extends ServiceError {
//   constructor(message?: string | undefined) {
//     super(message || "Access denied");
//   }
// }

/**
 * Thrown when the response.status is "500"
 */
class ServerError extends ServiceError {
  constructor(message?: string | undefined) {
    super(message);
  }
}

function toServiceError(error: any) {
  //if (axios.isAxiosError(error)) {
  if (error.response) {
    const response: any = error.response;
    let message: any = error.response.data;
    if (response.status === 401) {
      message = message || "Unauthorized access";
      //  window.location.href = "/unauthorized";
    } else if (response.status === 403) {
      window.location.href = "/forbidden";
    } else if (message.state === 500) {
      throw new ServerError(message);
    }
    return error;
  }
  //}
  return new ServiceError(error.response);
}
