import {
  IWebLinkValue,
  IWebLinkStatusValue,
  IUpdateWebLinkStatusDetails
} from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class NotificationService extends ServiceBase
  implements INotificationService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getWebLinkSMS(body: IWebLinkValue) {
    let url = ApiEndpoints.Notification.getNotificationWeblinkSMSUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async getScanCode(body: IWebLinkValue) {
    let url = ApiEndpoints.Notification.getNotificationWeblinkSMSUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async getWebLinkStatus(body: IWebLinkStatusValue) {
    let url = ApiEndpoints.Notification.getWeblinkStatusUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async getWebLinkDetailsStatus(body: IWebLinkStatusValue) {
    let url = ApiEndpoints.Notification.getWebLinkStatusDetailsUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async updateWebLinkDetailsStatus(body: IUpdateWebLinkStatusDetails) {
    let url = ApiEndpoints.Notification.getUpdateWebStatusLinkDetailsUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }

}
export interface INotificationService {
  getWebLinkSMS(body: IWebLinkValue): Promise<any>;
  getScanCode(body: IWebLinkValue): Promise<any>;
  getWebLinkStatus(body: IWebLinkStatusValue): Promise<any>;
  getWebLinkDetailsStatus(body: IWebLinkStatusValue): Promise<any>;
  updateWebLinkDetailsStatus(body: IUpdateWebLinkStatusDetails): Promise<any>
}
