import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import "./GettingScan.scss";
import { Button, Layout, Row, Col } from "antd";

import qrCode from "../../Assets/Images/qrCode.svg";
import qrCodeImg from "../../Assets/Images/QRLQ.png";
import { useNavigate, useLocation } from "react-router-dom";

import FooterMain from "../Footer/FooterMain";

const { Content } = Layout;

const GettingScan: React.FC = () => {
  const navigate = useNavigate();

  const handlClick = (e: SyntheticEvent) => {
    navigate("/gettingconnected");
  };
  return (
    <>
      <Layout className="getting-scan-container" onClick={handlClick}>
        <Content className="getting-to-know-content">
          <h1 className="getting-to-know-title">Getting to know you</h1>
          <div className="getting-to-button-container">
            <h3 className="Identification_heading getting-header">
              Please scan your Identification document and take a selfie
            </h3>
            <div className="qr-code">
              <img width={320} height={320} src={qrCodeImg} alt="qr-code" />
            </div>
          </div>
        </Content>
      </Layout>
      <FooterMain
        nextPath="/"
        isConfirmBtnDisabled={true}
        currentStep={3}
        totalSteps={8}
        isProgressBarEnabled={true}
        name="Getting to know you"
      />
    </>
  );
};

export default GettingScan;
