import React from "react";
import { useLocation } from "react-router-dom";
import { Layout } from "antd";
import MobileFooter from "../MobileFooter/MobileFooter";
import UploadDocumentsContainer from "../UploadDocumentsContainer/UploadDocumentsContainer";
import UploadScreenHeader from "../UploadScreenHeader/UploadScreenHeader";
import { RouteList } from "../../../../Redux/Selector";
import { useSelector } from "react-redux";
import {
  getCurrentRoutePath,
  getValueFromStorage,
} from "../../Utility/Utility";

const { Content } = Layout;

const UploadMobileSelfie: React.FC = () => {
  const routePaths = useSelector(RouteList);
  const location = useLocation();
  const currentScreen = window.location.pathname;
  const currentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );
  const cardType = location?.state?.cardType;
  return (
    <Layout className="upload-frontdoc-container">
      <UploadScreenHeader
        gobackpath={currentRoutePath?.Previous}
        cardType={cardType}
      />
      <Content className="upload-frontdoc-content">
        <div className="upload-step-selfie-title">
          Getting <br />
          to know you
        </div>
        <UploadDocumentsContainer type="selfie" />
      </Content>
      <MobileFooter />
    </Layout>
  );
};

export default UploadMobileSelfie;
