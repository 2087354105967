import { Progress } from "antd";
import React from "react";
import "./StepsProgressBar.scss";

const StepsProgressBar = (props: {
  currentStep: number;
  steps?: string;
  progressBarClassName?: string
  totalSteps?: number;
  name?: string;
}) => {
  const current_step = props.currentStep;
  const total_steps = props.totalSteps ?? 8;
  const percentage = (current_step / total_steps) * 100;

  return (
    <>
      <Progress percent={percentage} size="small" showInfo={false}  />
      <p className="progress-step"><b>{`${props.currentStep}/${props.totalSteps}`}</b> {props?.name}</p>
    </>
  );
};

export default StepsProgressBar;
