import React, { useEffect, useState } from "react";
import "./Acuant.scss";
import { Button, Layout } from "antd";
import { IAcuantProps } from "../../Contracts/Contracts";
import accountcard from "../../Assets/Images/Dashboard/accountcard.png";
import { BeatLoader } from "react-spinners";
import UploadScreenHeader from "../MobileScreens/UploadScreenHeader/UploadScreenHeader";
import MobileFooter from "../MobileScreens/MobileFooter/MobileFooter";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";

const { Content } = Layout;

const AcuantCamera: React.FC<IAcuantProps> = (props: IAcuantProps) => {
  const [type, setType] = useState<string>("initial");
  const [image, setImage] = useState<any>(accountcard);
  const [showPopup, setShowPopup] = useState(false);
  const [isUseImage, setUseImage] = useState<boolean>(false);

  const [integersProperties, setIntegersProperties] = useState<{
    glare: number;
    sharpness: number;
  }>();

  useEffect(() => {
    setIntegersProperties({
      ...integersProperties,
      glare: 0,
      sharpness: 0,
    });
  }, []);

  useEffect(() => {
    console.log("AcuantCamera.", type);
    if (type == "initial") {
      openCamera();
    }
  }, [type]);

  const openCamera = () => {
    if (window?.AcuantCamera?.isCameraSupported && !window.liveCaptureFailed) {
      window?.AcuantCameraUI.start(cameraCallback, options);
    }
  };

  const options = {
    text: {
      NONE: "ALIGN",
      SMALL_DOCUMENT: "MOVE CLOSER",
      GOOD_DOCUMENT: null, //null countdown
      BIG_DOCUMENT: "TOO CLOSE",
      CAPTURING: "CAPTURING",
      TAP_TO_CAPTURE: "TAP TO CAPTURE",
    },
  };

  const cameraCallback: any = {
    onCaptured: (response: any) => {
      console.log(response, "onCaptured");
    },

    onCropped: (response: any) => {
      console.log(response, "onCropped");
      setType("preview");
      setIntegersProperties({
        ...integersProperties,
        glare: response?.glare,
        sharpness: response?.sharpness,
      });
      if (
        !response.glare ||
        response.glare <= 0 ||
        !response.sharpness ||
        response.sharpness < 50
      ) {
        setShowPopup(true);
      }
      setImage(response?.image?.data);
      if (props?.setcardType) {
        props?.setcardType(response?.cardType);
      }
    },

    onFrameAvailable: (response: any) => {
      //get each frame if needed
      //console.log(response)
    },
    onError: (error: any, code: any) => {
      console.error(error, code);
      window.liveCaptureFailed = true;

      var errorMessage = "";

      if (code === window?.AcuantJavascriptWebSdk.REPEAT_FAIL_CODE) {
        //live capture was started after previous failure leading to manual capture, show any elements you may have hidden as the user can cancel the prompt and could otherwise end up on a blank page.
        //cameraBtn.style.display = "block";
      } else {
        // modal.style.display = "block";
        switch (code) {
          case window?.AcuantJavascriptWebSdk.SEQUENCE_BREAK_CODE: //camera froze/crashed; usually happens due to an ios 15 bug
            errorMessage = "Live Camera failed. Start manual capture.";
            break;
          case window?.AcuantJavascriptWebSdk.START_FAIL_CODE: //camera not supported or permission not granted
            errorMessage = "Live Camera failed to open. (Camera not supported or permission not granted)";
            break;
          case window?.AcuantJavascriptWebSdk.HEIC_NOT_SUPPORTED_CODE:
            errorMessage = "HEIC image failed to process.";
            break;
          default:
            errorMessage = "Unknown camera failure. Start manual capture.";
            break;
        }
      }

      console.log(errorMessage);
      alert(errorMessage);
    },
  };

  const retakeImage = () => {
    setType("initial");
    //openCamera();
  };

  const useImage = () => {
    //TODO : need to navigate to next screen.
    if (!isUseImage && props.selectedImage) {
      setUseImage(true);
      props.selectedImage(image, responseCallBack);
    }
  };

  const responseCallBack = () => {
    setUseImage(false);
  };

  const isImageCorrect = () => {
    if (integersProperties)
      return integersProperties.glare > 0 && integersProperties.sharpness >= 50;
    else return false;
  };

  return (
    <Layout className="frontdoc-layout">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {type == "initial" ? (
          <>
            <div id="acuant-camera" className="acuant-camera-layout"></div>
          </>
        ) : type == "preview" ? (
          <Layout className="loquat-kyc-doc-preview">
            <UploadScreenHeader
              gobackpath={
                props?.title === "front" ? "/uploadfrontdoc" : "/uploadbackdoc"
              }
            />
            <Content>
              <div className="doc-title">
                <div className="upload-step-title">
                  Getting
                  <br /> to know you
                </div>
                <div className="docupload-title">
                  {props?.title === "front" ? "Front" : "Back"} of
                  identification <br />
                  document
                </div>
              </div>
              <div>
                <div className="preview-container">
                  <img className="preview-image" src={image}></img>
                </div>
                <div
                  className={`action-buttons ${
                    !isImageCorrect() ? "retake-center-button" : ""
                  }`}
                >
                  <Button
                    className={`accuantbtn acuant-retake ${
                      !isImageCorrect() ? "fullwidth-button" : ""
                    }`}
                    onClick={retakeImage}
                    disabled={isUseImage}
                  >
                    Retake
                  </Button>
                  {isImageCorrect() ? (
                    <Button
                      className="accuantbtn acuant-use"
                      onClick={useImage}
                      disabled={isUseImage}
                    >
                      {isUseImage ? (
                        <BeatLoader color="#f22fd2" />
                      ) : (
                        "Use picture"
                      )}
                    </Button>
                  ) : null}
                </div>
              </div>
            </Content>
            <MobileFooter />
            <NotificationPopup
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              headerText="Image Quality is low"
              descriptionText="Please retake the picture"
              buttonCount={1}
            />
          </Layout>
        ) : (
          <></>
        )}
      </div>
    </Layout>
  );
};

export default AcuantCamera;
