import { ILinkTokenValue ,IAccessTokenValue} from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class DigitalFinancialService
  extends ServiceBase
  implements IDigitalFinancialService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getLinkToken(body: ILinkTokenValue) {
    let url = ApiEndpoints.DigitalFinancial.getLinkTokenUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async getAccessToken(body: IAccessTokenValue) {
    let url = ApiEndpoints.DigitalFinancial.getAccessTokenUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IDigitalFinancialService {
  getLinkToken(body: ILinkTokenValue): Promise<any>;
  getAccessToken(body: IAccessTokenValue): Promise<any>;
}
