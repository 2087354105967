import React from "react";
import "./GettingThankYou.scss";
import { Layout, } from "antd";
import connected from "../../Assets/Images/img.svg";



import { useNavigate } from "react-router-dom";


import FooterMain from "../Footer/FooterMain";



const { Content } = Layout;

const GettingThankyou: React.FC = () => {



  return (
    <>
      <Content className="content-getting-thankyou">
        <h1 className="getting-to-know-title">Getting to know you</h1>
        <img src={connected} alt="connected" />
        <h3 className='subtitle'>Thank you!</h3>
        <div className='text'>Your verification data has been successfully submitted.
          You can move on to the next step</div>
      </Content>
      <FooterMain name="Getting to know you" nextPath="personalinfo" currentStep={3} totalSteps={8} isProgressBarEnabled={true} nextButtonText="Next" isBackBtnDisabled={true}/>

    </>
  );
};

export default GettingThankyou;
