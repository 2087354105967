import React, { useState, useEffect, useContext } from "react";
import { Layout, Space, Radio, Row, Typography, Input } from "antd";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./PleaseConfirm.scss";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import image from "../../Assets/Images/headerImages/Pleaseconfirm.png";
import { Form, useLocation, useNavigate } from "react-router-dom";
import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import { IStepsHeader } from "../../Contracts/Contracts";
import StepsProgressBar from "../Common/StepsProgressBar/StepsProgressBar";
import { RadioChangeEvent } from "antd/lib/radio";
import {
  getValueFromStorage,
  showNotification,
  getCurrentRoutePath,
} from "../Utility/Utility";
import { applicationMessages } from "../Utility/ApplicationMessages";

import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";
import FooterMain from "../Footer/FooterMain";
import { validateHeaderValue } from "http";

const { Content } = Layout;
const headerValues: IStepsHeader = {
  title: "Please Confirm",
  titleClass: "letsheadingh5",
  img: image,
};
const PleaseConfirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<string>("otherbeneficial");
  const finalStepOtherBeneficialNavigation =
    location?.state?.FinalStepOtherBeneficialNavigation;
  const { getLoquatClient } = useContext(ApplicationContext);
  const businessname = getValueFromStorage("businessName");
  const [valuesPercent, setValuesPercent] = useState<{ [key: string]: string }>(
    {
      inputNoOwners: "",
      inputOwners: "25%",
    }
  );

  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelectedBeneficiaryType();
  }, []);

  const getSelectedBeneficiaryType = () => {
    const service = getLoquatClient().businessService();
    service
      .getBusinessInfo()
      .then((response) => {
        if (response) {
          const beneficiaryType = response?.successResult?.beneficiaryType;
          if (beneficiaryType) {
            setSelectedValue(beneficiaryType);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRedirection = () => {
    var service = getLoquatClient().businessService();
    const payload = {
      beneficiaryType: selectedValue,
    };
    service
      .postBeneficiaryType(payload)
      .then((response) => {
        if (response.status === 400) {
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        } else {
          // showNotification(response?.successResult, "");
          if (
            (selectedValue && selectedValue === "otherbeneficial") ||
            (selectedValue && selectedValue === "otherbeneficialnoowners")
          ) {
            navigate("/finalstep");
          } else {
            navigate(CurrentRoutePath?.Next);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      });
  };

  const handleChangePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let inputValue = value.replace("%", "");
    if (inputValue) {
      inputValue = Math.min(
        100,
        Math.max(0, parseInt(inputValue, 10))
      ).toString();
      setValuesPercent((prevValues) => ({
        ...prevValues,
        [name]: inputValue + "%",
      }));
    } else {
      setValuesPercent((prevValues) => ({
        ...prevValues,
        [name]: "",
      }));
    }
  };

  return (
    <>
      <Layout className="please-confirm-layout">
        <Content className="please-confirm-content layout">
          <Row className="container">
            <Typography.Title level={1} className="title">
              Please confirm
            </Typography.Title>
            <Row>
              <p className="description">
                <b>* Responsible individuals</b> are vested with full authority
                to open and close accounts on behalf of the Business, add and
                remove Authorized Signers on behalf of the Business with respect
                to the accounts based upon the designated authority and
                Certificate of Authority set forth below
              </p>
              <p className="description">
                <b>** Beneficial owners.</b> To help the government fight
                financial crime, federal law requires the [Financial
                Institution] to obtain, verify and record information about the
                Beneficial Owners who own 25%, or more of the legal entity
              </p>
            </Row>
          </Row>
          <Row className="container">
            <Radio.Group
              onChange={handleRadioChange}
              value={selectedValue}
              className="radio"
            >
              <Radio
                className="radio__item"
                value={"otherbeneficialnoowners"}
                checked
              >
                <div className="radio__row">
                  <div className="radio__title">
                    I am a Responsible Individual and there are no other owners
                    who own 25% and more
                  </div>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      name="inputNoOwners"
                      className="info_inputTextClass radio__input"
                      placeholder="%"
                      maxLength={4}
                      value={valuesPercent.inputNoOwners}
                      onChange={handleChangePercent}
                    />
                    <span className="info_span placeholder">
                      I own (up to 100%)
                    </span>
                  </div>
                </div>
              </Radio>
              <Radio className="radio__item" value={"otherbeneficial"}>
                <div className="radio__row">
                  <div className="radio__title">
                    I am a Responsible Individual and there are other owners who
                    own 25% and more
                  </div>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      name="inputOwners"
                      className="info_inputTextClass radio__input"
                      placeholder="%"
                      maxLength={4}
                      value={valuesPercent.inputOwners}
                      onChange={handleChangePercent}
                    />
                    <span className="info_span placeholder">
                      I own (up to 75%)
                    </span>
                  </div>
                </div>
              </Radio>
            </Radio.Group>
          </Row>
        </Content>
      </Layout>
      <FooterMain
        nextPath={"otherbeneficial"}
        isConfirmBtnDisabled={selectedValue ? false : true}
        currentStep={7}
        totalSteps={8}
        isProgressBarEnabled={true}
        name="Beneficial ownership"
      />
      {/* <FooterMain
        nextPath={selectedValue}
        isConfirmBtnDisabled={selectedValue ? false : true}
        currentStep={5}
        totalSteps={8}
        isProgressBarEnabled={true}
      /> */}
    </>
  );
};

export default PleaseConfirm;
