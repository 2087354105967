import React, { useState, useContext, useEffect } from "react";
import logo from "../../Assets/Images/Dashboard/logo.png";
import signoutpic from "../../Assets/Images/Dashboard/defaultProfileImg.png";
import { Menu, Dropdown } from "antd";
import "./PlaidHeader.scss";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";

import { getValueFromStorage, handleImages } from "../Utility/Utility";

const PlaidHeader = () => {
  const { getLoquatClient } = useContext(ApplicationContext);
  const [userProfileDetails, setUserProfileDetails] = useState<any>({});

  const menu = () => (
    <Menu>
      <Menu.Item key="1">
        <div className="menu-options">
          <span className="option-title">Sign Out</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    const service = getLoquatClient().profileService();
    const userId = getValueFromStorage("UserId");
    service
      .getPersonalInfo(userId)
      .then((response: any) => {
        console.log(response);
        if (response) {
          setUserProfileDetails({ ...response?.successResult });
          //setValueInStorage("userName", response?.successResult?.firstName);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="plaid_header">
      <div className="plaid_header_left">
        <img className="logo_image" src={logo} alt="Logo" />
      </div>
      <div className="plaid_header_right">
        <img
          className="profileImg"
          src={
            userProfileDetails?.profileImage
              ? handleImages(userProfileDetails?.profileImage)
              : signoutpic
          }
          alt="Logo"
        />
        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <span className="signout">Sign out</span>
        </Dropdown>
      </div>
    </div>
  );
};

export default PlaidHeader;
