import { createSlice } from '@reduxjs/toolkit';
import RoutesJson  from '../../Web/Components/Utility/Routes.json';

const RouteJson = createSlice({
    name: 'Routes',
    initialState: {
        RoutesJson: RoutesJson
    },
    reducers: {
        setRoutes(state, action) {
            // state.RoutesJson = action.payload;
        }
    },
});

export const { setRoutes } = RouteJson.actions;
export default RouteJson.reducer;
