import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Select,
  Input,
  Flex,
  Form,
} from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import styles from "./SignUpForm.module.scss";
import logo from "../../../Assets/Images/logo.svg";
import { formatPhoneNumber } from "../../Utility/Utility";

const { Footer, Content } = Layout;

const SignUpForm = ({ handleNextClick, handleBackClick }: any) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [phone, setPhone] = useState("");

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(formatPhoneNumber(e.target.value));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Backspace" && phone.length <= 7) {
      event.preventDefault();
      setPhone("");
    }
  };

  return (
    <>
      <Content className={`${styles.container} `}>
        <Typography.Title level={1} className={styles.title}>
          Security Information
        </Typography.Title>
        <Typography.Text className={styles.terms_text}>
          Please provide a phone or email. This information will be used for
          one-time pass code (OTP) during enrollment. We may use this
          information for additional security on authentications or
          transactions.
        </Typography.Text>

        <Form className={styles.formContainer}>
          <div className={styles.inputWrapper}>
            <span className="text_poppins placeholder">Email address</span>
            <input type="email" className={styles.input} />
          </div>

          <div className={styles.inputWrapper}>
            <span className="text_poppins placeholder">Mobile phone</span>
            <input
              className={styles.input}
              type="phone"
              value={phone}
              onChange={handlePhoneChange}
              onKeyDown={handleKeyDown}
              maxLength={14}
            />
          </div>

          <Flex gap="10px" className={styles.btnGroup}>
            <Button onClick={handleBackClick}>CANCEL</Button>
            <Button className={styles.btnNext} onClick={handleNextClick}>
              NEXT
            </Button>
          </Flex>

          <Typography.Text className={styles.terms_text}>
            Need assistance?{" "}
            <span className={styles.terms_resend}>Contact Us</span>
          </Typography.Text>
        </Form>
      </Content>

      <Footer className={styles.footer}>
        <img src={logo} alt="logo" className={styles.logo} />
      </Footer>
    </>
  );
};

export default SignUpForm;
