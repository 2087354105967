import { Layout, Row, Col, Button, Typography, Flex } from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { applicationMessages } from "../Utility/ApplicationMessages";

import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";
import styles from './FooterMain.module.scss';
import logo from "../../Assets/Images/logo.svg";
import StepsProgressBar from "../Common/StepsProgressBar/StepsProgressBar";

interface ProgressBarDisabledProps {
  isProgressBarEnabled?: false;
  currentStep?: number;
  totalSteps?: number;
}

interface ProgressBarEnabledProps {
  isProgressBarEnabled: true;
  currentStep: number;
  totalSteps: number;
}
interface CommonPropsI {
  isConfirmBtnDisabled?: boolean;
  isBackBtnDisabled?: boolean;
  isConfirmBtnInactive?: boolean;
  nextPath?: string;
  nextButtonConfirmTitle?: string;
  onConfirmBtnClick?: () => void;
  nextButtonText?: string;
  backButtonText?: string;
  name?: string
}

type FooterMainProps = (ProgressBarDisabledProps | ProgressBarEnabledProps) & CommonPropsI;



const { Footer, Content } = Layout;

const FooterMain: React.FC<FooterMainProps> = ({
  isProgressBarEnabled, totalSteps, currentStep, nextPath, isConfirmBtnDisabled, isConfirmBtnInactive, onConfirmBtnClick, nextButtonText, isBackBtnDisabled, backButtonText,
  name
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPopup, setShowPopup] = useState(false);
  const isTermsPath = location.pathname.includes('/terms');


  const OnClick = () => {
    navigate(`/${nextPath}`);
  };

  const handleDecline = () => {
    setShowPopup(true);
  };

  const handleBack = () => {
    navigate(-1)
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className={styles.footer_substitute}></div>
      <Footer className={styles.terms_footer} style={{
        height: isProgressBarEnabled ? '166px' : '134px',
        padding: isProgressBarEnabled ? '16px 50px' : '35px 50px',
      }}>
        {isProgressBarEnabled && <StepsProgressBar
          currentStep={currentStep!}
          totalSteps={totalSteps}
          name={name}
        />}

        <Flex justify="space-between">
          <Button
            style={{ visibility: isBackBtnDisabled ? 'hidden' : 'visible' }}
            className={`${styles.button} ${styles.backgroundBlue}`}
            onClick={isTermsPath ? handleDecline : handleBack}
          >
            {backButtonText ? backButtonText : isTermsPath ? 'Decline' : 'Back'}

          </Button>

          <img src={logo} alt="logo" className={styles.logo} />
          <Button
            style={{ visibility: isConfirmBtnDisabled ? 'hidden' : 'visible' }}
            className={`${styles.button}  ${isConfirmBtnInactive ? styles.btnInactive : styles.backgroundOrange}`}
            disabled={isConfirmBtnInactive}
            onClick={onConfirmBtnClick ? onConfirmBtnClick : OnClick}>

            {nextButtonText ? nextButtonText : 'Confirm'}

          </Button>
        </Flex>
      </Footer>
      <NotificationPopup
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        headerText={applicationMessages.terms.termsDeclineMessage}
        buttonCount={1}
      />
    </>
  );
};

export default FooterMain;
