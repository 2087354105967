import React, { useEffect, useState } from "react";
import { Layout, Flex, Spin } from "antd";
import logo from "../../../../../Assets/Images/logo.svg";

import check from "../../../../../Assets/Images/check.svg";

import "./KYCWait.scss";

import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Content } = Layout;

const KYCWait = () => {
    const [step, setStep] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);

    const navigate = useNavigate();

    const onClick = () => {
    };

    const handleNextClick = () => {
        if (step < 4) {

            setStep((prev) => prev + 1);
        }
        else {
            navigate("/mobilethankyou");

        }
    };

    useEffect(() => {

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime + 1);
        }, 1000);

        return () => {
            clearInterval(timer);
        };

    }, [timeLeft]);

    const formatTime = (seconds: number) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor(seconds / 60) % 60;
        const remainingSeconds = seconds % 60;
        return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`;
    };



    return (
        <Layout
            className="mobile-layout kyc-container-wait"
            onClick={handleNextClick}
        >
            <Content className="mobile-content">
                <h1 className="title">Please wait</h1>

                <Flex vertical gap="16px" className="stepsContainer">
                    <p className="finaltimeshow">
                        <span className="finaltime">{formatTime(timeLeft)} </span>
                    </p>
                    <Flex>
                        {step < 1 ? (
                            <Spin indicator={antIcon} className="custom-spin" />
                        ) : (
                            <img src={check} alt="img" />
                        )}
                        <div className="text">Pictures processed</div>
                    </Flex>
                    <Flex>
                        {step < 2 ? (
                            <Spin indicator={antIcon} className="custom-spin" />
                        ) : (
                            <img src={check} alt="img" />
                        )}
                        <div className="text">Image quality checked</div>
                    </Flex>
                    <Flex>
                        {step < 3 ? (
                            <Spin indicator={antIcon} className="custom-spin" />
                        ) : (
                            <img src={check} alt="img" />
                        )}
                        <div className="text">ID is being verified</div>
                    </Flex>

                    <Flex>
                        {step < 4 ? (
                            <Spin indicator={antIcon} className="custom-spin" />
                        ) : (
                            <img src={check} alt="img" />
                        )}
                        <div className="text">Your information matches</div>
                    </Flex>
                </Flex>

                <div className="footer">
                    <img src={logo} alt="logo" className="logo" />
                </div>
            </Content>
        </Layout>
    );
};

export default KYCWait;
