import { createSlice } from '@reduxjs/toolkit';
import RoutesJson  from '../../Web/Components/Utility/Routes.json';

const UserApplicationStatus = createSlice({
    name: 'userApplicationStatus',
    initialState: {
        userApplicationStatusApiResponse: null,
        RoutesJson: RoutesJson
    },
    reducers: {
        setUserApplicationStatus(state, action) {
            state.userApplicationStatusApiResponse = action.payload;
        }
    },
});

export const { setUserApplicationStatus } = UserApplicationStatus.actions;
export default UserApplicationStatus.reducer;