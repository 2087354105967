import { Layout, Row, Col, Button, Typography, Select, Input, Flex, Form, Radio } from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import styles from './OPTMethodSelect.module.scss';
import logo from "../../../Assets/Images/logo.svg";




const { Footer, Content } = Layout;

const OPTMethodSelect = ({ handleNextClick, handleBackClick }: any) => {

  return (
    <>
      <Content className={styles.container}>
        <Typography.Title level={1} className={styles.title}>OTP Verification Method</Typography.Title>
        <Typography.Text className={styles.terms_text}>
          Before your account is created, a one-time passcode (OTP)
          will be sent to you to verify the contact information
          you provided.
        </Typography.Text>

        <Form className={styles.formContainer}>
          <Flex vertical>
            <Typography.Text className={styles.terms_text}>
              How do you want to get the code?
            </Typography.Text>

            <Flex>
              <label>
                <input type="radio" name="contact" value="Email" /> Email
              </label>
              <label>
                <input type="radio" name="contact" value="Text message" /> Text message
              </label>
            </Flex>

            <Typography.Text className={styles.terms_text}>
              By selecting text as the OTP verification method, you consent
              to receive text messages.
            </Typography.Text>
          </Flex>

          <Flex gap='10px' className={styles.btnGroup}>
            <Button onClick={handleBackClick}>Back</Button>
            <Button className={styles.btnNext} onClick={handleNextClick}>NEXT</Button>
          </Flex>

          <Typography.Text className={styles.terms_text}>
            Need assistance? <span className={styles.terms_resend}>Contact Us</span>
          </Typography.Text>
        </Form>


      </Content>

      <Footer className={styles.footer}>


        <img src={logo} alt="logo" className={styles.logo} />

      </Footer>

    </>
  );
};

export default OPTMethodSelect;
