import { IKYCDocProps, IKYCFrontData } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class KYCService extends ServiceBase implements IKYCService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async postKYCFronDocUpload(
    body: IKYCFrontData, // IKYCDocProps
  ) {
    let url = ApiEndpoints.KYCDocuments.setKYCFrontDoc();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async postKYCBackDocUpload(
    body: IKYCDocProps,
  ) {
    let url = ApiEndpoints.KYCDocuments.setKYCBackDoc();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async postKYCSelfieUpload(
    body: IKYCDocProps,
  ) {
    let url = ApiEndpoints.KYCDocuments.setKYCSelfie();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IKYCService {
    postKYCFronDocUpload(
    body: any,
  ): Promise<any>;
  postKYCBackDocUpload(
    body: any,
  ): Promise<any>;
  postKYCSelfieUpload(
    body: any,
  ): Promise<any>;
}
