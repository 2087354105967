import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import goback from "../../Assets/Images/goback.png";
import { IFooterProps } from "../../Contracts/Contracts";
import "./Footer.scss";
import Loader from "../Common/Loader/Loader";
import FinalSupportPopup from "../FinalSupportPopup/FinalSupportPopup";
import {
  setValueInStorage,
  getValueFromStorage,
  getCurrentRoutePath,
} from "../Utility/Utility";

import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";

const StpesFooter: React.FC<IFooterProps> = (FooterProps: IFooterProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);

  const storedClickCount = getValueFromStorage("understandingbusinessCount");
  const initialClickCount =
    storedClickCount !== null ? parseInt(storedClickCount) : 0;

  const businessinfoClickCount = getValueFromStorage("businessinfoCount");
  const initialCount =
    businessinfoClickCount !== null ? parseInt(businessinfoClickCount) : 0;
  const routePaths = useSelector(RouteList);
  const isFinalStepNavigation = getValueFromStorage("IsFinalStepNavigation");

  const onBackClick = () => {
    const currentScreen = location.pathname;
    const CurrentRoutePath = getCurrentRoutePath(
      currentScreen,
      routePaths.Routes
    );

    if (
      currentScreen == "/understandingbusiness" &&
      isFinalStepNavigation?.toLowerCase() !== "true"
    ) {
      if (initialClickCount >= 3) {
        setShowPopup(true);
        return true;
      }
      setValueInStorage(
        "understandingbusinessCount",
        (initialClickCount + 1).toString()
      );
    } else if (currentScreen == "/businessinfo") {
      if (initialCount >= 3) {
        setShowPopup(true);
        return true;
      }

      setValueInStorage("businessinfoCount", (initialCount + 1).toString());
    }
    if (
      isFinalStepNavigation &&
      isFinalStepNavigation.toLowerCase() === "true" &&
      FooterProps?.isRedirectedStep === true
    ) {
      navigate("/finalstep");
    } else {
      navigate(CurrentRoutePath?.Previous);
    }
  };

  const onNextClick = () => {
    if (FooterProps.onClick) {
      FooterProps.onClick();
    } else if (FooterProps.uploadDocuments) {
      FooterProps.uploadDocuments();
    } else if (FooterProps.inviteClick) {
      FooterProps.inviteClick();
    } else {
      navigate(FooterProps.nextPath, {
        state: {
          FinalStepNavigation: FooterProps.isFinalStepNavigation,
          FinalStepChangePictureNavigation:
            FooterProps.isFinalStepChangePictureNavigation,
          FinalStepDocumentNavigation:
            FooterProps.isFinalStepDocumentNavigation,
          FinalStepOtherBeneficialNavigation:
            FooterProps.isFinalStepOtherBeneficialNavigation,
        },
      });
    }
  };

  const onFormSubmit = () => {
    FooterProps?.form.submit();
  };

  return (
    <>
      <FinalSupportPopup showPopup={showPopup} setShowPopup={setShowPopup} />
      <div className="footerfixed">
        {!FooterProps.hideGoBack && (
          <div className="fixed_left">
            <p className="gobackcenter" onClick={onBackClick}>
              <span className="goback_imgmain">
                <img className="goback_img" src={goback} alt="Logo" />
              </span>

              <span className="goback">Go back</span>
            </p>
          </div>
        )}

        <div
          className={
            FooterProps?.fullWidth ? "full-width-right" : "fixed_right"
          }
        >
          <Button
            className={
              FooterProps?.fullWidth
                ? "full-width-next-button next-button"
                : " nextbtn next-button"
            }
            onClick={FooterProps.form ? onFormSubmit : onNextClick}
            disabled={
              FooterProps.loading
                ? FooterProps.loading
                : FooterProps.setDisable && FooterProps.setDisable
            }
          >
            {!FooterProps.loading ? (
              FooterProps?.buttonName ? (
                FooterProps.buttonName
              ) : (
                "Next"
              )
            ) : (
              <Loader
                checkLoading={FooterProps.loading}
                color={"#fff"}
                size={10}
                inline={true}
              />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default StpesFooter;
