import moment from "moment";
import { RuleObject } from "antd/lib/form";
import { notification } from "antd";
import { Dayjs } from "dayjs";

export const getFormattedDate = (
  dateString: string,
  format?: string | null
) => {
  format = format || "MMM, DD YYYY";
  return moment(dateString).locale("en_us").format(format);
};

export const getScheduleCallFormattedDate = (
  dateString: string,
  format?: string | null
) => {
  const date = new Date(dateString);
  return moment(date).format("MM/DD/YYYY");
};

export const getScheduleCallFormattedTime = (dateString: string) => {
  const date = new Date(dateString);
  return moment(date).format("hh:mm A");
};

export const setDateFormat = (date?: string) => {
  if (date) {
    // If a date is provided, create a new Date object
    const parsedDate = new Date(date);

    // Extract the local date components
    const year = parsedDate.getFullYear();
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");

    // Format the date as "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
  } else {
    // If no date is provided, use the current date
    const currentDate = new Date();

    // Extract the local date components
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    // Format the date as "YYYY-MM-DD"
    return `${year}-${month}-${day}`;
  }
};

export const getNonUTCFormat = (date?: string, format?: string) => {
  if (format) {
    let setDate = getFormattedDateForNonUTC(date, format);
    return setDate;
  } else {
    let setDate = date ? moment(date) : moment();
    return setDate;
  }
};
const getFormattedDateForNonUTC = (date?: string, format?: string) => {
  let formatDate = date ? moment(date).format(format) : moment().format(format);
  return formatDate;
};
export const setTimeFormat = (time?: string) => {
  return time ? moment(time).locale("en_us").format("h:mm a") : "";
};

export const getTimeFormat = (time?: string) => {
  return time ? moment(time, "hh:mm a") : moment("00:00:00", "h:mm a");
};

export const validateEmail = (rule: RuleObject, value: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!value) {
      reject("Email Address is required");
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(value)) {
      reject("Invalid Email Address");
    } else {
      resolve();
    }
  });
};

export const formatPhoneNumber = (input: string) => {
  const cleaned = input?.replace(/\D/g, "");
  const match = cleaned?.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `${match[1] ? `(${match[1]})` : ""} ${match[2] ? match[2] : ""}${
      match[2] && match[3] ? "-" : ""
    }${match[3] ? match[3] : ""}`;
  }
  return input;
};

export const validatePhoneNumber = async (
  _: unknown,
  value: string
): Promise<void> => {
  if (value === "") {
    return Promise.reject("Phone Number is required");
  }

  const cleaned = value?.replace(/\D/g, "");
  if (!cleaned?.match(/^\d{10}$/)) {
    return Promise.reject("Invalid Phone Number");
  }
};

export const handleNumberKeyDown = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  if (
    event.key === "Backspace" ||
    event.key === "Delete" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight"
  ) {
    return;
  }
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const validateFieldName =
  (fieldName: string) => (rule: unknown, value: string) => {
    const name = value;
    if (value && value.length < 2) {
      return Promise.reject(`Invalid ${fieldName}`);
    } else if (!name?.match(/^[a-zA-Z,.s]*$/)) {
      return Promise.reject(`Invalid ${fieldName}`);
    } else {
      return Promise.resolve();
    }
  };

export const isNumericInput = (event: any) => {
  const key = event.keyCode;
  return (key >= 48 && key <= 57) || (key >= 96 && key <= 105);
};

export const formattedSSNValue = (value: string) => {
  let newArray = value.split("");
  let masked = "X".repeat(newArray.length > 5 ? 5 : newArray.length);
  var maskedArray = masked.split("");
  let temp: string[] = [];
  if (newArray.length > 5) {
    temp = newArray.slice(5);
  }
  maskedArray.push(...temp);
  if (maskedArray.length > 2) {
    maskedArray.splice(3, 0, "-");
  }
  if (maskedArray.length > 6) {
    maskedArray.splice(6, 0, "-");
  }
  return maskedArray.join("");
};

export const formattedEINValue = (value: string) => {
  let newArray = value.split("");
  let masked = "X".repeat(newArray.length);
  var maskedArray = masked.split("");
  if (maskedArray.length > 1) {
    maskedArray.splice(2, 0, "-");
  }
  return maskedArray.join("");
};

export const formatInputEINValue = (inputValue: any) => {
  const cleanedValue = inputValue.replace(/\D/g, "");
  // Apply the "82-xxxxxxx" format
  if (cleanedValue.length <= 2) {
    return cleanedValue;
  } else {
    return `${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2, 9)}`;
  }
};

export const getTimerPromise = (callback: any) => {
  setTimeout(() => {
    callback();
  }, 2000);
};

export const showNotification = (
  message: string,
  description?: string,
  error?: boolean
) => {
  if (error) {
    notification.error({
      message: message || "",
      description: description || "",
    });
  } else {
    notification.success({
      message: message || "",
      description: description || "",
      duration: 2,
    });
  }
};

export const clearStorage = () => {
  sessionStorage.removeItem("UserType");
  sessionStorage.removeItem("AccessToken");
  sessionStorage.removeItem("ProductKey");
  sessionStorage.removeItem("UserId");
  sessionStorage.removeItem("businessId");
  sessionStorage.removeItem("businessName");
  sessionStorage.removeItem("ScheduleCall");
  sessionStorage.removeItem("PhoneNumber");
  sessionStorage.removeItem("CountryCode");
  sessionStorage.removeItem("businessId");
  sessionStorage.removeItem("businessName");
  sessionStorage.removeItem("SelectedLegalType");
  sessionStorage.removeItem("UploadFileIndex");
  sessionStorage.removeItem("WelcomeBack");
  sessionStorage.removeItem("FlowType");
  sessionStorage.removeItem("DocumentData");
};

export const convertBase64ToBlob = (base64: any) => {
  // const binaryString = atob(base64String.split(",")[1]); // Binary data string
  // const blob = new Blob([binaryString], { type: "image/png" });
  // return blob;

  return "asdasdsad";
};

export const handleLocationPermission = (callback: any) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        callback(latitude, longitude);
      },
      (error) => {
        if (error.code === 1) {
          callback(null, null, error);
        }
      }
    );
  } else {
    callback(null, null, "notSelected");
  }
};

export const handleSessionStorage = (
  keys: string[],
  action: string,
  keyValuePairs?: { [key: string]: any }
) => {
  if (action === "remove") {
    keys.forEach(function (key) {
      sessionStorage.removeItem(key);
    });
  } else if (action === "set" && keyValuePairs) {
    Object.entries(keyValuePairs).forEach(function ([key, value]) {
      sessionStorage.setItem(key, value);
    });
  }
};

export const getValueFromStorage = (key: string) => {
  return sessionStorage.getItem(key);
};

export const setValueInStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

export const removeValueFromStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const formatCardNumber = (value: string) => {
  let result = "";
  return result;
};

export function FormErrorHandlingFunction(form: any) {
  const allErrors = form.getFieldsError();
  const resultArray = [];
  for (let i = 0; i < allErrors.length; i++) {
    const item = allErrors[i];
    if (item?.errors?.length > 0) {
      resultArray.push(item.name[0]);
    }
  }
  return resultArray;
}

export const disabledDateRange = (current: any) => {
  if (!current) {
    return false; // Handle null case if necessary
  }
  // Get the current date
  const currentDate = new Date();

  // Disable today's date
  const isToday = current && current.isSame(currentDate, "day");

  // Disable dates in the future or 100 years in the past
  const isFuture = current && current > currentDate;
  const is100YearsAgo =
    current &&
    current <
      new Date(
        currentDate.getFullYear() - 100,
        currentDate.getMonth(),
        currentDate.getDate()
      );

  return isToday || isFuture || is100YearsAgo;
};

export const disabledScheduleCallDateRange = (current: any) => {
  if (!current) {
    return false; // Handle null case if necessary
  }
  // // Get the current date
  // const currentDate = new Date();

  // // Disable today's date
  // const isToday = current && current.isSame(currentDate, "day");

  // Disable dates in the future or 100 years in the past
  const isFuture = moment().add(-1, "days") >= current;

  return isFuture;
};

export const disabledMinutes = (date: any) => {
  const currentDate = moment();
  if (date > currentDate) {
    return [];
  }

  const minutes = [];
  const currentMinute = moment().minute();
  if (date === moment().hour()) {
    for (let i = currentMinute; i <= currentMinute && i >= 0; i--) {
      minutes.push(i);
    }
  }

  return minutes;
};

export const getDisabledHours = (date: any) => {
  var hours = [];
  const currentDate = moment();

  if (date > currentDate) {
    return [];
  }

  for (let i = 0; i < moment().hour(); i++) {
    hours.push(i);
  }

  return hours;
};

export const handleImages = (src: string) => {
  if (src) {
    if (src?.includes("data:image")) {
      return src;
    } else {
      return `data:image/png;base64,${src}`;
    }
  }
};

export const getCurrentRoutePath = (currentScreen: String, routePaths: any) => {
  const filteredPaths = routePaths.filter((item: any) =>
    item.Current.includes(currentScreen)
  );
  return filteredPaths[0];
};

export const getObjectWithFormattedPhone = <T extends { phone: string }>(
  obj: T
): T => {
  return {
    ...obj,
    phone: obj?.phone ? formatPhoneNumber(obj!.phone) : undefined,
  };
};
