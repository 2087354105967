import React, { useState } from "react";
import { Layout, List, Avatar, Button, Flex, Modal } from "antd";
import logo from "../../../../Assets/Images/logo.svg";
import connected from "../../../../Assets/Images/img.svg";

import "./ThankYou.scss";

const { Content } = Layout;

const ThankYou = () => {
  return (
    <Layout className="mobile-layout kyc-container">
      <Content className="mobile-content">
        <Flex vertical align="center">
          <h1 className="title">Thank you!</h1>
          <img width={124} height={124} src={connected} alt="connected" />
          <div className="text-container">
            <span>Your verification data has been successfully submitted.</span>
            <span>
              You can now close this window and return to your computer
            </span>
          </div>
        </Flex>
        <div className="footer">
          <img src={logo} alt="logo" className="logo" />
        </div>
      </Content>
    </Layout>
  );
};

export default ThankYou;
