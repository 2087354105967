import React, { useEffect, useContext, useState } from "react";
import "./Tabs.scss";
import { Layout, Tabs, Image, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";

import Cards from "../Cards/Cards";
import MyAccount from "../MyAccount/Myaccount";
import mail from "../../../Assets/Images/DashboardImages/mailImage.png";
import msg from "../../../Assets/Images/DashboardImages/msgImage.png";
import { getValueFromStorage } from "../../Utility/Utility";
import FinalSupportPopup from "../../FinalSupportPopup/FinalSupportPopup";
import moment from "moment";
import { useSelector } from 'react-redux';
import { countdownTimer } from '../../../../Redux/Selector';

const onChange = (key: string) => {
  console.log(key);
};

const items = (
  userDetails: any,
  cardNumber: string,
  dateValue: string,
  callBack: any
) => {
  return [
    {
      key: "1",
      label: "My accounts",
      children: (
        <MyAccount
          userName={userDetails?.firstName}
          cardNumber={cardNumber}
          regenerateCard={callBack}
        />
      ),
    },
    {
      key: "2",
      label: "Business loan",
      children: "Content of Tab Pane 2",
      disabled: true,
    },
    {
      key: "3",
      label: "Cards",
      children: (
        <Cards
          cardNumber={cardNumber}
          regenerateCard={callBack}
          issuedDate={dateValue}
        />
      ),
    },
    {
      key: "4",
      label: "Account management",
      children: "Content of Tab Pane 3",
      disabled: true,
    },
  ];
};

const TabView: React.FC<any> = ({ userDetails }) => {
  const { getLoquatClient } = useContext(ApplicationContext);
  const [cardNumber, setCardNumber] = useState("");
  const [dateValue, setDateValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const timer = useSelector(countdownTimer);

  useEffect(() => {
    generateCardDetails(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSupportPopup = () => {
    setShowPopup(true);
  };

  const operations = (
    <div className="righticons">
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Search"
        style={{ width: 150 }}
      />
      <a className="notification">
        <Image src={mail} alt="front" loading="lazy" preview={false} />
        <span className="badge">3</span>
        {/* <span className="badge">{timer} seconds</span> */}
      </a>
      <Image
        src={msg}
        alt="front"
        loading="lazy"
        preview={false}
        className="msgImage"
        onClick={handleSupportPopup}
      />
    </div>
  );

  const generateCardDetails = (isRenerate: boolean) => {
    const service = getLoquatClient().virtualAccountService();
    const businessId = getValueFromStorage("businessId");
    const userid = getValueFromStorage("UserId");
    const payload = {
      businessId: businessId,
      userId: userid,
      regenerateCard: isRenerate,
    };
    service
      .getAddCard(payload)
      .then((response: any) => {
        //  console.log("response",response)
        setCardNumber(response?.successResult?.cardNumber);
        if (response?.successResult?.issuedDate) {
          setDateValue(
            moment(response?.successResult?.issuedDate).format(
              "MMM DD, YYYY, hh:mm A"
            )
          );
        } else {
          setDateValue(moment().format("MMM DD, YYYY, hh:mm A"));
        }
      })
      .catch((error: any) => {
        console.log(error);
        // notification.error({
        //   message: "An error occurred",
        //   description: "Unable to fetch user's application status",
        // });
      });
  };

  return (
    <Layout className="tabs-layout">
      <div>
        <Tabs
          defaultActiveKey="1"
          items={items(userDetails, cardNumber, dateValue, generateCardDetails)}
          onChange={onChange}
          tabBarExtraContent={operations}
        />
        <FinalSupportPopup showPopup={showPopup} setShowPopup={setShowPopup} />
      </div>
    </Layout>
  );
};
export default TabView;
