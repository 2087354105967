import React, { useContext, useEffect, useState } from "react";
import "./MobileSelfie.scss";
import { Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import AcuantSelfie from "../../Acuant/AcuantSelfie";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { applicationMessages } from "../../Utility/ApplicationMessages";
import {
  getTimerPromise,
  showNotification,
  getCurrentRoutePath,
} from "../../Utility/Utility";
import CameraPermission from "../../Common/CameraPermission/CameraPermission";

import { RouteList } from "../../../../Redux/Selector";
import { useSelector } from "react-redux";

const MobileSelfie = () => {
  const navigate = useNavigate();
  const { getLoquatClient } = useContext(ApplicationContext);
  const [isSelfieUploading, setSelfieUploading] = useState<boolean>(false);
  const [isAcuantSelfie, setAcuantSelfie] = useState<boolean>(true);
  const [isCameraPermission, setCameraPermission] = useState<boolean | null>(
    null
  );
  const location = useLocation();
  const routePaths = useSelector(RouteList);
  const currentScreen = window.location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  useEffect(() => {
    console.log("MobileSelfie Loaded");
  }, []);

  const selectedImage = (image: any, callBack: any) => {
    var service = getLoquatClient().kycService();
    setAcuantSelfie(false);
    setSelfieUploading(true);
    console.log("selectedImage Loaded");
    service
      .postKYCSelfieUpload(image)
      .then((response: any) => {
        // showNotification(applicationMessages.kyc.selfie);
        getTimerPromise(() => {
          setSelfieUploading(false);
          navigate("/verifying");
        });
      })
      .catch((error: any) => {
        showNotification(applicationMessages.errorFileUpload, "", true);
      });
  };

  const handleToggle = (value: any) => {
    setCameraPermission(value);
  };

  return (
    <Layout className="mobileselfie-layout">
      {isSelfieUploading ? (
        <>
          <div className="uploading-loader-container">
            <div className="uploading-text">
              <BeatLoader />
              <span>Uploading Selfie</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {isAcuantSelfie ? (
            isCameraPermission === true ? (
              <AcuantSelfie selectedImage={selectedImage} title="" />
            ) : (
              <CameraPermission
                cameraPermission={isCameraPermission}
                onToggle={handleToggle}
              />
            )
          ) : (
            ""
          )}
        </>
      )}
    </Layout>
  );
};

export default MobileSelfie;
