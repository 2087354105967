import React from "react";
import "./MobileFooterSample.scss";
import { Layout, Row, Col, Button } from "antd";

const { Footer } = Layout;

type IMobileFooterprops = {
  onClick?: () => void;
  buttonName?: string;
};

const MobileFooterSample = (props: IMobileFooterprops) => {
  const date=new Date();
  const year =date.getFullYear();
  const onButtonClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <div className="mobile-footer-sample">
      {props.buttonName && (
        <div className="mobileotp-sample-button-container">
          <Button className="mobile-sample-next-button" block onClick={onButtonClick}>
            {props.buttonName}
          </Button>
        </div>
      )}
      <Row align="middle" justify="space-between">
        <Col className="colored-text">Loquat Inc. © {year}</Col>
        {/* <Col className="link-text">Privacy Policy</Col> */}
        <Col className="link-text"> Privacy Policy</Col>
      </Row>
    </div>
  );
};

export default MobileFooterSample;
