import { IPersonalInfoPostDetails, IPostLegalTypeDetails } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class ProfileService extends ServiceBase implements IProfileService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getPersonalInfo(userId: string) {
    let url = ApiEndpoints.Profile.getPersonalInfo(userId);
    const response: any = await this.doGet(url);
    return response;
  }

  async postPersonalInfo(body: IPersonalInfoPostDetails) {
    let url = ApiEndpoints.Profile.postPersonalInfo();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async postLegalType(body: IPostLegalTypeDetails){
    let url = ApiEndpoints.Profile.postLegaltype();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async getUserApplicationStatus() {
    let url = ApiEndpoints.Profile.getUserAppStatus();
    const response: any = await this.doGet(url);
    return response;
  }
}

export interface IProfileService {
  getPersonalInfo(userId: string | null): Promise<any>;
  postPersonalInfo(body: IPersonalInfoPostDetails): Promise<any>;
  postLegalType(body: IPostLegalTypeDetails):Promise<any>;
  getUserApplicationStatus(): Promise<any>;
}
