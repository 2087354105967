import { Layout, Row, Col, Button, Typography, Checkbox, Radio, Flex, Input, Form, Space, Select } from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { applicationMessages } from "../../Utility/ApplicationMessages";

import NotificationPopup from "../../Common/NotificationPopup/NotificationPopup";
import './ResponsibleIndividuals.scss';
import { RadioChangeEvent } from "antd/lib/radio";
import logo from "../../Assets/Images/logo.svg";
import deleteIcon from "../../../Assets/Images/ic_trash_can_24px.png";
import FooterMain from "../../Footer/FooterMain";
import { IAddIndividualValues, IBeneficiaryListDetailsWithOwners } from "../../../Contracts/Contracts";
import {
  FormErrorHandlingFunction,
  disabledDateRange,
  formattedSSNValue,
  getTimerPromise,
  getValueFromStorage,
  handleNumberKeyDown,
  showNotification,
  validateEmail,
  validateFieldName,
  formatPhoneNumber
} from "../../Utility/Utility";
import Column from "antd/es/table/Column";
import { validateHeaderValue } from "http";

interface ResponsibleIndividualsProps {
  personalInfoValues: IBeneficiaryListDetailsWithOwners;
  index: number;
  authorize?: boolean;
  owners?: boolean;
}

const { Footer, Content } = Layout;

const ResponsibleIndividuals: React.FC<ResponsibleIndividualsProps> = ({ personalInfoValues, index, authorize = true, owners = false }) => {
  const [form] = Form.useForm();
  const [selectedState, setSelectedState] = useState<any>({});
  const [formValues, setFormValues] = useState<IBeneficiaryListDetailsWithOwners | any>(
    personalInfoValues
  );
  const [keyState, setKeyState] = useState<number>(index + 1);
  const [showOwn, setShowOwn] = useState<boolean>(formValues.owner);
  const [selectedValue, setSelectedValue] = useState<string | null>(formValues.individual);
  const [valuesPercent, setValuesPercent] = useState<{ [key: string]: string }>({
    inputNoOwners: formValues.theyown,
    inputOwners: formValues.theyown
  });

  const onChange = (fieldName: string, value: any) => {
    let updatedValues;
    if (fieldName.toLowerCase() === "phone") {
      updatedValues = {
        ...formValues,
        phone: formatPhoneNumber(value),
      };
    } else {
      updatedValues = {
        ...formValues,
        [fieldName]: value,
      };
    }
    if (fieldName.toLowerCase() === "state") {
      setSelectedState({ name: value });
    }
    form.setFieldsValue(updatedValues);
    setFormValues(updatedValues);
  };

  const handleKeyPress = (e: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) => {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault();
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Backspace" && formValues?.phone.length <= 7) {
      event.preventDefault();
      form.setFieldsValue({ phone: "" });
      setFormValues((prevValues: IAddIndividualValues) => ({
        ...prevValues,
        phone: "",
      }));
    }
  };

  const changeShowOwn = () => {
    setShowOwn(!showOwn);
  }

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedValue(e.target.value);
  };

  const handleChangePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let inputValue = value.replace('%', '');
    if (inputValue) {
      inputValue = Math.min(100, Math.max(0, parseInt(inputValue, 10))).toString();
      setValuesPercent((prevValues) => ({
        ...prevValues,
        [name]: inputValue + '%'
      }));
    } else {
      setValuesPercent((prevValues) => ({
        ...prevValues,
        [name]: ''
      }));
    }
  };

  return (
    <Row className="main-container main-container_responsible">
      <Form
        form={form}
        className="personalinfo-form"
        initialValues={formValues}
        scrollToFirstError={true}
      >
      <Flex vertical  className="responsible-column">
        { authorize && (
          <Flex justify='space-between' gap='20px'>
            <div className="responsible-number">
              {/* {keyState} */}
            </div>
            <Button className="responsible-delete-btn">
              Delete
              <img src={deleteIcon} alt="Trash" /> 
            </Button>
          </Flex>
        )}
        { owners && (
          <Radio.Group onChange={handleRadioChange} value={selectedValue} className="radio">
            <Radio className="radio__item" value={"individualShow"}>
              <div className="radio__row">
                <div className="radio__title">
                  Individual
                </div>
              </div>
            </Radio>
            <Radio className="radio__item" value={"anotherShow"}>
              <div className="radio__row">
                <div className="radio__title">
                  Another business entity
                </div>
              </div>
            </Radio>
          </Radio.Group>
        )}
        { owners && selectedValue === "anotherShow" && (
          <>
            <Form.Item
              name="businessname"
              style={{ flex: 1 }}
            >
              <div
                className={`info_borderText application-inputBox`}
              >
                <Input
                  className="info_inputTextClass"
                  placeholder="Business Name"
                  bordered={false}
                  onChange={(e) => onChange("businessname", e.target.value)}
                  value={formValues.businesname}
                  onKeyDown={(e) => handleKeyPress(e)}
                  required
                />
                <span className="info_span placeholder">Business Name</span>
              </div>
            </Form.Item>
            <Form.Item
              name="doingbusiness"
              style={{ flex: 1 }}
            >
              <div
                className={`info_borderText application-inputBox`}
              >
                <Input
                  className="info_inputTextClass"
                  placeholder="Doing Business As (DBA)"
                  bordered={false}
                  onChange={(e) => onChange("doingbusiness", e.target.value)}
                  value={formValues.doingbusines}
                  onKeyDown={(e) => handleKeyPress(e)}
                  required
                />
                <span className="info_span placeholder">Doing Business As (DBA)</span>
              </div>
            </Form.Item>
            <Form.Item
              name="eintin"
              style={{ flex: 1 }}
            >
              <div
                className={`info_borderText application-inputBox`}
              >
                <Input
                  className="info_inputTextClass"
                  placeholder="EIN / TIN"
                  bordered={false}
                  onChange={(e) => onChange("eintin", e.target.value)}
                  value={formValues.eintin}
                  onKeyDown={(e) => handleKeyPress(e)}
                  required
                />
                <span className="info_span placeholder">EIN / TIN</span>
              </div>
            </Form.Item>
            <Form.Item
              name="eintin"
              style={{ flex: 1 }}
            >
              <div
                className={`info_borderText application-inputBox`}
              >
                    <Space wrap className="fullWidth info_inputTextClass">
                      <Select
                        className="selectBusinessWidth"
                        placeholder="Legal Type"
                        options={
                          [
                            {
                              value: "Limited Liability Corporation",
                              label: "Limited Liability Corporation",
                            },
                            {
                              value: "Limited Liability Corporation 2",
                              label: "Limited Liability Corporation 2",
                            },
                            {
                              value: "Limited Liability Corporation 3",
                              label: "Limited Liability Corporation 3",
                            },
                          ]
                        }
                        bordered={false}
                        onChange={(value) => onChange("industryId", value)}
                        // defaultValue={selectedOption?.id}
                        value={formValues.legaltype}
                      />
                    </Space>
              </div>
            </Form.Item>
          </>
        )}
        { owners && selectedValue === "anotherShow" && (
          <div className="responsible-form-title-second">Individual(s) who is the Beneficial Owner of this entity </div>
        )}
        <Flex justify='space-between' gap='20px'>
          <Form.Item
            name="firstname"
            rules={[
              { required: true, message: "First Name is required" },
              {
                validator: validateFieldName("First Name"),
              },
            ]}
            style={{ flex: 1 }}
          >
            <div
              className={`info_borderText application-inputBox`}
            >
              <Input
                className="info_inputTextClass"
                placeholder="First Name"
                bordered={false}
                onChange={(e) => onChange("firstname", e.target.value)}
                value={formValues.firstname}
                onKeyDown={(e) => handleKeyPress(e)}
                required
              />
              <span className="info_span placeholder">First Name</span>
            </div>
          </Form.Item>
          <Form.Item
            name="lastname"
            rules={[
              { required: true, message: "Last Name is required" },
              {
                validator: validateFieldName("Last Name"),
              },
            ]}
            style={{ flex: 1 }}
          >
            <div
              className={`info_borderText application-inputBox`}
            >
              <Input
                className="info_inputTextClass"
                placeholder="Last Name"
                bordered={false}
                onChange={(e) => onChange("lastname", e.target.value)}
                value={formValues.lastname}
                onKeyDown={(e) => handleKeyPress(e)}
                required
              />
              <span className="info_span placeholder">Last Name</span>
            </div>
          </Form.Item>
        </Flex>

        <Flex justify='space-between' gap='20px'>
          <Form.Item
            name="phone"

          >
            <div
              className={`info_borderText application-inputBox`}
            >
              <Input
                className="info_inputTextClass"
                bordered={false}
                placeholder="Phone"
                onKeyDown={handleKeyDown}
                onChange={(e) => onChange("phone", e.target.value)}
                value={formValues.phone}
                maxLength={14}
                required
              />
              <span className="info_span placeholder">Phone</span>
            </div>
          </Form.Item>
          <Form.Item name="email" rules={[{ validator: validateEmail }]}>
            <div
              className={`info_borderText application-inputBox`}
            >
              <Input
                className="info_inputTextClass"
                bordered={false}
                placeholder="Email"
                onChange={(e) => onChange("email", e.target.value)}
                value={formValues.email}
                required
              />
              <span className="info_span placeholder">Email</span>
            </div>
          </Form.Item>
        </Flex>

        { authorize && !owners && (
          <Flex justify='space-between' gap='20px'>
            <Form.Item
              name="beneficial"

            >
              <div
                className={`checkbox`}
              >
                <Checkbox className="checkbox__item" checked={showOwn} 
                  onChange={() => changeShowOwn()}
                >
                  <span className="checkbox__title">
                    Beneficial owner
                  </span>
                </Checkbox>
              </div>
            </Form.Item>
            {showOwn && (
            <Form.Item name="theyown">
              <div
                className={`info_borderText application-inputBox`}
              >
                <Input
                  name="inputNoOwners"
                  className="info_inputTextClass"
                  bordered={false}
                  placeholder="%"
                  required
                  maxLength={4}
                  value={valuesPercent.inputNoOwners}
                  onChange={handleChangePercent}
                />
                <span className="info_span placeholder">They Own (up to 75%)</span>
              </div>
            </Form.Item>
            )}
          </Flex>
        )}
        { owners && selectedValue === "individualShow" && (
            <Flex justify='space-between' gap='20px'>
              <Form.Item name="theyownowners">
                <div
                  className={`info_borderText application-inputBox`}
                >
                  <Input
                    name="inputOwners"
                    className="info_inputTextClass"
                    bordered={false}
                    placeholder="They Own (up to 75%)"
                    required
                    maxLength={4}
                    value={valuesPercent.inputOwners}
                    onChange={handleChangePercent}
                  />
                  <span className="info_span placeholder">They Own (up to 75%)</span>
                </div>
              </Form.Item>
              <Form.Item></Form.Item>
            </Flex>
          )}
        </Flex>
      </Form>
    </Row>
  );
};

export default ResponsibleIndividuals;
