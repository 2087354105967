import { Layout, Row, Col, Button, Typography, Select, Input, Flex, Form, Radio } from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import styles from './OPTForm.module.scss';
import logo from "../../../Assets/Images/logo.svg";




const { Footer, Content } = Layout;

const OPTForm = ({ handleNextClick, handleBackClick }: any) => {

  return (
    <>
      <Content className={styles.container}>
        <Typography.Title level={1} className={styles.title}>Enter One-Time Passcode</Typography.Title>
        <Typography.Text className={styles.terms_text}>
          A text message with the verification code was just sent to <b>(***) ***-8358</b>.
        </Typography.Text>

        <Form className={styles.formContainer}>


          <div className={styles.inputWrapper}>
            <span className="text_poppins placeholder">Enter the code</span>
            <input
              type="text"
              className={styles.input}
            />
          </div>

          <Typography.Text className={styles.terms_text}>
            Didn't get it? <span className={styles.terms_resend}>Resend</span>
          </Typography.Text>

          <Flex gap='10px' className={styles.btnGroup}>
            <Button onClick={handleBackClick}>Back</Button>
            <Button className={styles.btnNext} onClick={handleNextClick}>NEXT</Button>
          </Flex>

          <Typography.Text className={styles.terms_text}>
            Need assistance? <span className={styles.terms_resend}>Contact Us</span>
          </Typography.Text>

        </Form>


      </Content>

      <Footer className={styles.footer}>


        <img src={logo} alt="logo" className={styles.logo} />

      </Footer>

    </>
  );
};

export default OPTForm;
