import React, { useContext, useState } from "react";
import { Row, Col, Image } from "antd";
import "./Cards.scss";
import cardImg from "../../../Assets/Images/DashboardImages/cardImage1.png";
import copy from "../../../Assets/Images/DashboardImages/copy.png";
import refresh from "../../../Assets/Images/DashboardImages/refresh.png";
import btn from "../../../Assets/Images/DashboardImages/transBtn.png";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { getValueFromStorage } from "../../Utility/Utility";
import { IMyAccountProps } from "../../../Contracts/Contracts";
import Transactions from "../Transactions/Transactions";

const Cards: React.FC<IMyAccountProps> = (profileDetails: IMyAccountProps) => {
  const [showTransactions, setShowTransactions] = useState(false);

  const regenerate = () => {
    if (profileDetails && profileDetails.regenerateCard) {
      profileDetails.regenerateCard(true);
    }
  };

  const handleTransctions = () => {
    setShowTransactions(true);
  };

  return (
    <div className="cardsContent">
      {!showTransactions ? (
        <>
          <span className="CardsHeading">XYZ Loqwallet</span>
          <Row className="cardalign">
            <h3 className="eachCardHeading">Virtual card</h3>
          </Row>
          <Row className="cardSection">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <Image
                src={cardImg}
                alt="front"
                loading="lazy"
                preview={false}
                className="cardImgMargin"
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <p className="accountNum">**********1234</p>

              <div>
                <div className="cardalign">
                  <span className="detailsHead">Card number</span>
                  <div className="detailsDiv">
                    <span className="cardDetails cardNumber">
                      {profileDetails?.cardNumber?.match(/.{1,4}/g)?.join(" ")}
                    </span>
                    <div className="cardCopy">
                      <Image
                        src={copy}
                        alt="front"
                        loading="lazy"
                        className="copyImage"
                        preview={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="validity cardalign">
                  <div className="floatLeft margin20 validtyWidth">
                    <span className="detailsHead">Valid thru</span>
                    <div>
                      <span className="cardDetails">06/25</span>
                    </div>
                  </div>
                  <div className="floatLeft cvvWidth">
                    <span className="detailsHead">CVV</span>
                    <div>
                      <span className="cardDetails">121</span>
                    </div>
                  </div>
                </div>
                <div className="cardalign validity">
                  <span className="issued">
                    Issued: {profileDetails?.issuedDate}
                  </span>
                </div>
                <div
                  className="validity cardalign cursor"
                  onClick={() => regenerate()}
                >
                  <span className="Regenrate underline">
                    Regenerate card details
                  </span>
                  <Image
                    src={refresh}
                    alt="front"
                    loading="lazy"
                    className="refreshImage"
                    preview={false}
                  />
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="onlyMobileMargin"
            >
              <div className="floatLeft margin20">
                <span className="detailsHead">Available amount</span>
                <div>
                  <span className="amount">$0.00</span>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              className="onlyMobileMargin"
            >
              <Image
                src={btn}
                alt="front"
                loading="lazy"
                preview={false}
                onClick={handleTransctions}
                className="transaction-btn"
              />
            </Col>
          </Row>
          <hr className="commonColr"></hr>
          <Row className="cardalign">
            <h3 className="eachCardHeading">Physical card</h3>
          </Row>
          <Row className="cardSection">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <Image
                src={cardImg}
                alt="front"
                loading="lazy"
                preview={false}
                className="cardImgMargin"
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
            >
              <p className="accountNum">**********1235</p>
              <div>
                <div className="cardalign">
                  <span className="detailsHead">Card number</span>
                  <div className="detailsDiv">
                    <span className="cardDetails">**** **** **** 4321</span>
                    <div className="cardCopy">
                      <Image
                        src={copy}
                        alt="front"
                        loading="lazy"
                        className="copyImage"
                        preview={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="validity cardalign">
                  <div className="floatLeft margin20 validtyWidth">
                    <span className="detailsHead">Valid thru</span>
                    <div>
                      <span className="cardDetails">06/28</span>
                    </div>
                  </div>
                  <div className="floatLeft cvvWidth">
                    <span className="detailsHead">CVV</span>
                    <div>
                      <span className="cardDetails">380</span>
                    </div>
                  </div>
                </div>
                <div className="cardalign validity">
                  <span className="issued">Issued: Jan 14, 2023, 2:40 PM</span>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="onlyMobileMargin"
            >
              <div className="floatLeft margin20">
                <span className="detailsHead">Available amount</span>
                <div>
                  <span className="amount">$0.00</span>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 20 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 4 }}
              className="onlyMobileMargin"
            >
              <Image
                src={btn}
                alt="front"
                loading="lazy"
                preview={false}
                onClick={handleTransctions}
                className="transaction-btn"
              />
            </Col>
          </Row>
        </>
      ) : (
        <Transactions
          showTransactions={showTransactions}
          setShowTransactions={setShowTransactions}
        />
      )}
    </div>
  );
};

export default Cards;
