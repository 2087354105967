import React, { useContext, useState } from "react";
import "./BackDocument.scss";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";

import AcuantCamera from "../../Acuant/AcuantCamera";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { applicationMessages } from "../../Utility/ApplicationMessages";
import {
  getTimerPromise,
  getValueFromStorage,
  showNotification,
} from "../../Utility/Utility";
import CameraPermission from "../../Common/CameraPermission/CameraPermission";

const BackDocument = () => {
  const navigate = useNavigate();
  const type = getValueFromStorage("FlowType");
  const { getLoquatClient } = useContext(ApplicationContext);
  const [isCameraPermission, setCameraPermission] = useState<boolean | null>(
    null
  );

  // const onSubmitClick = () => {};

  const handleRedirection = () => {
    if (type?.toLowerCase() === "front") {
      navigate("/verifying");
    } else if (type?.toLowerCase() === "selfie") {
      navigate("/verifying");
    } else {
      navigate("/uploadmobileselfie");
    }
  };

  const selectedImage = (image: any, callBack: any) => {
    var service = getLoquatClient().kycService();
    service
      .postKYCBackDocUpload(image)
      .then((response) => {
        // showNotification(applicationMessages.kyc.back)
        getTimerPromise(() => {
          handleRedirection();
        });
      })
      .catch((error) => {
        showNotification(applicationMessages.errorFileUpload, "", true);
        callBack();
      });
  };

  const handleToggle = (value: any) => {
    setCameraPermission(value);
  };

  return (
    <Layout className="back-layout">
      {isCameraPermission === true ? (
        <AcuantCamera selectedImage={selectedImage} title="back" />
      ) : (
        <CameraPermission
          cameraPermission={isCameraPermission}
          onToggle={handleToggle}
        />
      )}
    </Layout>
  );
};

export default BackDocument;
