import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Button, Flex, Typography } from "antd";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./OtherBeneficial.scss";
import image from "../../Assets/Images/headerImages/Pleaseconfirm.png";
import plusIcon from "../../Assets/Images/ic_plus_24px.png";
import close from "../../Assets/Images/close.png";
import { useNavigate, useLocation } from "react-router-dom";
import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import {
  IStepsHeader,
  IAddIndividualValues,
  IBeneficiaryListDetails,
  IBeneficiaryOwners,
  IBeneficiaryListDetailsWithOwners,
} from "../../Contracts/Contracts";
import StepsProgressBar from "../Common/StepsProgressBar/StepsProgressBar";
import { applicationMessages } from "../Utility/ApplicationMessages";
import { DEFAULT_CONFIG } from "../../Configuration/Config";
import {
  getTimerPromise,
  showNotification,
  removeValueFromStorage,
  getObjectWithFormattedPhone,
} from "../Utility/Utility";
import OnDeleteUserPopup from "./OnDeleteUserPopup";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import PleaseNotePopup from "./PleaseNotePopup";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";
import FinalSupportPopup from "../FinalSupportPopup/FinalSupportPopup";
import FooterMain from "../Footer/FooterMain";
import ResponsibleIndividuals from "./ResponsibleIndividuals/ResponsibleIndividuals";
const { Content } = Layout;

const headerValues: IStepsHeader = {
  title: "Please Confirm",
  titleClass: "letsheadingh5",
  img: image,
};

const mockBeneficiaryListDetails: IBeneficiaryListDetailsWithOwners[] = [
  {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    owner: "",
    theyown: "",
    individual: "",
    businesname: "",
    doingbusines: "",
    eintin: "",
    legaltype: "",
  },
];

// mock
// const mockBeneficiaryListDetailsNoOwners: IBeneficiaryListDetailsWithOwners[] = [
//   {
//     firstname: "John",
//     lastname: "Smith",
//     phone: "(000) 000-0000",
//     email: "jsmith@gmail.com",
//     owner: "",
//     theyown: "",
//     individual: "",
//     businesname: "",
//     doingbusines: "",
//     eintin: "",
//     legaltype: "",
//   },
// ];

const mockBeneficiaryListDetailsNoOwners: IBeneficiaryListDetailsWithOwners[] =
  [
    {
      firstname: "John",
      lastname: "Smith",
      phone: "(000) 000-0000",
      email: "josmith@gmail.com",
      owner: "",
      theyown: "",
      individual: "",
      businesname: "",
      doingbusines: "",
      eintin: "",
      legaltype: "",
    },
    {
      firstname: "Jack",
      lastname: "Smith",
      phone: "(000) 000-0000",
      email: "jasmith@gmail.com",
      owner: true,
      theyown: "75%",
      individual: "",
      businesname: "",
      doingbusines: "",
      eintin: "",
      legaltype: "",
    },
  ];

const mockBeneficiaryListDetailsAuthorize: IBeneficiaryListDetailsWithOwners[] =
  [
    {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      owner: "",
      theyown: "",
      individual: "",
      businesname: "",
      doingbusines: "",
      eintin: "",
      legaltype: "",
    },
  ];

// const mockBeneficiaryListDetailsAuthorizeNoOwners: IBeneficiaryListDetailsWithOwners[] = [
//   {
//     firstname: "",
//     lastname: "",
//     phone: "",
//     email: "",
//     owner: "",
//     theyown: "",
//     individual: "",
//     businesname: "",
//     doingbusines: "",
//     eintin: "",
//     legaltype: "",
//   },
// ];

const mockBeneficiaryListDetailsAuthorizeNoOwners: IBeneficiaryListDetailsWithOwners[] =
  [
    {
      firstname: "Jane",
      lastname: "Smith",
      phone: "(000) 000-0000",
      email: "jnsmith@gmail.com",
      owner: "",
      theyown: "",
      individual: "",
      businesname: "",
      doingbusines: "",
      eintin: "",
      legaltype: "",
    },
  ];

// const mockBeneficiaryListDetailsOwners: IBeneficiaryListDetailsWithOwners[] = [
//   {
//     firstname: "",
//     lastname: "",
//     phone: "",
//     email: "",
//     owner: "",
//     theyown: "",
//     individual: "",
//     businesname: "",
//     doingbusines: "",
//     eintin: "",
//     legaltype: "",
//   },
//   {
//     firstname: "",
//     lastname: "",
//     phone: "",
//     email: "",
//     owner: "",
//     theyown: "",
//     individual: "",
//     businesname: "",
//     doingbusines: "",
//     eintin: "",
//     legaltype: "",
//   },
// ];

const mockBeneficiaryListDetailsOwners: IBeneficiaryListDetailsWithOwners[] = [
  // {
  //   firstname: "James",
  //   lastname: "Smith",
  //   phone: "(000) 000-0000",
  //   email: "jsmith@gmail.com",
  //   owner: true,
  //   theyown: "75%",
  //   individual: "individualShow",
  //   businesname: "",
  //   doingbusines: "",
  //   eintin: "",
  //   legaltype: "",
  // },
  {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    owner: true,
    theyown: "",
    individual: "individualShow",
    businesname: "",
    doingbusines: "",
    eintin: "",
    legaltype: "",
  },
  // {
  //   firstname: "Joan",
  //   lastname: "Clark",
  //   phone: "(000) 000-0000",
  //   email: "jclark@gmail.com",
  //   owner: true,
  //   theyown: "65%",
  //   individual: "anotherShow",
  //   businesname: "Joan's Coffee Shop",
  //   doingbusines: "Joan's Coffee Shop",
  //   eintin: "XX-XXX0000",
  //   legaltype: "LLC (Limited Liability Company)",
  // },
];

interface OtherPleaseConfirmProps {
  noowners?: boolean;
}

const OtherPleaseConfirm: React.FC<OtherPleaseConfirmProps> = ({
  noowners = false,
}) => {
  const { getLoquatClient } = useContext(ApplicationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [individualDetails, setIndividualDetails] = useState<
    IAddIndividualValues[]
  >([]);
  // const [beneficiaryDetails, setBeneficiaryDetails] = useState<
  //   IBeneficiaryListDetails[]
  // >([]);
  // mock
  const [beneficiaryDetails, setBeneficiaryDetails] = useState<
    IBeneficiaryListDetailsWithOwners[]
  >(
    (!noowners
      ? mockBeneficiaryListDetailsNoOwners
      : mockBeneficiaryListDetails
    ).map((i) => getObjectWithFormattedPhone(i))
  );
  const [beneficiaryDetailsAuthorize, setBeneficiaryDetailsAuthorize] =
    useState<IBeneficiaryListDetailsWithOwners[]>(
      (!noowners
        ? mockBeneficiaryListDetailsAuthorizeNoOwners
        : mockBeneficiaryListDetailsAuthorize
      ).map((i) => getObjectWithFormattedPhone(i))
    );
  const [beneficiaryDetailsOwners, setBeneficiaryDetailsOwners] = useState<
    IBeneficiaryListDetailsWithOwners[]
  >(
    mockBeneficiaryListDetailsOwners.map((i) => getObjectWithFormattedPhone(i))
  );
  const [buttonName, setButtonName] = useState<string>("Next");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isPleaseNoteModalVisible, setIsPleaseNoteModalVisible] =
    useState(false);
  const [selectionPopupValue, setSelectionPopupValue] = useState(0);
  const [deletingBeneficiaryId, setDeletingBeneficiaryId] = useState<
    string | null
  >(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [onClickNavigation, setOnClickNavigation] = useState(false);
  const [showSupportPopup, setShowSupportPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    // getBeneficiaryDetailsList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSupportPopup = () => {
    setShowSupportPopup(true);
  };

  const getBeneficiaryDetailsList = () => {
    var service = getLoquatClient().beneficiaryService();
    setShowLoader(true);
    service
      .getBeneficiaryList()
      .then((response) => {
        setShowLoader(false);
        if (response.status === 400) {
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        } else {
          const beneficials = response.successResult;
          if (beneficials.length > 0) {
            setBeneficiaryDetails(beneficials);
            // Check if all invitations are sent
            const allInvitationsSent = areAllInvitationsSent(beneficials);
            if (allInvitationsSent) {
              setButtonName("Confirm");
            } else {
              setButtonName("Invite");
            }
          } else {
            setBeneficiaryDetails([]);
            setButtonName("Next");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setShowLoader(false);
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      });
  };

  const handleNavigation = () => {
    if (beneficiaryDetails.length >= 3) {
      return setIsPleaseNoteModalVisible(true);
    }
    navigate("/addindividual");
  };

  const handleShowNotification = () => {
    showNotification(applicationMessages.connectToCustomerMsg, "");
  };

  const handlePostInvite = () => {
    setLoading(true);
    var service = getLoquatClient().beneficiaryService();
    service
      .postBeneficiaryOwnerInvitation()
      .then((response) => {
        if (response.status === 400) {
          showNotification("", response.successResult, true);
        } else {
          if (buttonName.toLowerCase() === "confirm") {
            navigate("/finalstep");
          } else {
            // setNotificationMessage(response.successResult);
            // setOnClickNavigation(true);
            // setShowNotificationPopup(true);
            navigate("/beneficialthanks");
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      });
  };

  const handleOkClick = () => {
    navigate("/beneficialthanks");
  };

  const handleDeleteIndividual = (userId: string) => {
    if (beneficiaryDetails?.length === 1) {
      setIsDeleteModalVisible(true);
      return;
    }
    // Set deletingBeneficiaryId to the userId of the beneficiary being deleted
    setDeletingBeneficiaryId(userId);

    const service = getLoquatClient().beneficiaryService();
    service
      .deleteBeneficiary(userId)
      .then((response) => {
        if (response.status === 400) {
          showNotification("", response.successResult, true);
        } else {
          // setNotificationMessage("Beneficiary deleted successfully");
          // setOnClickNavigation(false);
          // setShowNotificationPopup(true);
          getBeneficiaryDetailsList();
        }
      })
      .catch((error) => {
        console.log(error);
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      })
      .finally(() => {
        // Reset deletingBeneficiaryId when the API call completes
        setDeletingBeneficiaryId(null);
      });
  };

  const formatPhoneNumber = (phoneNumber: any) => {
    const cleaned = phoneNumber.replace(/\D/g, "");
    if (cleaned.length === 10) {
      return `(${cleaned.substring(0, 3)}) ${cleaned.substring(
        3,
        6
      )}-${cleaned.substring(6)}`;
    }
    return phoneNumber;
  };

  // Function to check if all beneficials have isinvitationsent as true
  const areAllInvitationsSent = (beneficialOwners: IBeneficiaryOwners[]) => {
    for (const beneficial of beneficialOwners) {
      if (beneficial?.isinvitationsent !== true) {
        return false; // If isinvitationsent is null or false, return false
      }
    }
    return true; // All objects have isinvitationsent as true
  };

  const emptyBeneficiary: IBeneficiaryListDetailsWithOwners = {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    owner: "",
    theyown: "",
    individual: "",
    businesname: "",
    doingbusines: "",
    eintin: "",
    legaltype: "Limited Liability Corporation",
  };

  const addEmptyBeneficiary = () => {
    setBeneficiaryDetails([...beneficiaryDetails, emptyBeneficiary]);
  };

  const addEmptyBeneficiaryAuthorize = () => {
    setBeneficiaryDetailsAuthorize([
      ...beneficiaryDetailsAuthorize,
      emptyBeneficiary,
    ]);
  };

  const addEmptyBeneficiaryOwners = () => {
    setBeneficiaryDetailsOwners([
      ...beneficiaryDetailsOwners,
      emptyBeneficiary,
    ]);
  };

  return (
    <>
      <Layout className="other-beneficial-container other-layout layout">
        <Content className="other-content">
          <div className="other-button-container">
            <div className="title">Please provide</div>
            <div className="description">
              <p>
                Please provide the names of additional Responsible Individuals
                and Authorized Signers you would like to include.
              </p>
              <p>We will send them a link to confirm their identity.</p>
              <p>
                Authorized Signers are vested with limited authority to transact
                any business on such accounts including the following, but may
                not make changes to the accounts:
              </p>
              <p>
                - Depositing, withdrawing, transferring funds into, out of, and
                between one or more accounts;
                <br />
                - Signing drafts, checks or other orders for payment or
                withdrawal;
                <br />
                - Issuing instructions regarding order for payment or
                withdrawal;
                <br />
                - Endorsing any check, draft, share certificate and other
                instrument or order for payment owned or held by the Business
                <br />
                - Initiating ACH Transactions and Wire Transfers
                <br />- Receiving information of any nature about the account
              </p>
            </div>
            {showLoader ? (
              <div className="showLoader">
                <Spin
                  indicator={
                    <LoadingOutlined className="beneficiary-list-loader" spin />
                  }
                />
              </div>
            ) : (
              <>
                <Typography.Title level={2} className="title_second">
                  Responsible Individuals
                </Typography.Title>
                {beneficiaryDetails.length > 0 &&
                  beneficiaryDetails?.map(
                    (
                      item: IBeneficiaryListDetailsWithOwners,
                      index: number
                    ) => (
                      <ResponsibleIndividuals
                        personalInfoValues={item}
                        key={index}
                        index={index}
                        authorize={!noowners}
                      />
                    )
                  )}
                <Flex justify="center">
                  <Button
                    className="beneficial-btn-plus"
                    onClick={addEmptyBeneficiary}
                  >
                    Add Responsible Individual <img src={plusIcon} alt="" />
                  </Button>
                </Flex>
              </>
            )}
            {showLoader ? (
              <div className="showLoader">
                <Spin
                  indicator={
                    <LoadingOutlined className="beneficiary-list-loader" spin />
                  }
                />
              </div>
            ) : (
              <>
                <Typography.Title level={2} className="title_second">
                  Authorized Signers
                </Typography.Title>
                {beneficiaryDetailsAuthorize.length > 0 &&
                  beneficiaryDetailsAuthorize?.map(
                    (
                      item: IBeneficiaryListDetailsWithOwners,
                      index: number
                    ) => (
                      <ResponsibleIndividuals
                        personalInfoValues={item}
                        key={index}
                        index={index}
                        authorize={false}
                      />
                    )
                  )}
                <Flex justify="center">
                  <Button
                    className="beneficial-btn-plus"
                    onClick={addEmptyBeneficiaryAuthorize}
                  >
                    Add Authorized Signer <img src={plusIcon} alt="" />
                  </Button>
                </Flex>
              </>
            )}
            {!noowners &&
              (showLoader ? (
                <div className="showLoader">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        className="beneficiary-list-loader"
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                <>
                  <Typography.Title level={2} className="title_second">
                    Beneficial Owners
                  </Typography.Title>
                  {beneficiaryDetailsOwners.length > 0 &&
                    beneficiaryDetailsOwners?.map(
                      (
                        item: IBeneficiaryListDetailsWithOwners,
                        index: number
                      ) => (
                        <ResponsibleIndividuals
                          personalInfoValues={item}
                          key={index}
                          index={index}
                          owners={true}
                        />
                      )
                    )}
                  <Flex justify="center">
                    <Button
                      className="beneficial-btn-plus"
                      onClick={addEmptyBeneficiaryOwners}
                    >
                      Add Beneficial owner <img src={plusIcon} alt="" />
                    </Button>
                  </Flex>
                </>
              ))}
          </div>
        </Content>
        {/* {isDeleteModalVisible && (
          <OnDeleteUserPopup
            isDeleteModalVisible={isDeleteModalVisible}
            setIsDeleteModalVisible={setIsDeleteModalVisible}
            setSelectionPopupValue={setSelectionPopupValue}
            selectionPopupValue={selectionPopupValue}
            individualDetails={individualDetails}
            setIndividualDetails={setIndividualDetails}
            beneficiaryDetails={beneficiaryDetails}
          />
        )} */}
        {isPleaseNoteModalVisible && (
          <PleaseNotePopup
            isPleaseNoteModalVisible={isPleaseNoteModalVisible}
            setIsPleaseNoteModalVisible={setIsPleaseNoteModalVisible}
          />
        )}
        <NotificationPopup
          showPopup={showNotificationPopup}
          setShowPopup={setShowNotificationPopup}
          headerText={notificationMessage}
          buttonCount={1}
          onClickNavigation={onClickNavigation}
          handleClick={handleOkClick}
        />
        <FinalSupportPopup
          showPopup={showSupportPopup}
          setShowPopup={setShowSupportPopup}
        />
      </Layout>
      <FooterMain
        nextPath="finalthankyou"
        currentStep={7}
        totalSteps={8}
        isProgressBarEnabled={true}
        nextButtonText="Invite"
        name="Beneficial ownership"
      />
    </>
  );
};

export default OtherPleaseConfirm;
