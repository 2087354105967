import { Layout, Form, Input, Button } from "antd";

import "./AccountFunding.scss";

import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/logo.svg";

const { Content } = Layout;
const AccountFunding = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleClick = () => {
    navigate("/finalstep");
  };

  return (
    <>
      <Content className="finalthankyou-content">
        <p className="title">
          Please set up funding for your account to allow for expedited account
          opening
        </p>

        <div className="text">
          <p>
            We have partnered with Plaid to offer a way to authenticate your
            external bank account and transfer funds into your new business
            account with [Financial Institution].
          </p>

          <p>
            If interested, please enter the amount below and press Continue.
          </p>
          <p>
            Please note, we will not transfer the funds until after your account
            has been approved and opened
          </p>
        </div>

        <div className="funding-container">
          <Form form={form} className="funding-form" scrollToFirstError={true}>
            <Form.Item name="amount">
              <div className={`info_borderText application-inputBox `}>
                <Input
                  className="info_inputTextClass"
                  bordered={false}
                  prefix="$"
                  placeholder="Amount"
                />
              </div>
            </Form.Item>

            <div className="buttons-start">
              <Button
                className={`button backgroundOrange`}
                onClick={handleClick}
              >
                Continue
              </Button>
            </div>
            <div>
              <Button className={`button backgroundBlue`} onClick={handleClick}>
                I’ll fund my account later
              </Button>
            </div>
          </Form>
        </div>
      </Content>
      <img src={logo} alt="logo" className={"logo"} />
    </>
  );
};

export default AccountFunding;
