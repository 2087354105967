import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import finalconfirm from "../../Assets/Images/finalconfirm.png";
import image from "../../Assets/Images/headerImages/finalConfirmed.png";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./FinalConfirm.scss";
import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import { IStepsHeader } from "../../Contracts/Contracts";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;
const headerValues: IStepsHeader = {
  title: "Your application confirmed!",
  titleClass: "letsheadingfinalconfirm",
  img: image,
};
const FinalConfirm = () => {
  const [timeLeft, setTimeLeft] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft < 2) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime + 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    } else {
      navigate("/finalthankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);
  return (
    <Layout className="confirm-layout finalconfirmscreen">
      <StepsHeader {...headerValues} />
      <Content className="finalconfirm-content">
        <div className="finalconfirm-container">
          <div className="finalconfirm-group">
            <img
              className="finalcornerimg"
              src={finalconfirm}
              alt="finallogo"
            />
          </div>
        </div>
      </Content>
      <PrivacyPolicy />
      <StpesFooter gobackPath="/finalstep" nextPath="/finalthankyou" />
    </Layout>
  );
};

export default FinalConfirm;
