import React, { useContext, useEffect, useState } from "react";
import "./GettingToKnow.scss";
import { Button, Layout, Row, Col } from "antd";
import camera from "../../Assets/Images/Camera.svg";
import phone from "../../Assets/Images/SMS.svg";
import { useNavigate, useLocation } from "react-router-dom";
import {

  getValueFromStorage,
  setValueInStorage,
} from "../Utility/Utility";
import FooterMain from "../Footer/FooterMain";


const { Content } = Layout;

const GettingToKnow: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectType = getValueFromStorage("FlowType")
    ? getValueFromStorage("FlowType")
    : "";
  const [type, setType] = useState(redirectType);
  const finalStepNavigation = location?.state?.FinalStepNavigation;
  const finalStepChangePicturenavigation =
    location?.state?.FinalStepChangePictureNavigation;


  useEffect(() => {
    if (finalStepNavigation && finalStepNavigation === true) {
      setType("front");
      setValueInStorage("FlowType", "front");
    } else if (
      finalStepChangePicturenavigation &&
      finalStepChangePicturenavigation === true
    ) {
      setValueInStorage("FlowType", "selfie");
      setType("selfie");
    } else if (redirectType) {
      setType(redirectType);
      setValueInStorage("FlowType", redirectType);
    } else {
      setType("");
      setValueInStorage("FlowType", "");
    }
  }, []);



  const handlClick = () => {
    navigate("/gettingscan");
  };
  return (
    <>

      <Layout className="getting-to-know-container">

        <Content className="getting-to-know-content">
          <h1 className="getting-to-know-title">Getting to know you</h1>
          <div className="getting-to-button-container">

            <h3 className="Identification_heading">
              Please scan your identification document and take a selfie
            </h3>
            <div className="getting-to-button-group">
              <Button
                className={`button`}
                block
                onClick={handlClick}
              >
                <img src={camera} alt="Logo" />
                Use your mobile device's camera
              </Button>
              <Button
                className={`button`}
                block
                onClick={handlClick}
              >
                <img src={phone} alt="Logo" />
                Get weblink via SMS
              </Button>
            </div>

          </div>
        </Content>

      </Layout>
      <FooterMain name="Getting to know you" nextPath="/" isConfirmBtnDisabled={true} currentStep={3} totalSteps={8} isProgressBarEnabled={true}/>
    </>
  );
};

export default GettingToKnow;
