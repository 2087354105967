import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Select, Dropdown, Button } from "antd";
import "./Myaccount.scss";
import accountcard from "../../../Assets/Images/Dashboard/accountcard.png";
import refresh from "../../../Assets/Images/Dashboard/refresh.png";
import paytransfer from "../../../Assets/Images/Dashboard/paytransfer.png";
import vector from "../../../Assets/Images/Dashboard/vector.png";
import applayright from "../../../Assets/Images/Dashboard/applayright.png";
import applyleft from "../../../Assets/Images/Dashboard/applyleft.png";
import spending from "../../../Assets/Images/DashboardImages/Analytics.png";
import { useNavigate } from "react-router-dom";
import { getValueFromStorage } from "../../Utility/Utility";
import { IMyAccountProps } from "../../../Contracts/Contracts";
import type { MenuProps } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const MyAccount: React.FC<IMyAccountProps> = (
  profileDetails: IMyAccountProps
) => {
  const navigate = useNavigate();

  const businessname = getValueFromStorage("businessName");
  const userName = getValueFromStorage("userName");
  const [open, setOpen] = useState(false);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key) {
      setOpen(false);
    }
  };

  const regenerate = () => {
    if (profileDetails && profileDetails.regenerateCard) {
      profileDetails.regenerateCard(true);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: "Account Info",
      key: "1",
    },
    {
      label: "Statements & Documents",
      key: "2",
    },
    {
      label: "Authorize additional access to the account",
      key: "3",
    },
  ];

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <div className="cardsContentacc">
      <span className="acuntjohn">Hello {profileDetails.userName}!</span>
      <Row>
        <div className="myaccountsec">
          <h3 className="eachCardHeading">My account</h3>
          <div className="addaccount">Add another account +</div>
        </div>
      </Row>
      <div className="mainselect">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
          >
            <p className="accnum">XYZ (••••••••••• 1234)</p>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16 }}
            lg={{ span: 16 }}
          >
            <div className="paytransper ">
              <img className="paytrans" src={paytransfer} alt="paytransfer" />
              <div className="paymore">
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  onOpenChange={handleOpenChange}
                  open={open}
                  trigger={["click"]}
                  className="more-dropdown"
                  placement="bottomRight"
                >
                  <Button className="more-dropdown-button">
                    More <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
          >
            {/* <p className="accnum">{businessname}</p> */}
            <div className="currbal">
              <p className="curbal">Current balance</p>
              <p className="amountcur">$0.00</p>
              <p className="activeacc">Active</p>
            </div>
          </Col>
          <Col
            className="vircard"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 16 }}
            lg={{ span: 10 }}
          >
            <p className="virtual">My cards | Virtual</p>
            <div className="acccountcardsec">
              <div className="acccountcardsecleft">
                <img
                  className="cameraimage"
                  src={accountcard}
                  alt="accountcard"
                />
              </div>
              <div className="acccountcardsecright">
                <p>{"•".repeat(12) + profileDetails?.cardNumber?.slice(-4)}</p>
                <p className="cursor" onClick={() => regenerate()}>
                  <span className="generate">Regenerate Card Details</span>
                  <span>
                    <img
                      className="refreshimg"
                      src={refresh}
                      alt="accountcard"
                    />
                  </span>
                </p>
              </div>
            </div>
            <div className="acccountcardsec">
              <p className="physical">Physical</p>
              <div className="acccountcardsecleft">
                <img
                  className="cameraimage"
                  src={accountcard}
                  alt="accountcard"
                />
              </div>
              <div className="acccountcardsecright">
                <p>(••••••••••• 3479)</p>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
          >
            {/* <div className="paytransper">
              <img className="paytrans" src={paytransfer} alt="paytransfer" />
              <div className="paymore">
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick,
                  }}
                  onOpenChange={handleOpenChange}
                  open={open}
                  trigger={["click"]}
                  className="more-dropdown"
                  placement="bottomRight"
                >
                  <Button className="more-dropdown-button">
                    More
                    {open ? (
                      <UpOutlined className="upp-arrow" />
                    ) : (
                      <DownOutlined className="down-arrow" />
                    )}
                  </Button>
                </Dropdown>
              </div>
            </div> */}
          </Col>
        </Row>
        <Row className="connectsec" onClick={() => navigate("/plaid")}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
          >
            <div className="connectconn">
              <p>
                Please connect your external bank account(s) to get access
                <br /> to our spending analytic tools
              </p>
            </div>
          </Col>
          <Col
            className="vectorright"
            xs={{ span: 24 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <img className="vectorhome" src={vector} alt="vector" />
          </Col>
        </Row>
        {/* </Row> */}

        <Row className="applycardsloan">
          <Col
            className="applycol"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <div className="applyloan">
              <p>Apply for a loan</p>
              <img className="applyimage" src={applayright} alt="applayright" />
            </div>
          </Col>
          <Col
            className="applycol"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <div className="applycard">
              <p>Apply for additional card</p>
              <img className="applyimage" src={applyleft} alt="applyleft" />
            </div>
          </Col>
        </Row>
      </div>
      <h3 className="eachCardHeading">Spending analytics</h3>

      <div className="chartImage">
        <img src={spending} alt="applayright" className="pie-chart-image" />
      </div>
    </div>
  );
};

export default MyAccount;
