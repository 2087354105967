import React, { useContext, useEffect, useState } from "react";
import RingLoader from "react-spinners/RingLoader";
import { Route, Routes } from "react-router-dom";
import { IApprouteProps } from "../Contracts/Contracts";
import Login from "../Components/Login/Login";
import NotFoundError from "../Components/ErrorPages/NotFoundError";
import { ApplicationContext } from "../Context/ApplicationContextProvider";
import StartPage from "../Components/StartPage/StartPage";
import WelcomePage from "../Components/WelcomePage/WelcomePage";
import GettingToKnow from "../Components/GettingToKnow/GettingToKnow";
import TermsPage from "../Components/TermsCondition/TermsCondition";
import PleaseConfirm from "../Components/BeneficialOwners/PleaseConfirm";
import OtherPleaseConfirm from "../Components/OtherBeneficialOwners/OtherBeneficial";
import PhoneValidation from "../Components/PhoneValidation/PhoneValidation";
import PhoneSMSValidation from "../Components/PhoneSMSValidation/PhoneSMSValidation";
import VerificationDetails from "../Components/MobileScreens/VerificationDetails/VerificationDetails";
import WaitScreen from "../Components/MobileScreens/WaitScreen/WaitScreen";
import SuccessfulDataSubmit from "../Components/MobileScreens/MobileSubmittedScreen/SuccessfulDataSubmit";
import BusinessInfo from "../Components/BusinessInfo/BusinessInfo";
import PersonalInfo from "../Components/PersonalInfo/PersonalInfo";
import UploadFrontDocument from "../Components/MobileScreens/UploadFrontDocument/UploadFrontDocument";
import UploadBackDocument from "../Components/MobileScreens/UploadBackDocument/UploadBackDocument";
import UploadMobileSelfie from "../Components/MobileScreens/UploadSelfie/UploadSelfie";
import { applicationMessages } from "../Components/Utility/ApplicationMessages";
import {
  getValueFromStorage,
  handleSessionStorage,
  setValueInStorage,
} from "../Components/Utility/Utility";
import GettingScan from "../Components/GettingScan/GettingScan";
import GettingConnected from "../Components/GettingConnected/GettingConnected";
import GettingThankyou from "../Components/GettingThankYou/GettingThankYou";
import UnderstandingBusiness from "../Components/UnderstandingBusiness/UnderstandBusiness";
import UnderstandingUpload from "../Components/UnderstandingUpload/UnderstandingUpload";
import LetsGetReady from "../Components/LetsGetsReady/LetsGetReady";
import BeneficialThanks from "../Components/BeneficialThanks/BeneficialThanks";
import FinalStep from "../Components/FinalStep/FinalStep";
import FinalConfirm from "../Components/FinalConfirm/FinalConfirm";
import FinalThankYou from "../Components/FinalThankYou/FinalThankYou";
import FinalSupport from "../Components/FinalSupport/FinalSupport";
import LocationDeniedErrorPage from "../Components/LocationDeniedErrorPage/LocationDeniedErrorPage";
import Forbidden from "../Components/ErrorPages/ForbiddenError";
import NotFound from "../Components/ErrorPages/NotFoundError";
import NetworkError from "../Components/ErrorPages/NetworkError";
import UnauthorizedError from "../Components/ErrorPages/UnauthorizedError";
import AddIndividual from "../Components/AddIndividual/AddIndividual";
import Dashboard from "../Components/Dashboard/Dashboard/Dashboard";
import StartPlaid from "../Components/Plaid/PlaidLinkToken";
import UnderstandingBusinessScan from "../Components/UnderstandingBusinessScan/UnderstandingBusinessScan";
import UnderstandingBusinessConnected from "../Components/UnderstandingBusinessConnected/UnderstandingBusinessConnected";
import UnderstandingBusinessThankyou from "../Components/UnderstandingBusinessThankyou/UnderstandingBusinessThankyou";
import WebLinkStatus from "../Components/MobileScreens/WebLinkStatus/WebLinkStatus";
import FrontDocument from "../Components/MobileScreens/FrontDocument/FrontDocument";
import BackDocument from "../Components/MobileScreens/BackDocument/BackDocument";
import MobileSelfie from "../Components/MobileScreens/Selfie/MobileSelfie";
import MobileUnderstandingBusiness from "../Components/MobileScreens/MobileUnderstandingBusiness/MobileUnderstandingBusiness";
import UnderstandingBusinessVerification from "../Components/MobileScreens/UnderstandingBusinessVerification/UnderstandingBusinessVerification";
import MobileSMSValidation from "../Components/MobileScreens/MobileSMSValidation/MobileSMSValidation";
import WebOtpAutoFillSample from "../Components/PhoneSMSValidation/WebOtpAutoFillSample";
import ResponsiveDesignSample from "../Components/PhoneValidation/ResponsiveDesignSample";
import IntroPage from "../Components/IntroPage/IntroPage";
import Layout from "../Components/Layout/Layout";
import SignUp from "../Components/SignUp";
import AccountSelection from "../Components/AccountSelection/AccountSelection";
import ProductSelection from "../Components/ProductSelection/ProductSelection";
import FinalStepNew from "../Components/FinalStep/FinalStepNew";
import LetsVerify from "../Components/MobileScreens/new/KYC/LetsVerify";
import MobileThankYou from "../Components/MobileScreens/new/ThankYou/ThankYou";
import EndThankYou from "../Components/EndThankYou/EndThankYou";
import AccountFunding from "../Components/AccountFunding/AccountFunding";
import KYCWait from "../Components/MobileScreens/new/KYC/KYCWait/KYCWait";

const AppRoute = (props: IApprouteProps) => {
  const { setAuth, auth } = useContext(ApplicationContext);

  // useEffect(() => {
  //   if (window.location.href.includes("/weblink")) {
  //     var queryString = window.location.search;
  //     // Parse the query string to an object
  //     var queryParams = new URLSearchParams(queryString);

  //     var weblinkId = queryParams.get("webLinkId");
  //     var productKey = queryParams.get("productKey");
  //     var type = queryParams.get("type");
  //     var fileIndex = queryParams.get("fileIndex");
  //     var countrycode = queryParams.get("countryCode");
  //     var isfinalstep = queryParams.get("isFinalStep");

  //     if (weblinkId && productKey) {
  //       handleSessionStorage([], "set", {
  //         GeoLocationAllowed: "true",
  //         UserLoggedIn: "true",
  //         ProductKey: productKey,
  //         WebLinkId: weblinkId,
  //         CountryCode: countrycode,
  //         IsFinalStepNavigation: isfinalstep,
  //       });
  //       if (fileIndex) {
  //         setValueInStorage("UploadFileIndex", fileIndex);
  //       }
  //       if (type) {
  //         setValueInStorage("FlowType", type);
  //       }

  //       setAuth({ ls: true, loadingUser: false });
  //     } else {
  //       setAuth({ ls: false, loadingUser: false });
  //     }
  //   } else if (
  //     window.location.href.includes("/welcomeback") ||
  //     window.location.href.includes("/letsstartphone")
  //   ) {
  //     if (window.location.href.includes("/welcomeback")) {
  //       handleSessionStorage([], "set", {
  //         WelcomeBack: "true",
  //         UserLoggedIn: "true",
  //       });
  //     } else {
  //       handleSessionStorage([], "set", {
  //         UserLoggedIn: "true",
  //       });
  //     }

  //     setAuth({ ls: true, loadingUser: false });
  //   } else {
  //     const userLoginDetails = getValueFromStorage("UserLoggedIn");
  //     if (userLoginDetails && userLoginDetails === "true") {
  //       setAuth({ ls: true, loadingUser: false });
  //     } else {
  //       setAuth({ loadingUser: false, ls: false });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div>
      {/* {auth.loadingUser ? (
        <RingLoader
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : ( */}
      <Layout>
        <Routes>
          <Route path="/" element={<IntroPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/letsgetready" element={<LetsGetReady />} />
          <Route path="/accountselection" element={<AccountSelection />} />
          <Route path="/productselection" element={<ProductSelection />} />

          <Route path="/gettingtoknow" element={<GettingToKnow />} />
          <Route path="/gettingscan" element={<GettingScan />} />
          <Route path="/gettingconnected" element={<GettingConnected />} />
          <Route path="/gettingthankyou" element={<GettingThankyou />} />
          <Route path="/personalinfo" element={<PersonalInfo />} />
          <Route path="/businessinfo" element={<BusinessInfo />} />
          <Route
            path="/understandingbusiness"
            element={<UnderstandingBusiness />}
          />
          <Route
            path="/understandingupload"
            element={<UnderstandingUpload />}
          />
          <Route
            path="/understandingbusinessscan"
            element={<UnderstandingBusinessScan />}
          />

          <Route
            path="/understandingbusinessconnected"
            element={<UnderstandingBusinessConnected />}
          />
          <Route
            path="/understandingbusinessthankyou"
            element={<UnderstandingBusinessThankyou />}
          />

          <Route path="/beneficial" element={<PleaseConfirm />} />
          <Route path="/otherbeneficial" element={<OtherPleaseConfirm />} />

          <Route path="/finalthankyou" element={<FinalThankYou />} />

          <Route path="/accountfunding" element={<AccountFunding />} />

          <Route path="/finalstep" element={<FinalStepNew />} />

          <Route path="/endthankyou" element={<EndThankYou />} />

          <Route
            path="/otherbeneficialnoowners"
            element={<OtherPleaseConfirm noowners={true} />}
          />
          <Route path="/beneficialthanks" element={<BeneficialThanks />} />
          <Route path="/addindividual" element={<AddIndividual />} />
          <Route path="/letsstartphone" element={<PhoneValidation />} />
          <Route path="/autofillsmssample" element={<WebOtpAutoFillSample />} />
          <Route path="/welcomeback" element={<ResponsiveDesignSample />} />
          <Route
            path="/responsivedesignsample"
            element={<ResponsiveDesignSample />}
          />
          <Route path="/letsstartsms" element={<PhoneSMSValidation />} />
          <Route path="/mobilevalidateotp" element={<MobileSMSValidation />} />
          <Route
            path="/verification-details"
            element={<VerificationDetails />}
          />
          <Route
            path="/kyb-verification-details"
            element={<MobileUnderstandingBusiness />}
          />

          <Route
            path="/kyb-verifying"
            element={<UnderstandingBusinessVerification />}
          />

          <Route path="/uploadfrontdoc" element={<UploadFrontDocument />} />
          <Route path="/uploadbackdoc" element={<UploadBackDocument />} />
          <Route path="/uploadmobileselfie" element={<UploadMobileSelfie />} />
          <Route path="/frontdoc" element={<FrontDocument />} />
          <Route path="/backdoc" element={<BackDocument />} />
          <Route path="/mobileselfie" element={<MobileSelfie />} />
          <Route path="/verifying" element={<WaitScreen />} />
          <Route path="/success" element={<SuccessfulDataSubmit />} />

          {/* KYC mobile */}
          <Route path="/kyc" element={<LetsVerify />} />
          <Route path="/kyc-wait" element={<KYCWait />} />
          <Route path="/mobilethankyou" element={<MobileThankYou />} />

          {/* <Route
                path="/mobileunderstandingupload"
                element={<UnderstandingUploadOld />}
              /> */}

          <Route path="/finalconfirm" element={<FinalConfirm />} />
          <Route path="/finalsupport" element={<FinalSupport />} />
          <Route
            path="/locationdeniederrorpage"
            element={<LocationDeniedErrorPage />}
          />
          <Route path="/plaid" element={<StartPlaid />} />

          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/weblink" element={<WebLinkStatus />} />
          <Route path="*" element={<NotFoundError />} />
        </Routes>
      </Layout>
      {/* )} */}
    </div>
  );
};

export default AppRoute;
