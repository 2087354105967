export interface IDynamicConfig {
  clientId: string;
  authority: string;
  clientRoot: string;
  clientScope: string;
  apiRoot: string;
  baseURL: string;
  countryCode : string;
}

export const initialConfig = {
  clientId: "",
  authority: "",
  clientRoot: "",
  clientScope: "",
  apiRoot: "",
  baseURL: "",
  countryCode : "+91"
};

export const DEFAULT_CONFIG: IDynamicConfig = {
  clientId: "",
  authority: "",
  clientRoot: "https:/loquat.com/",
  clientScope: "openid profile email",
  apiRoot: "https://apim-loquat-dev-001.azure-api.net/",
  baseURL: "https://apim-loquat-dev-001.azure-api.net",
  countryCode : ""
};

export const dynamicConfigUrl = "config.json";
