import React, { useEffect, useState } from "react";
// import Webcam from "react-webcam";
import { Camera } from "react-camera-pro";
import "./Webcam.scss";
import { ReactComponent as SwitchIcon } from "../../../../Assets/Images/new/ic_reload.svg";

interface Props {
  onPhotoClick: (imgSrc: string) => void;
  header?: React.ReactNode;
  isSelfie?: boolean;
}

const WebcamCapture: React.FC<Props> = ({ onPhotoClick, header, isSelfie }) => {
  const camera = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [isShowSwitchButton, setShowSwitchButton] = useState(false);

  useEffect(() => {
    //@ts-ignore
    setShowSwitchButton(camera.current.getNumberOfCameras() > 1);
  }, [camera]);

  const capture = React.useCallback(() => {
    // @ts-ignore
    const imageSrc = camera.current.takePhoto();
    onPhotoClick(imageSrc);
    setImgSrc(imageSrc);
  }, [camera, setImgSrc, onPhotoClick]);

  const handleSwitchCamera = () => {
    //@ts-ignore
    camera.current.switchCamera();
  };

  const aspectRatio = isSelfie ? 4 / 5 : 4 / 3;

  return (
    <div className="webcam-container">
      <div className="header">{header}</div>
      <div className={`camera-container ${isSelfie ? "selfie" : ""}`}>
        <Camera
          facingMode="environment"
          aspectRatio={aspectRatio}
          errorMessages={{}}
          ref={camera}
        />
      </div>
      <div className="btn-container">
        {isShowSwitchButton && (
          <SwitchIcon
            className="switch-icon"
            onClick={() => handleSwitchCamera()}
          />
        )}
        <div className="button-photo" onClick={capture}>
          <div>
            <div />
          </div>
        </div>
      </div>
      <svg style={{ height: "0" }}>
        <defs>
          <linearGradient id="gradient" x1="0" y1="00%" x2="0" y2="100%">
            <stop stop-color="black" offset="0" />
            <stop stop-color="white" offset="1" />
          </linearGradient>
          <mask
            id="masking"
            maskUnits="objectBoundingBox"
            maskContentUnits="objectBoundingBox"
          >
            <rect y="0.3" width="1.2" height=".7" fill="url(#gradient)" />
            <circle cx=".5" cy=".5" r=".35" fill="white" />
          </mask>
        </defs>
      </svg>
    </div>
  );
};

export default WebcamCapture;
