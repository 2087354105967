import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Row } from "antd";
import "./PhoneSMSValidation.scss";
import NoStepsHeader from "../Header/NoStepsHeader";
import StpesFooter from "../Footer/Footer";
import { INoStepsHeader } from "../../Contracts/NoStepheader";
import { useNavigate, useLocation } from "react-router-dom";
import image from "../../Assets/Images/LetsStart.png";
import { IValidateOtp } from "../../Contracts/Contracts";
import { ISendOtp } from "../../Contracts/Contracts";
import Loader from "../Common/Loader/Loader";
import welcomeImg from "../../Assets/Images/Welcomeback2.png";
import { DEFAULT_CONFIG } from "../../Configuration/Config";
import { applicationMessages } from "../Utility/ApplicationMessages";
import {
  showNotification,
  handleSessionStorage,
  setValueInStorage,
  removeValueFromStorage,
  getValueFromStorage,
  handleNumberKeyDown,
  getCurrentRoutePath,
} from "../Utility/Utility";
import ProfileExpiredPopup from "./ProfileExpiredPopup";

import { useDispatch } from "react-redux";
import { setUserApplicationStatus } from "../../../Redux/Reducers/UserApplicationStatus";

import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";

const { Content } = Layout;
const headerValues: INoStepsHeader = {
  title: "Lets get started",
  titleClass: "Noletsheadingh5",
  img: image,
};

const welcomebackValues: INoStepsHeader = {
  title: "Welcome back",
  titleClass: "Noletsheadingh5",
  img: welcomeImg,
};

interface OTPCredential {
  transport: string[];
  // Add any other properties that your OTP credential might have
}

const WebOtpAutoFillSample = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [codeNumber, setCodeNumber] = useState("");
  const [codeNumberError, setCodeNumberError] = useState("");
  const phoneNumber = location.state?.phoneNumber;
  const last4Digits = phoneNumber?.substr(phoneNumber.length - 4);
  const maskedPhoneNumber = `(XXX) XXX-${last4Digits}`;
  const [resendTime, setResendTime] = useState(60);
  const [loading, setloading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const { getLoquatClient } = useContext(ApplicationContext);
  const validCodeNumber = /^[0-9\b]+$/;
  let interval: NodeJS.Timeout | null = null;
  const welcomeback = getValueFromStorage("WelcomeBack");
  const [showUserExpiredPopup, setShowUserExpiredPopup] = useState(false);
  const [profileExpiredTime, setProfileExpiredTime] = useState("");
  const [showAccessMessage, setShowAccessMessage] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const dispatch = useDispatch();
  const date = new Date();
  const year = date.getFullYear();
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  useEffect(() => {
    const KeysToRemove = [
      "UserType",
      "AccessToken",
      "ProductKey",
      "UserId",
      "businessId",
      "businessName",
      "SelectedLegalType",
    ];
    handleSessionStorage(KeysToRemove, "remove");
  }, []);

  useEffect(() => {
    if (resendTime > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        setResendTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      if (interval !== null) {
        clearInterval(interval); // Clear interval when countdown is done
      }
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval); // Cleanup on unmount
      }
    };
  }, [resendTime]);

  useEffect(() => {
    console.log(window, navigator.credentials, "imppp");
    if ("OTPCredential" in window) {
      const ac = new AbortController();

      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        } as any)
        .then((otp: any) => {
          console.log(otp, "otppp");
          setCodeNumber(otp.code);
          ac.abort();
          // Additional logic if needed
        })
        .catch((err) => {
          console.log(err);
          ac.abort();
        });
    }
  }, []);

  const onResendClick = () => {
    if (resendTime === 0) {
      setResendLoading(true);
      setResendTime(60);
      var service = getLoquatClient().authenticationService();
      const countryCode = getValueFromStorage("CountryCode");
      const phone = phoneNumber?.replace(/[()\-\s]/g, "");
      var payLoad: ISendOtp = {
        MobileNumber: DEFAULT_CONFIG.countryCode.concat(phone),
        CountryCode: countryCode,
      };
      service
        .getPhoneAuthenticationOtp(payLoad)
        .then((response: any) => {
          showNotification(applicationMessages.otpSentMessage, "");
          setResendLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
          setResendLoading(false);
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        });
    }
  };

  const getUserApplicationStatus = () => {
    const service = getLoquatClient().profileService();
    // var payLoad: ISendOtp = { MobileNumber:DEFAULT_CONFIG.countryCode.concat(phone)};
    service
      .getUserApplicationStatus()
      .then((response: any) => {
        if (response) {
          dispatch(setUserApplicationStatus(response));
          const res = response?.successResult;
          if (res?.profile?.isProfileExpired === true) {
            setShowUserExpiredPopup(true);
            let profileExpiryLimit =
              res?.profile?.profileExpiryLimitInMinutes >= 60
                ? res?.profile?.profileExpiryLimitInMinutes / 60 + " hour"
                : res?.profile?.profileExpiryLimitInMinutes + " minute";
            setProfileExpiredTime(profileExpiryLimit);
            setloading(false);
          } else {
            const legalTypeId = response?.successResult?.userLegalTypeId;
            const selectedLegalTypeId = legalTypeId
              ? legalTypeId.toString()
              : null;
            setValueInStorage("SelectedLegalType", selectedLegalTypeId);
            if (
              res.business[0].userTypeId == null ||
              res.business[0].userTypeId === "0"
            ) {
              setValueInStorage("UserType", "new");

              if (res?.kyc.documentDetails.length > 0) {
                // Check if all documents have approvalStatus set to true
                const isAllKYCDocumentsUploaded =
                  res.kyc?.documentDetails?.every(
                    (doc: any) => doc.approvalStatus === true
                  );
                // Check if "Front" has approvalStatus as false
                const isFrontApprovalFalse = res.kyc?.documentDetails
                  .filter((doc: any) => doc.type === "Front")
                  ?.every(
                    (item: { type: string; approvalStatus: boolean }) =>
                      item.approvalStatus === false
                  );

                // Check if "Back" has approvalStatus as false
                const isBackApprovalFalse = res.kyc?.documentDetails
                  .filter((doc: any) => doc.type === "Back")
                  ?.every(
                    (item: { type: string; approvalStatus: boolean }) =>
                      item.approvalStatus === false
                  );

                const frontbackuploaddetails = res.kyc?.documentDetails?.filter(
                  (item: { type: string }) =>
                    item.type === "Front" || item.type === "Back"
                );
                // Check if "Front" and "Back" have approvalStatus === true
                const areFrontAndBackApproved = frontbackuploaddetails.every(
                  (document: any) => document.approvalStatus === true
                );
                // Check if "Selfie" has approvalStatus as false
                const isSelfieApprovalFalse = res.kyc?.documentDetails
                  .filter((doc: any) => doc.type === "Selfie")
                  ?.every(
                    (item: { type: string; approvalStatus: boolean }) =>
                      item.approvalStatus === false
                  );
                if (res.kyc?.isKYCCompleted === false) {
                  if (isAllKYCDocumentsUploaded) {
                    navigate("/personalinfo");
                    setValueInStorage("FlowType", "");
                  } else if (isFrontApprovalFalse) {
                    setValueInStorage("FlowType", "front");
                    navigate("/gettingtoknow");
                  } else if (isBackApprovalFalse && !isFrontApprovalFalse) {
                    setValueInStorage("FlowType", "back");
                    navigate("/gettingtoknow");
                  } else if (isSelfieApprovalFalse && areFrontAndBackApproved) {
                    setValueInStorage("FlowType", "selfie");
                    navigate("/gettingtoknow");
                  } else {
                    setValueInStorage("FlowType", "");
                    navigate("/gettingtoknow");
                  }
                } else {
                  navigate("/businessinfo");
                }
              } else if (res.userLegalTypeId) {
                setValueInStorage("FlowType", "");
                navigate("/gettingtoknow");
              } else {
                navigate("/terms");
              }
            } else if (
              res.business[0].userTypeId != null &&
              res.business[0].userTypeId === "1"
            ) {
              handleSessionStorage([], "set", {
                UserType: "primary",
                businessId: res.business[0].businessId,
                businessName: res.business[0].businessName,
              });
              if (
                res.business[0].isBusinessActivated === true &&
                res.business[0].isApplicationCompleted === true
              ) {
                navigate("/dashboard");
              } else if (res.business[0].isKYBCompleted === false) {
                navigate("/understandingbusiness");
              } else if (res.business[0].isBusinessActivated === true) {
                navigate("/finalstep");
              } else {
                if (res.business[0].isKYBCompleted === true) {
                  if (res.business[0].beneficiaryType) {
                    if (
                      res.business[0].beneficiaryType === 3 &&
                      res.business[0].isBeneficiaryUsersInvited === false
                    ) {
                      navigate("/otherbeneficial");
                    } else {
                      navigate("/beneficialthanks");
                    }
                  } else {
                    navigate("/beneficial");
                  }
                }
              }
            } else if (
              res.business[0].userTypeId != null &&
              res.business[0].userTypeId === "2"
            ) {
              setValueInStorage("UserType", "secondary");
              if (res?.kyc.documentDetails.length > 0) {
                // Check if all documents have approvalStatus set to true
                const isAllKYCDocumentsUploaded =
                  res.kyc?.documentDetails?.every(
                    (doc: any) => doc.approvalStatus === true
                  );
                // Check if "Front" has approvalStatus as false
                const isFrontApprovalFalse = res.kyc?.documentDetails
                  .filter((doc: any) => doc.type === "Front")
                  ?.every(
                    (item: { type: string; approvalStatus: boolean }) =>
                      item.approvalStatus === false
                  );

                // Check if "Back" has approvalStatus as false
                const isBackApprovalFalse = res.kyc?.documentDetails
                  .filter((doc: any) => doc.type === "Back")
                  ?.every(
                    (item: { type: string; approvalStatus: boolean }) =>
                      item.approvalStatus === false
                  );

                const frontbackuploaddetails = res.kyc?.documentDetails?.filter(
                  (item: { type: string }) =>
                    item.type === "Front" || item.type === "Back"
                );
                // Check if "Front" and "Back" have approvalStatus === true
                const areFrontAndBackApproved = frontbackuploaddetails.every(
                  (document: any) => document.approvalStatus === true
                );
                // Check if "Selfie" has approvalStatus as false
                const isSelfieApprovalFalse = res.kyc?.documentDetails
                  .filter((doc: any) => doc.type === "Selfie")
                  ?.every(
                    (item: { type: string; approvalStatus: boolean }) =>
                      item.approvalStatus === false
                  );
                if (res.kyc?.isKYCCompleted === false) {
                  if (isAllKYCDocumentsUploaded) {
                    navigate("/personalinfo");
                    setValueInStorage("FlowType", "");
                  } else if (isFrontApprovalFalse) {
                    setValueInStorage("FlowType", "front");
                    navigate("/gettingtoknow");
                  } else if (isBackApprovalFalse && !isFrontApprovalFalse) {
                    setValueInStorage("FlowType", "back");
                    navigate("/gettingtoknow");
                  } else if (isSelfieApprovalFalse && areFrontAndBackApproved) {
                    setValueInStorage("FlowType", "selfie");
                    navigate("/gettingtoknow");
                  } else {
                    setValueInStorage("FlowType", "");
                    navigate("/gettingtoknow");
                  }
                } else {
                  navigate("/beneficialthanks");
                }
              } else {
                navigate("/terms");
              }
            } else {
              setValueInStorage("UserType", "new");
              navigate("/terms");
            }
            setloading(false);
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
        setloading(false);
        if (error.response.status === 401) {
          setShowAccessMessage(true);
          setShowUserExpiredPopup(true);
        } else {
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
          // navigate("/letsgetready");
        }
      });
  };

  const OnClick = () => {
    if (
      codeNumber &&
      codeNumber.length >= 4 &&
      validCodeNumber.test(codeNumber) &&
      codeNumber.length <= 6
    ) {
      setloading(true);
      var service = getLoquatClient().authenticationService();
      const phone = phoneNumber;

      var phoneOtpDetails: IValidateOtp = {
        MobileNumber: DEFAULT_CONFIG.countryCode.concat(phone),
        OTP: codeNumber,
      };

      service
        .postPhoneAuthenticationOtp(phoneOtpDetails)
        .then((response: any) => {
          if (response?.status === 400) {
            // setNotificationMessage(applicationMessages.defaultErrorMessage);
            // setShowNotificationPopup(true);
            showNotification("", applicationMessages.defaultErrorMessage, true);
          } else {
            handleSessionStorage([], "set", {
              AccessToken: response.successResult.access_token,
              ProductKey: response.successResult.product_key,
              UserId: response.successResult.user_id,
            });
            getUserApplicationStatus();
          }

          // setloading(false);
          // navigate("/letsgetready");
        })
        .catch((error: any) => {
          setloading(false);
          if (
            error?.response?.data?.failureResult.toLowerCase() ===
            "invalid code"
          ) {
            setNotificationMessage(
              applicationMessages.smsCode.smsInvalidCodeDescription
            );
            setShowNotificationPopup(true);
          } else {
            showNotification("", applicationMessages.pleaseTryAgainMsg, true);
          }
          // navigate("/letsgetready");
        });
    } else {
      setCodeNumberError(
        codeNumber.length === 0
          ? "Please enter the 6 digit one time passcode"
          : codeNumber.length !== 6
          ? "Please enter the 6 digit one time passcode"
          : !validCodeNumber.test(codeNumber)
          ? "Please enter the 6 digit one time passcode"
          : ""
      );
    }
  };

  const handleSMSCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCodeNumber = e.target.value;
    if (
      newCodeNumber.length < 1 &&
      (e.nativeEvent as InputEvent).inputType === "deleteContentBackward"
    ) {
      setCodeNumber("");
      return;
    }
    if (!/^\d+$/.test(newCodeNumber)) {
      return;
    }
    setCodeNumber(newCodeNumber);

    if (newCodeNumber.length === 0) {
      setCodeNumberError("Please enter the 6 digit one time passcode");
    } else if (!validCodeNumber.test(newCodeNumber)) {
      setCodeNumberError("Please enter the 6 digit one time passcode");
    } else if (newCodeNumber.length !== 6) {
      setCodeNumberError("Please enter the 6 digit one time passcode");
    } else {
      setCodeNumberError("");
    }
  };

  return (
    <Layout className="phone-sms-validation-layout">
      {welcomeback && welcomeback === "true" ? (
        <NoStepsHeader {...welcomebackValues} />
      ) : (
        <NoStepsHeader {...headerValues} />
      )}

      <Content className="confirm-content">
        <div className="phone_val_details">
          <Row>
            <p className="text_poppins alignText phone_text">
              Please, enter the verification code sent to {maskedPhoneNumber}
            </p>
            <div
              className={`phone_val_text_main  ${
                codeNumberError ? "input-error" : ""
              }`}
            >
              <div className="phone_val_center application-inputBox">
                <div className="phone_val_left_align">
                  <input
                    // type="number"
                    className="inputfield phone_sms_code text_poppins no-spinner"
                    placeholder="Enter SMS Code"
                    autoComplete="one-time-code"
                    onChange={handleSMSCodeChange}
                    value={codeNumber}
                    maxLength={6}
                    required
                  />
                  <span className="text_poppins placeholder">
                    Enter SMS Code
                  </span>
                </div>
              </div>
            </div>
            {codeNumberError && (
              <p className="error-message">{codeNumberError}</p>
            )}
          </Row>
          <div
            className={`phone_sms_resend clickable-icon ${
              resendTime > 0 ? "disabled" : ""
            }`}
            onClick={onResendClick}
          >
            <span
              className={`glyphicon glyphicon-repeat right ${
                resendTime > 0 ? "disabled-icon" : ""
              }`}
            ></span>
            <span
              className={`underline text_poppins padding_left ${
                resendTime > 0 ? "disabled-text" : ""
              }`}
            >
              {resendTime > 0
                ? `Resend the SMS Code (available in ${resendTime} sec)`
                : "Resend the SMS Code"}
            </span>
            {resendLoading && (
              <Loader
                checkLoading={resendLoading}
                color={"#123abc"}
                size={7}
                inline={true}
              />
            )}
          </div>
        </div>
        {/* privacy policy content */}
        <div className="button-container newprivacypolicy">
          <div className="button-group">
            <span className="float-left left-footer-text">
              Loquat Inc. © {year}
            </span>
            {/* <span className="margin-right"> Privacy Policy</span> */}
            <span className="text-footerright"> Privacy Policy</span>
          </div>
        </div>
      </Content>
      <StpesFooter
        nextPath={CurrentRoutePath?.Next}
        onClick={OnClick}
        loading={loading}
      />
      <ProfileExpiredPopup
        showUserExpiredPopup={showUserExpiredPopup}
        setShowUserExpiredPopup={setShowUserExpiredPopup}
        phoneNumber={phoneNumber}
        profileExpireTime={profileExpiredTime}
        showAccessMessage={showAccessMessage}
        setShowAccessMessage={setShowAccessMessage}
      />
      <NotificationPopup
        showPopup={showNotificationPopup}
        setShowPopup={setShowNotificationPopup}
        headerText={notificationMessage}
        buttonCount={1}
      />
    </Layout>
  );
};

export default WebOtpAutoFillSample;
