import React from "react";
import { Layout, List, Avatar, Button } from "antd";
import { useNavigate } from "react-router-dom";
import tickIcon from "../../../Assets/Images/tick.png";
import data from "./VerificationDetails.json";

import "./VerificationDetails.scss";
import MobileFooter from "../MobileFooter/MobileFooter";
import MobileHeader from "../MobileHeader/MobileHeader";
import { getValueFromStorage } from "../../Utility/Utility";

const { Content } = Layout;

const VerificationDetails = () => {
  const navigate = useNavigate();
  const type = getValueFromStorage("FlowType");
  console.log(type, "type");

  const handleRedirection = () => {
    if (type?.toLowerCase() === "front") {
      navigate("/uploadfrontdoc");
    } else if (type?.toLowerCase() === "selfie") {
      navigate("/uploadmobileselfie");
    } else if (type?.toLowerCase() === "back") {
      navigate("/uploadbackdoc");
    } else {
      navigate("/uploadfrontdoc");
    }
  };

  return (
    <Layout className="mobile-layout">
      <MobileHeader />
      <Content className="mobile-content">
        <div className="mobile-content-container">
          <div className="mobile-content-heading">
            Let’s get you <br />
            verified
          </div>
          <div className="mobile-subHeading">Before you start, please:</div>
          <List
            itemLayout="horizontal"
            dataSource={data.points}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar src={tickIcon} className="tick-icon" />}
                  title={<div className="content-text">{item.title}</div>}
                />
              </List.Item>
            )}
          />
        </div>
      </Content>
      <MobileFooter buttonName="Let’s go!" onClick={handleRedirection} />
    </Layout>
  );
};

export default VerificationDetails;
