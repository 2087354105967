import { IGeoLocProps } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import {
    ILoquatServiceClient,
} from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class GeolocationService
  extends ServiceBase
  implements IGeolocationService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async postAllowGeoLocation(body: IGeoLocProps) {
    let url = ApiEndpoints.Geolocation.setGeolocation();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IGeolocationService {
    postAllowGeoLocation(body: any): Promise<any>;
}
