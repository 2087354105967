import React, { useEffect, useState } from "react";
import {
  Image,
  Modal,
  Form,
  Space,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Button,
} from "antd";
import call from "../../Assets/Images/call.png";
import supportimage from "../../Assets/Images/headerImages/finalsupport.png";
import "./FinalSupportPopup.scss";
import { CommonValues } from "../Common/Base";
import edit from "../../Assets/Images/EditIcon.svg";
import {
  getScheduleCallFormattedDate,
  getScheduleCallFormattedTime,
  getValueFromStorage,
  setValueInStorage,
  disabledScheduleCallDateRange,
  getDisabledHours,
  disabledMinutes,
  removeValueFromStorage,
} from "../Utility/Utility";

import dayjs from "dayjs";

type FinalPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
};
const FinalSupportPopup = (FinalSupportProps: FinalPopupProps) => {
  const [showCallPopup, setShowCallPopup] = useState(false);
  const [formValues, setFormValues] = useState<{
    date: any;
    time: any;
  }>({
    date: null,
    time: null,
  });
  const [callSchedule, setCallSchedule] = useState<{
    date: any;
    time: any;
  }>({
    date: null,
    time: null,
  });
  const dateFormat = CommonValues.dateFormat;
  const [form] = Form.useForm();

  useEffect(() => {
    const isScheduled = getValueFromStorage("ScheduleCall");
    const isScheduledValue = isScheduled ? JSON.parse(isScheduled) : formValues;
    if (isScheduled) {
      setFormValues({
        date: dayjs(isScheduledValue?.date),
        time: dayjs(isScheduledValue.time),
      });
      setCallSchedule(isScheduledValue);
    }
  }, []);

  const onDateChange = (fieldName: string, value: any) => {
    let updatedValues;

    updatedValues = {
      ...formValues,
      [fieldName]: value,
    };
    form.setFieldsValue(updatedValues);
    setFormValues(updatedValues);
  };
  const handleCallmeClick = () => {
    setShowCallPopup(true);
  };
  const handleOk = () => {
    FinalSupportProps.setShowPopup(false);
  };

  const handleCloseCallPopup = () => {
    setShowCallPopup(false);
  };

  const handleCancel = () => {
    FinalSupportProps.setShowPopup(false);
  };

  const handleDoneClick = () => {
    const dateValues = form.getFieldsValue();
    setCallSchedule(dateValues);
    setShowCallPopup(false);
    const callScheduleValue = JSON.stringify(dateValues);
    setValueInStorage("ScheduleCall", callScheduleValue);
  };

  const handleCancelCall = () => {
    const dateValues = {
      date: null,
      time: null,
    };
    setFormValues(dateValues);
    setCallSchedule(dateValues);
    setShowCallPopup(false);
    removeValueFromStorage("ScheduleCall");
  };
  const formattedDate = callSchedule?.date
    ? getScheduleCallFormattedDate(callSchedule?.date)
    : null;
  const formattedTime = callSchedule?.time
    ? getScheduleCallFormattedTime(callSchedule?.time)
    : null;

  return (
    <>
      <Modal
        centered
        open={FinalSupportProps.showPopup}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1100}
        maskClosable={false}
        footer={[]}
      >
        <div className="support-container">
          <div className="support-group">
            <Image
              src={supportimage}
              alt="front"
              loading="lazy"
              className="imageAlignsupport"
              preview={false}
            />

            <p className="support_para">Support 24/7</p>
            <div className="callsupport" onClick={handleCallmeClick}>
              <span className="callmetext">
                {formattedDate && formattedTime
                  ? `${formattedDate}  ${formattedTime}`
                  : "Call me"}
              </span>
              <span className="callmeimg">
                {formattedDate && formattedTime ? (
                  <img className="supportcornerimg" src={edit} alt="editicon" />
                ) : (
                  <img className="supportcornerimg" src={call} alt="callicon" />
                )}
              </span>
            </div>
            <div className="call_supportus">
              <span className="callus">Call us</span>
              <span className="call_num">(234) 567-8900</span>
            </div>
            <div className="call_supportus">
              <span className="callus">Write us</span>
              <span className="call_num">help@loquatinc.io</span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={showCallPopup}
        onCancel={handleCloseCallPopup}
        width={800}
        maskClosable={false}
        className="callschedulepopup"
        footer={[
          <Row className="popBtns" gutter={[16, 16]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="center-aligned"
            >
              <Button className="cancel-button" onClick={handleCancelCall}>
                Cancel
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="center-aligned"
            >
              <Button onClick={handleDoneClick} className="done-button">
                Schedule
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form
          form={form}
          onFinish={handleDoneClick}
          className="personalinfo-form "
          initialValues={formValues}
        >
          <Row>
            <Col md={12} xs={24}>
              <Form.Item
                name="date"
                rules={[{ required: true, message: "Date is required" }]}
              >
                <div className={`info_borderText application-inputBox `}>
                  <span className="info_span">Date</span>
                  <Space
                    direction="vertical"
                    className="fullWidth info_inputTextClass"
                  >
                    <DatePicker
                      format={dateFormat}
                      disabledDate={disabledScheduleCallDateRange}
                      onChange={(value) => onDateChange("date", value)}
                      bordered={false}
                      value={formValues.date}
                    />
                  </Space>
                </div>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name="time"
                rules={[{ required: true, message: "Time is required" }]}
              >
                <div className={`info_borderText application-inputBox `}>
                  <span className="info_span">Time</span>
                  <Space
                    direction="vertical"
                    className="fullWidth info_inputTextClass"
                  >
                    <TimePicker
                      disabledHours={() => getDisabledHours(formValues.date)}
                      disabledMinutes={(value) => disabledMinutes(value)}
                      onChange={(value) => onDateChange("time", value)}
                      bordered={false}
                      value={formValues.time && formValues.time}
                      use12Hours={true}
                      format="h:mm A"
                      onOpenChange={(open) => {
                        if (open && !formValues.time) {
                          // Set the current time when the TimePicker is opened
                          onDateChange("time", dayjs());
                        }
                      }}
                    />
                  </Space>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default FinalSupportPopup;
