import React, { useState } from "react";
import gobackarrow from "../../../Assets/Images/DashboardImages/gobackarrow.png";
import { SearchOutlined } from "@ant-design/icons";
import searchIcon from "../../../Assets/Images/DashboardImages/SearchIcon.svg";
import { Image, Row, Col, Input, Select, Divider } from "antd";
import calendarIcon from "../../../Assets/Images/DashboardImages/CalendarIcon.svg";
import "./Transactions.scss";
import transactions from "./Transactions.json";

type TransactionProps = {
  showTransactions: boolean;
  setShowTransactions: (value: boolean) => void;
};

const Transactions = (props: TransactionProps) => {
  const handleGoBack = () => {
    props.setShowTransactions(false);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className="transactions-container">
      <div onClick={handleGoBack} className="go-back-button">
        <div>
          <Image
            src={gobackarrow}
            alt="front"
            loading="lazy"
            preview={false}
            className="go-back-arrow-img"
          />
        </div>
        <div className="go-back-text">Go back</div>
      </div>
      <Row className="transaction-header-container">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          className="transactions-heading"
        >
          Your transactions
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}
          className="cards-details-heading"
        >
          XYZ (••••••••••• 1234)
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className="dropdown-cols"
        >
          <Select
            placeholder="Period"
            className="select-dropdown"
            onChange={handleChange}
            options={[]}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className="dropdown-cols"
        >
          <Select
            placeholder="Amount"
            className="select-dropdown"
            onChange={handleChange}
            options={[]}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className="dropdown-cols"
        >
          <Select
            placeholder="Type"
            className="select-dropdown"
            onChange={handleChange}
            options={[]}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className="dropdown-cols align-right"
        >
          <div className="search-box">
            <div className="search-image-container">
              <img src={searchIcon} alt="search" className="searchIcon" />
            </div>
            <Input className="searchInputBox" placeholder="Search" />
          </div>
        </Col>
      </Row>
      <Row className="transactions-list-container">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}
        >
          {transactions?.transactionData.map((data: any) => (
            <div className="card-header-data">
              <div className="date-data-header">
                <div className="calendar-text-container">
                  <img
                    src={calendarIcon}
                    alt="search"
                    className="calendarIcon"
                  />
                  <div className="date-text">{data.transactionDate}</div>
                </div>
                <div className="colored-text">{data.transactionAmount}</div>
              </div>
              <Divider />
              {data?.detailedTransactions?.map((item: any) => (
                <div className="date-data-header">
                  <div className="card-text-container">
                    <div className="card-text">{item.carddetails}</div>
                    <div className="date-details-text">{item.date}</div>
                  </div>
                  <div className="normal-rate-text">{item.amount}</div>
                </div>
              ))}
            </div>
          ))}
        </Col>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 8 }}
        ></Col>
      </Row>
    </div>
  );
};

export default Transactions;
