import React, { useEffect, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import "./ListItems.scss";

interface ListItemsProps {
  selectedOption: {
    legalTypeId: number;
    legalTypeName: string;
    documentType: {
      documentTypeId: number;
      documentTypeName: string;
    }[];
  };
}

const ListItems: React.FC<ListItemsProps> = ({ selectedOption }) => {
  const [optionsItem, setOptionItem] = useState<Array<{ item?: string }>>([]);

  useEffect(() => {
    const transformedOptions = selectedOption?.documentType?.map((item) => ({
      item: item?.documentTypeName,
    }));
    setOptionItem(transformedOptions);
  }, [selectedOption]);

  return (
    <div className="listrightitems">
      {optionsItem?.map((item, index) => (
        <p key={index}>
          <span className="tickimg">
            <CheckOutlined />
          </span>
          <span className="itemcontent">{item.item}</span>
        </p>
      ))}
    </div>
  );
};

export default ListItems;
