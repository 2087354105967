import { Layout, Row, Col, Button, Typography, Select, Input, Flex, Form } from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import styles from './CreateUser.module.scss';
import logo from "../../../Assets/Images/logo.svg";




const { Footer, Content } = Layout;

const CreateUser = ({ handleNextClick, handleBackClick }: any) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);




  return (
    <>
      <Content className={styles.container}>
        <Typography.Title level={1} className={styles.title}>Create Your Digital User ID</Typography.Title>
        <Typography.Text className={styles.terms_text}>
          This username and password combination enables you to log in to online & mobile banking.
        
        </Typography.Text>

        <Form className={styles.formContainer}>

          <div className={styles.inputWrapper}>

            <span className="text_poppins placeholder">Create User ID</span>
            <input
              type="email"
              className={styles.input}
            />
          </div>
          <div className={styles.inputWrapper}>

            <span className="text_poppins placeholder">Create password</span>
            <input
              type="password"
              className={styles.input}
            />
          </div>
          <div className={styles.inputWrapper}>

            <span className="text_poppins placeholder">Confirm password</span>
            <input
              type="password"
              className={styles.input}
            />
          </div>

      
          
          <Flex gap='10px' className={styles.btnGroup}>
            <Button onClick={handleBackClick}>CANCEL</Button>
            <Button className={styles.btnNext} onClick={handleNextClick}>NEXT</Button>
          </Flex>

          <Typography.Text className={styles.terms_text}>
            Need assistance? <span className={styles.terms_resend}>Contact Us</span>
          </Typography.Text>
        </Form>


      </Content>

      <Footer className={styles.footer}>


        <img src={logo} alt="logo" className={styles.logo} />

      </Footer>

    </>
  );
};

export default CreateUser;
