import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class FinalStepService extends ServiceBase implements IFinalStepService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getUserDocument(documentTypeId: any) {
    let url = ApiEndpoints.FinalStep.getUserDocument(documentTypeId);
    const response: any = await this.doGet(url);
    return response;
  }

  async getBusinessesInfo(
  ) {
    let url = ApiEndpoints.FinalStep.getBusinessesInfo();
    const response: any = await this.doGet(url);
    return response;
  }

  async getKYBDocumentsInfo(documentTypeId: any,legalTypeId:any
    ) {
      let url = ApiEndpoints.FinalStep.getKYBDocumentsInfo(documentTypeId,legalTypeId);
      const response: any = await this.doGet(url);
      return response;
    }
}

export interface IFinalStepService {
    getUserDocument(
    body: any,
  ): Promise<any>;
  getBusinessesInfo(): Promise<any>;
  getKYBDocumentsInfo(documentTypeId: any,legalTypeId:any): Promise<any>;
}
