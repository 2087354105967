import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import "./UnderstandingBusinessScan.scss";
import { Button, Layout, Row, Col } from "antd";

import qrCode from "../../Assets/Images/qrCode.svg";
import { useNavigate, useLocation } from "react-router-dom";

import FooterMain from "../Footer/FooterMain";


const { Content } = Layout;

const UnderstandingBusinessScan: React.FC = () => {
  const navigate = useNavigate();


  const handlClick = (e: SyntheticEvent) => {

    navigate("/understandingbusinessconnected");
  };
  return (
    <>

      <Layout className="UnderstandingBusinessScan-container layout" onClick={handlClick}>
        <Content className="getting-to-know-content">
          <h1 className="getting-to-know-title">Understanding your business</h1>
          <div className="getting-to-button-container">

            <h3 className="Identification_heading">
            Please scan the QR Code to start the process
            </h3>
            <div className="qr-code">
              <img src={qrCode} alt="qr-code" />
            </div>

          </div>
        </Content>

      </Layout>
      <FooterMain isConfirmBtnDisabled={true} currentStep={5} totalSteps={8} isProgressBarEnabled={true} />
    </>
  );
};

export default UnderstandingBusinessScan;
