import React, { useContext, useState, useEffect } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Row, Button } from "antd";
import "./PhoneValidation.scss";
import arrow from "../../Assets/Images/ArrowPhone.png";
import NoStepsHeader from "../Header/NoStepsHeader";
import { INoStepsHeader } from "../../Contracts/NoStepheader";
import { useNavigate, useLocation } from "react-router-dom";
import image from "../../Assets/Images/LetsStart.png";
import { ISendOtp } from "../../Contracts/Contracts";
import Loader from "../Common/Loader/Loader";
import { DEFAULT_CONFIG } from "../../Configuration/Config";
import { applicationMessages } from "../Utility/ApplicationMessages";
import {
  getTimerPromise,
  showNotification,
  handleSessionStorage,
  setValueInStorage,
  getValueFromStorage,
  handleLocationPermission,
  getCurrentRoutePath,
} from "../Utility/Utility";
import welcomeImg from "../../Assets/Images/Welcomeback2.png";

import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";

const { Footer, Content } = Layout;
const headerValues: INoStepsHeader = {
  title: "Lets get started",
  titleClass: "Noletsheadingh5",
  img: image,
};

const welcomebackValues: INoStepsHeader = {
  title: "Welcome back",
  titleClass: "Noletsheadingh5",
  img: welcomeImg,
};
const PhoneValidation = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const { getLoquatClient } = useContext(ApplicationContext);
  const [locationPermission, setLocationPermission] = useState("default");
  const [checkGeoLocation, setCheckGeoLocation] = useState<boolean>(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const geolocationAllowed = getValueFromStorage("GeoLocationAllowed");
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [onClickNavigation, setOnClickNavigation] = useState(false);
  const [popupContentCSSChange, setPopupContentCSSChange] = useState(false);
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  const apicall = (latitude: number, longitude: number) => {
    var service = getLoquatClient().geolocationService();
    const payload = {
      latitude: latitude,
      longitude: longitude,
    };
    service
      .postAllowGeoLocation(payload)
      .then((response) => {
        if (response?.status === 400) {
          setIsApiCall(false);
          setNotificationMessage(
            applicationMessages.geoLocation.geoLocationError
          );
          setShowNotificationPopup(true);
        } else {
          setValueInStorage("CountryCode", response.successResult?.countryCode);
          // showNotification(applicationMessages.geoLocation.geoLocationSuccess);
          setNotificationMessage(
            applicationMessages.geoLocation.geoLocationSuccess
          );
          setOnClickNavigation(false);
          setShowNotificationPopup(true);
        }
      })
      .catch((error) => {
        setIsApiCall(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!isApiCall) {
      handleLocationPermission((latitude: any, longitude: any, error: any) => {
        if (error) {
          if (error === "notSelected") {
            setIsApiCall(false);
          } else {
            setLocationPermission("denied");
            // apicall(0, 0);
            setCheckGeoLocation(false);
            setIsApiCall(false);
          }
        } else {
          setLocationPermission("allowed");
          setCheckGeoLocation(true);
          setValueInStorage("GeoLocationAllowed", "true");
          apicall(latitude, longitude);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiCall]);

  useEffect(() => {
    const keysToRemove = [
      "PhoneNumber",
      "UserType",
      "AccessToken",
      "ProductKey",
      "UserId",
      "businessId",
      "businessName",
      "ScheduleCall",
      "FlowType",
      "SelectedLegalType",
    ];
    handleSessionStorage(keysToRemove, "remove");
    if (location.pathname === "/welcomeback") {
      setValueInStorage("WelcomeBack", "true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    const geolocation = getValueFromStorage("GeoLocationAllowed");
    if (geolocation === null) {
      setNotificationMessage(applicationMessages.geoLocation.geolocationSelect);
      setOnClickNavigation(false);
      setPopupContentCSSChange(false);
      setShowNotificationPopup(true);
      return;
    }
    if (phoneNumber.length > 0 && phoneNumber.length === 14) {
      setLoading(true);
      var service = getLoquatClient().authenticationService();
      const phone = phoneNumber.replace(/[()\-\s]/g, "");
      const countryCode = getValueFromStorage("CountryCode");
      var payLoad: ISendOtp = {
        MobileNumber: DEFAULT_CONFIG.countryCode.concat(phone),
        CountryCode: countryCode && countryCode,
        // CountryCode: "In",
      };
      setValueInStorage("PhoneNumber", phone);
      service
        .getPhoneAuthenticationOtp(payLoad)
        .then((response: any) => {
          setLoading(false);
          if (response.status === 200) {
            navigate(CurrentRoutePath?.Next, {
              state: { phoneNumber: phone },
            });
          } else {
            if (
              response.status === 400 &&
              response.failureResult.code === 21614
            ) {
              setNotificationMessage("Invalid phone number, please try again");
              setOnClickNavigation(false);
              setShowNotificationPopup(true);
            } else {
              setNotificationMessage(
                "Unable to send SMS message, please try again"
              );
              setOnClickNavigation(false);
              setShowNotificationPopup(true);
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
          setNotificationMessage(
            "Unable to send SMS message, please try again"
          );
          setOnClickNavigation(false);
          setShowNotificationPopup(true);
        });
    } else {
      setPhoneNumberError(
        phoneNumber.length !== 14
          ? phoneNumber.length === 0
            ? "Please enter a valid phone number"
            : "Please enter a valid phone number "
          : ""
      );
    }
  };
  const onGoBackClick = () => {
    navigate(-1);
  };

  const handleOkClick = () => {
    const phone = phoneNumber.replace(/[()\-\s]/g, "");
    navigate(CurrentRoutePath?.Next, {
      state: { phoneNumber: phone },
    });
  };

  const handlePhoneNumberChange = (e: { target: { value: any } }) => {
    // const inputPhoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);

    setPhoneNumber(formattedPhoneNumber);
    if (formattedPhoneNumber.length !== 14) {
      setPhoneNumberError("Please enter a valid phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "e") {
      event.preventDefault(); // Prevent the "e" key from being input
    }
  };

  return (
    <Layout className="phone-validation-layout">
      {location.pathname === "/letsstartphone" ? (
        <NoStepsHeader
          {...headerValues}
          phoneNumberScreen={true}
          phoneNumber={phoneNumber}
        />
      ) : (
        <NoStepsHeader
          {...welcomebackValues}
          phoneNumberScreen={true}
          phoneNumber={phoneNumber}
        />
      )}

      <Content className="confirm-content">
        <div className="phone_val_details">
          <Row>
            {location.pathname === "/letsstartphone" && (
              <p className="text_poppins alignText phone_text">
                Once you start, we will save the information you provided for 24
                hours, so you can jump in where you left off to finalize the
                application.
              </p>
            )}

            <div
              className={`phone_val_text_main  ${
                phoneNumberError ? "input-error" : ""
              }`}
            >
              <span className="text_poppins phone_val_sms">
                {!showPlaceholder ? "My Phone number is" : ""}
              </span>
              <div className="phone_val_center leftMargin">
                <div className="phone_val_left_align">
                  <input
                    // type="number"
                    className="inputfield text_poppins no-spinner"
                    placeholder={showPlaceholder ? "My Phone number is" : ""}
                    onFocus={() => setShowPlaceholder(false)}
                    onBlur={() =>
                      !phoneNumber.length && setShowPlaceholder(true)
                    }
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    onKeyDown={handleKeyDown}
                    maxLength={14}
                  />
                </div>

                <span
                  className={`cursor loaderPhone ${
                    loading ? "disabled" : "rightAlign"
                  }`}
                >
                  {loading ? (
                    <Loader
                      checkLoading={loading}
                      color={"#123abc"}
                      size={10}
                      inline={true}
                    />
                  ) : (
                    <img
                      src={arrow}
                      alt="Logo"
                      className="header-logo img-responsive phone_val_arrow"
                      onClick={onClick}
                    />
                  )}
                </span>
              </div>
            </div>
            {phoneNumberError && (
              <p className="error-message">{phoneNumberError}</p>
            )}
          </Row>
          <div>
            <div className="user-content-header">
              Loquat, Inc. Notifications Consent
            </div>
            <p className="user-content-description">
              By providing your phone number, you consent to receive messages
              and calls from Loquat, Inc. related to our services. Message and
              data rates may apply. You can opt out anytime by replying "STOP"
              to our messages. We value your privacy and won't share your number
              with third parties without your consent. Thank you for choosing
              Loquat, Inc.
            </p>
          </div>
        </div>
      </Content>

      <Footer className="no-padding">
        <div className="transparent-footer">
          <div className="fixed_right">
            <Button
              className=" nextbtn next-button"
              onClick={onClick}
              disabled={loading}
            >
              Next
            </Button>
          </div>
        </div>
      </Footer>
      <NotificationPopup
        showPopup={showNotificationPopup}
        setShowPopup={setShowNotificationPopup}
        headerText={notificationMessage}
        descriptionText={descriptionText}
        buttonCount={1}
        onClickNavigation={onClickNavigation}
        handleClick={handleOkClick}
        popupContentCSSChange={popupContentCSSChange}
      />
    </Layout>
  );
};

export default PhoneValidation;
