import React, { useEffect, useContext, useState } from "react";
import { Layout } from "antd";
import "./Dashboard.scss";
import TabView from "../Tabs/Tabs";
import DashboardHeader from "../Header/Header";
import PrivacyPolicy from "../../PrivacyPolicy/PrivacyPolicy";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { getValueFromStorage, setValueInStorage } from "../../Utility/Utility";
import {
  useNavigate,
  useLocation,
  UNSAFE_NavigationContext,
} from "react-router-dom";
const Dashboard = () => {
  const { getLoquatClient } = useContext(ApplicationContext);
  const [userProfileDetails, setUserProfileDetails] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const navigator = useContext(UNSAFE_NavigationContext).navigator;
  const locationPathname = window.location.pathname;
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navigate(location.pathname);
    const service = getLoquatClient().profileService();
    const userId = getValueFromStorage("UserId");
    service
      .getPersonalInfo(userId)
      .then((response: any) => {
        console.log(response);
        if (response) {
          setUserProfileDetails({ ...response?.successResult });
          //setValueInStorage("userName", response?.successResult?.firstName);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navigate("/dashboard");
  }, [locationPathname]);

  return (
    <Layout className="dashboard-layout">
      <DashboardHeader userProfileDetails={userProfileDetails} />
      <TabView userDetails={userProfileDetails} />
      <div className="dashboardFooter">
        <PrivacyPolicy />
      </div>
    </Layout>
  );
};

export default Dashboard;
