
import { Layout as AntLayout, Button } from "antd";

import Header from "../Header/Headerr";

import { ReactNode } from "react";
import styles from './Layout.module.scss';


const Layout = ({ children }: { children?: ReactNode }) => {

  return (
    <AntLayout className={styles.container}>
      <Header />

      {children}


    </AntLayout>
  );
};

export default Layout;
