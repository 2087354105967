import { IBeneficialDetails } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class BeneficiaryService
  extends ServiceBase
  implements IBeneficiaryService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async postBeneficiaryDetails(body: IBeneficialDetails) {
    let url = ApiEndpoints.Beneficiary.addbeneficiaryUrl();
    const response: any = await this.doPost(url, body);
    return response;
  }
  async getBeneficiaryList() {
    let url = ApiEndpoints.Beneficiary.getBeneficiaryListUrl();
    const response: any = await this.doGet(url);
    return response;
  }
  async deleteBeneficiary(userId: string) {
    let url = ApiEndpoints.Beneficiary.deleteBeneficiaryUrl(userId);
    const response: any = await this.doDelete(url);
    return response;
  }

  async postBeneficiaryOwnerInvitation() {
    let url = ApiEndpoints.Beneficiary.setBeneficiaryOwnerInvitation();
    const response: any = await this.doPost(url);
    return response;
  }
}

export interface IBeneficiaryService {
  postBeneficiaryDetails(body: IBeneficialDetails): Promise<any>;
  getBeneficiaryList(): Promise<any>;
  deleteBeneficiary(userId: string): Promise<any>;
  postBeneficiaryOwnerInvitation(): Promise<any>;
}
