import React, { useEffect, useState } from "react";
import { Layout, List, Avatar } from "antd";
import data from "./WaitScreen.json";
import MobileFooter from "../MobileFooter/MobileFooter";
import MobileHeader from "../MobileHeader/MobileHeader";
import WaitImg from "../../../Assets/Images/waitImg.png";
import { CheckCircleFilled } from "@ant-design/icons";
import "./WaitScreen.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getCurrentRoutePath } from "../../Utility/Utility";

import { RouteList } from '../../../../Redux/Selector';
import { useSelector } from 'react-redux';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Content } = Layout;

const WaitScreen = () => {
  const navigate = useNavigate();
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [loadingPoints, setLoadingPoints] = useState(
    Array(data.points.length).fill(true)
  ); // Initialize all points to show loader

  const location = useLocation();
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(currentScreen, routePaths.Routes);
  
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeElapsed((prevTime) => prevTime + 1);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (timeElapsed >= 4) {
      // Redirect after 8 seconds (or your desired time)
      navigate(CurrentRoutePath?.Next);
    }
  }, [timeElapsed, navigate]);

  useEffect(() => {
    const pointTimer = setInterval(() => {
      setLoadingPoints((prevLoadingPoints) => {
        const newLoadingPoints = [...prevLoadingPoints];
        const nextIndex = newLoadingPoints.findIndex((loading) => loading);
        if (nextIndex !== -1) {
          newLoadingPoints[nextIndex] = false; // Show icon
        }
        return newLoadingPoints;
      });
    }, 1000);

    return () => {
      clearInterval(pointTimer);
    };
  }, []);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  return (
    <Layout className="mobile-layout">
      <MobileHeader />
      <Content>
        <div className="mobile-content-container">
          <div className="content-header">Please wait</div>
          <div className="wait-image-container">
            <div className="mobile-wait-image">
              <img src={WaitImg} alt="waitlogo" loading="lazy" />
            </div>
            <span className="timer-content">{formatTime(timeElapsed)}</span>
          </div>
          <div>
            <List
              itemLayout="horizontal"
              className="verified-list"
              dataSource={data.points}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        className="avatar-icon"
                        icon={
                          loadingPoints[index] ? (
                            <Spin indicator={antIcon} className="custom-spin" />
                          ) : (
                            <CheckCircleFilled className="completed-icon" />
                          )
                        }
                      />
                    }
                    title={<div className="content-text">{item.title}</div>}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Content>

      <MobileFooter />
    </Layout>
  );
};

export default WaitScreen;
