import {
  IBusinessPostInfoValues,
} from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class BusinessService extends ServiceBase implements IBusinessService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getBusinessInfo() {
    let url = ApiEndpoints.Business.getBusinessInfo();
    const response: any = await this.doGet(url);
    return response;
  }

  async postBusinessInfo(body: IBusinessPostInfoValues) {
    let url = ApiEndpoints.Business.postBusinessInfo();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async completeApplication() {
    let url = ApiEndpoints.Business.completeApplicationUrl();
    const response: any = await this.doGet(url);
    return response;
  }

  async postBeneficiaryType(body: any) {
    let url = ApiEndpoints.Business.setBeneficiaryType();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IBusinessService {
  getBusinessInfo(): Promise<any>;
  postBusinessInfo(body: IBusinessPostInfoValues): Promise<any>;
  completeApplication(): Promise<any>;
  postBeneficiaryType(body: any): Promise<any>;
}
