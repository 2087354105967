import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class MasterDataService
  extends ServiceBase
  implements IMasterDataService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getLegalTypes() {
    let url = ApiEndpoints.MasterData.getLegalType();
    console.log(url);
    const response: any = await this.doGet(url);
    return response;
  }

  async getDocumentTypes(countryCode: string | any) {
    let url = ApiEndpoints.MasterData.getDocumentType(countryCode);
    const response: any = await this.doGet(url);
    return response;
  }

  async getCountryConfigurations(countryCode: string) {
    let url = ApiEndpoints.MasterData.getCountryConfigurations(countryCode);
    const response: any = await this.doGet(url);
    return response;
  }

  async getIndustries() {
    let url = ApiEndpoints.MasterData.getIndustries();
    const response: any = await this.doGet(url);
    return response;
  }

  async getStateCityDetails(zipcode: string) {
    let url = ApiEndpoints.MasterData.getStateCityDetailsFromZipcode(zipcode);
    const response: any = await this.doGet(url);
    return response;
  }
}

export interface IMasterDataService {
  getLegalTypes(): Promise<any>;
  getDocumentTypes(countryCode: string | any): Promise<any>;
  getIndustries(): Promise<any>;
  getCountryConfigurations(countryCode: string | null): Promise<any>;
  getStateCityDetails(zipcode: string): Promise<any>;
}
