import { Modal, Button, Row, Col } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileExpiredPopup.scss";
import Loader from "../Common/Loader/Loader";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { applicationMessages } from "../Utility/ApplicationMessages";
import { showNotification } from "../Utility/Utility";

type ProfileExpiredProps = {
  showUserExpiredPopup: boolean;
  setShowUserExpiredPopup: (value: boolean) => void;
  phoneNumber: string;
  setShowAccessMessage: (value: boolean) => void;
  showAccessMessage: boolean;
  profileExpireTime: string;
};
const ProfileExpiredPopup = (props: ProfileExpiredProps) => {
  const navigate = useNavigate();
  const { getLoquatClient } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    props.setShowUserExpiredPopup(false);
  };

  const handleDeleteUser = () => {
    setLoading(true);
    var service = getLoquatClient().deleteUserService();
    const phone = props.phoneNumber;
    const numericPhoneNumber = phone.replace(/\D/g, "");
    const payload = {
      PhoneNumber: numericPhoneNumber,
      PassCode: "test@456",
      IsProfileExpired: true,
      SecurityKey: "",
      Reason: "Profile Expired",
      ConnectionString: "",
      IPAddress: "",
    };
    service
      .postDeleteUser(payload)
      .then(() => {
        handleOkClick();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      });
  };

  const handleOkClick = () => {
    props.setShowUserExpiredPopup(false);
    navigate("/letsgetready");
  };

  const handleRedirection = () => {
    navigate("/letsstartphone");
  };

  return (
    <Modal
      centered
      open={props.showUserExpiredPopup}
      width={900}
      maskClosable={false}
      closeIcon={true}
      className="NotificationPopup"
      onCancel={handleCancel}
      footer={[
        <Row>
          <Col span={24}>
            <Button
              className="single-popup-button"
              onClick={
                props.showAccessMessage === true
                  ? handleRedirection
                  : handleDeleteUser
              }
            >
              {loading ? (
                <Loader
                  checkLoading={loading}
                  color={"#fff"}
                  size={10}
                  inline={true}
                />
              ) : (
                "OK"
              )}
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div className="popup-container">
        <div className="popup-content">
          <div className="idleTimer-Header">
            {props.showAccessMessage !== true
              ? `Your ${props.profileExpireTime} window has expired`
              : "You do not have access."}
          </div>
          {props.showAccessMessage === true ? (
            <div className="description-text">Please contact Loquat</div>
          ) : (
            <div className="description-text">
              Please restart the application process
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProfileExpiredPopup;
