import { getValueFromStorage } from "../Components/Utility/Utility";
import { IDynamicConfig } from "../Configuration/Config";
const originUrl = window.location.origin;

export class AuthService {
  private static instance: AuthService;
  private config: IDynamicConfig;
  constructor(config: IDynamicConfig) {
    const settings = {
      authority: `${config.authority}`,
      clientId: `${config.clientId}`,
      redirectUri: `${originUrl}`,
      postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    };

    this.config = config;
  }

  public static getToken(){
    return getValueFromStorage("AccessToken");
  }

  public static getSubscriptionKey(){
    return getValueFromStorage("ProductKey");
  }

  public static getInstance(config: IDynamicConfig): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService(config);
    }

    return AuthService.instance;
  }
}
