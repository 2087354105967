import React from "react";
import "./LocationDeniedErrorPage.scss";
import { Layout} from "antd";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import errorImage from '../../Assets/Images/404-error.svg';
const {Content } = Layout;

const LocationDeniedErrorPage = () =>{

    return(
        <Layout className="start-page-container layout">
        <Content className="content">
          <div className="button-group">
          <img src={errorImage} alt="Error" className="error-image" />
          </div>
        </Content>
        <PrivacyPolicy />
      </Layout>
    )
}

export default LocationDeniedErrorPage;