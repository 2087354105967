import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { Layout, Row, Button } from "antd";
import "./MobileSMSValidation.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { IValidateOtp } from "../../../Contracts/Contracts";
import { ISendOtp } from "../../../Contracts/Contracts";
import Loader from "../../Common/Loader/Loader";
import { DEFAULT_CONFIG } from "../../../Configuration/Config";
import { applicationMessages } from "../../Utility/ApplicationMessages";
import {
  showNotification,
  handleSessionStorage,
  getValueFromStorage,
  setValueInStorage,
} from "../../Utility/Utility";
import MobileHeader from "../MobileHeader/MobileHeader";
import MobileFooter from "../MobileFooter/MobileFooter";

const { Content } = Layout;

const MobileSMSValidation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [codeNumber, setCodeNumber] = useState("");
  const [codeNumberError, setCodeNumberError] = useState("");
  const phoneNumber = location.state?.phoneNumber;
  const last4Digits = phoneNumber?.substr(phoneNumber.length - 4);
  const maskedPhoneNumber = `(XXX) XXX-${last4Digits}`;
  const [resendTime, setResendTime] = useState(60);
  const [loading, setloading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const { getLoquatClient } = useContext(ApplicationContext);
  const [isKyb, setKyb] = useState<string | any>(location.state?.kyb);
  const validCodeNumber = /^[0-9\b]+$/;
  let interval: NodeJS.Timeout | null = null;

  useEffect(() => {
    onResendClick(true);
  }, []);

  useEffect(() => {
    if (resendTime > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      interval = setInterval(() => {
        setResendTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      if (interval !== null) {
        clearInterval(interval); // Clear interval when countdown is done
      }
    }

    return () => {
      if (interval !== null) {
        clearInterval(interval); // Cleanup on unmount
      }
    };
  }, [resendTime]);

  const onResendClick = (isForce: boolean) => {
    if (resendTime === 0 || isForce) {
      setResendLoading(true);
      setResendTime(60);
      var service = getLoquatClient().authenticationService();
      const countryCode = getValueFromStorage("CountryCode");

      const phone = phoneNumber?.replace(/[()\-\s]/g, "");
      var payLoad: ISendOtp = {
        MobileNumber: DEFAULT_CONFIG.countryCode.concat(phone),
        CountryCode: countryCode,
      };
      service
        .getPhoneAuthenticationOtp(payLoad)
        .then((response: any) => {
          //console.log(response);
          //showNotification(applicationMessages.otpSentMessage, "");
          setResendLoading(false);
        })
        .catch((error: any) => {
          console.log(error);
          setResendLoading(false);
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        });
    }
  };

  useEffect(() => {
    getUserApplicationStatus();
  }, []);

  const getUserApplicationStatus = () => {
    const service = getLoquatClient().profileService();
    // var payLoad: ISendOtp = { MobileNumber:DEFAULT_CONFIG.countryCode.concat(phone)};
    service.getUserApplicationStatus().then((response: any) => {
      if (response) {
        const res = response?.successResult;
        const fileUploadSizeLimit =
          response?.successResult?.kybFilesUploadLimit;

        setValueInStorage("FileUploadSizeLimit", fileUploadSizeLimit);
      }
    });
  };

  const OnClick = () => {
    if (
      codeNumber &&
      codeNumber.length >= 4 &&
      validCodeNumber.test(codeNumber) &&
      codeNumber.length <= 6
    ) {
      setloading(true);
      var service = getLoquatClient().authenticationService();
      const phone = phoneNumber;

      var phoneOtpDetails: IValidateOtp = {
        MobileNumber: DEFAULT_CONFIG.countryCode.concat(phone),
        OTP: codeNumber,
      };

      service
        .postPhoneAuthenticationOtp(phoneOtpDetails)
        .then((response: any) => {
          if (response?.status === 400) {
            showNotification("", applicationMessages.defaultErrorMessage, true);
          } else {
            handleSessionStorage([], "set", {
              AccessToken: response.successResult.access_token,
              ProductKey: response.successResult.product_key,
              UserId: response.successResult.user_id,
            });

            if (isKyb && isKyb === "true") {
              navigate("/kyb-verification-details");
            } else {
              navigate("/verification-details");
            }
          }
        })
        .catch((error: any) => {
          setloading(false);
          if (
            error?.response?.data?.failureResult.toLowerCase() ===
            "invalid code"
          ) {
            showNotification(
              "",
              applicationMessages.smsCode.smsInvalidCodeDescription,
              true
            );
          } else {
            showNotification("", applicationMessages.pleaseTryAgainMsg, true);
          }
          // navigate("/letsgetready");
        });
    } else {
      setCodeNumberError(
        codeNumber.length === 0
          ? "SMS code is required"
          : codeNumber.length !== 6
          ? "Please enter the 6 digit one time passcode"
          : !validCodeNumber.test(codeNumber)
          ? "Please enter the 6 digit one time passcode"
          : ""
      );
    }
  };

  const handleSMSCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCodeNumber = e.target.value;
    if (
      newCodeNumber.length < 1 &&
      (e.nativeEvent as InputEvent).inputType === "deleteContentBackward"
    ) {
      setCodeNumber("");
      return;
    }
    if (!/^\d+$/.test(newCodeNumber)) {
      return;
    }
    setCodeNumber(newCodeNumber);

    if (newCodeNumber.length === 0) {
      setCodeNumberError("SMS code is required");
    } else if (!validCodeNumber.test(newCodeNumber)) {
      setCodeNumberError("Please enter the 6 digit one time passcode");
    } else if (newCodeNumber.length !== 6) {
      setCodeNumberError("Please enter the 6 digit one time passcode");
    } else {
      setCodeNumberError("");
    }
  };

  return (
    <Layout className="mobile-sms-layout">
      <MobileHeader />
      <Content className="mobile-sms-content">
        <div className="validateotpcontent">
          <div className="mobile-content-heading">
            {isKyb && isKyb === "true" ? (
              <div className="understanding-business-header">
                Understanding
                <br /> your business
              </div>
            ) : (
              <div>
                Let’s get you <br />
                verified
              </div>
            )}
          </div>
          <div className="mobileotp_val_details">
            <Row>
              <p className="validate-otp-description">
                Once you start, we will save the information you provided for 24
                hours, so you can jump in where you left off to finalize the
                application
              </p>
              <p className="validate-otp-description">
                Please, enter the verification code sent to {maskedPhoneNumber}
              </p>
              <div
                className={`phone_val_text_main  ${
                  codeNumberError ? "input-error" : ""
                }`}
              >
                <div className="phone_val_center application-inputBox">
                  <div className="phone_val_left_align">
                    <input
                      // type="number"
                      className="inputfield phone_sms_code text_poppins no-spinner"
                      placeholder="Enter SMS Code"
                      onChange={handleSMSCodeChange}
                      value={codeNumber}
                      maxLength={6}
                      required
                    />
                    <span className="text_poppins placeholder">
                      Enter SMS Code
                    </span>
                  </div>
                </div>
              </div>
              {codeNumberError && (
                <p className="error-message">{codeNumberError}</p>
              )}
            </Row>
            <div
              className={`phone_sms_resend clickable-icon ${
                resendTime > 0 ? "disabled" : ""
              }`}
              onClick={() => {
                onResendClick(false);
              }}
            >
              <span
                className={`glyphicon glyphicon-repeat right ${
                  resendTime > 0 ? "disabled-icon" : ""
                }`}
              ></span>
              <span
                className={`underline text_poppins padding_left ${
                  resendTime > 0 ? "disabled-text" : ""
                }`}
              >
                {resendTime > 0
                  ? `Resend the SMS Code (available in ${resendTime} sec)`
                  : "Resend the SMS Code"}
              </span>
              {resendLoading && (
                <Loader
                  checkLoading={resendLoading}
                  color={"#123abc"}
                  size={7}
                  inline={true}
                />
              )}
            </div>
          </div>
        </div>
      </Content>
      <MobileFooter buttonName="Next" onClick={OnClick} />
    </Layout>
  );
};

export default MobileSMSValidation;
