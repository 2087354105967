import { IDeleteUserProps } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import {
    ILoquatServiceClient,
} from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class DeleteUserService
  extends ServiceBase
  implements IDeleteUserService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async postDeleteUser(body: IDeleteUserProps) {
    let url = ApiEndpoints.DeleteUser.postDeleteUser();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IDeleteUserService {
    postDeleteUser(body: any): Promise<any>;
}
