import React, { useState } from "react";
import left_logo from "../../Assets/Images/left_logo.png";
import right_logo from "../../Assets/Images/right_logo.png";
import "./StepsHeader.scss";
import { IStepsHeader } from "../../Contracts/Contracts";
import FinalSupportPopup from "../FinalSupportPopup/FinalSupportPopup";

const StepsHeader: React.FC<IStepsHeader> = (titleProps: IStepsHeader) => {
  const [showPopup, setShowPopup] = useState(false);
  const handleSupportPopup=()=>{
    setShowPopup(true);
  }
  return (
    <div className="steps-header">
      <div className="header_position">
        <span className="leftimg">
          <img className="leftcornerimg" src={left_logo} alt="Logo" />
        </span>
        {/* <div className="letsheading">
          <h5 className={titleProps.titleClass}>{titleProps.title}</h5>
          <span className="stepborder">Step 1/7</span>
  </div>*/}
        <div className="letsheading">
          <img className="img-responsive marginHeader" src={titleProps.img} alt="step-header"/>
        </div>

        <span className="rightimg">
          <img className="rightcornerimg" src={right_logo} alt="Logo" onClick={handleSupportPopup}/>
        </span>
      </div>
      <FinalSupportPopup showPopup={showPopup} setShowPopup={setShowPopup} />
    </div>
  );
};

export default StepsHeader;
