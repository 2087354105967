import React, { useEffect, useState } from "react";
import "./Acuant.scss";
import { Button, Layout } from "antd";
import { IAcuantProps } from "../../Contracts/Contracts";
import accountcard from "../../Assets/Images/Dashboard/accountcard.png";
import { BeatLoader } from "react-spinners";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";

const AcuantSelfie: React.FC<IAcuantProps> = (props: IAcuantProps) => {
  const [type, setType] = useState<string>("initial");
  const [text, setText] = useState<string>("");
  const [error, setError] = useState<any>("");
  const [image, setImage] = useState<any>(accountcard);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [onClickNavigation, setOnClickNavigation] = useState(false);

  useEffect(() => {
    console.log("AcuantSelfie.", type);
    if (type === "initial") {
      openCamera();
    }
  }, [type]);

  const openCamera = () => {
    // if (window?.AcuantPassiveLiveness?.isCameraSupported) {
    window?.AcuantPassiveLiveness.start(cameraCallback, faceDetectionState);
    //}
  };

  const faceDetectionState: any = {
    FACE_NOT_FOUND: "FACE NOT FOUND",
    TOO_MANY_FACES: "TOO MANY FACES",
    FACE_ANGLE_TOO_LARGE: "FACE ANGLE TOO LARGE",
    PROBABILITY_TOO_SMALL: "PROBABILITY TOO SMALL",
    FACE_TOO_SMALL: "FACE TOO SMALL",
    FACE_CLOSE_TO_BORDER: "TOO CLOSE TO THE FRAME",
  };

  const cameraCallback: any = {
    onDetectorInitialized: () => {},
    onDetection: (text: any) => {
      console.log(text);
      setText(text);
    },
    onOpened: () => {
      console.log("onOpened");
    },
    onClosed: () => {
      console.log("onClosed");
    },
    onError: (error: any) => {
      console.log(error.message);
    },
    onPhotoTaken: () => {
      console.log("onPhotoTaken");
    },
    onPhotoRetake: () => {
      console.log("onPhotoRetake");
    },
    onCaptured: (image: any) => {
      console.log(image, "onCaptured");
      setType("preview");
      setError("Processing Your selfie liveness.");
      window?.AcuantPassiveLiveness.getLiveness(
        {
          endpoint: window?.Credential.liveness_endpoint,
          token: btoa(
            window?.Credential.passive_username +
              ":" +
              window?.Credential.passive_password
          ),
          image: image,
          subscriptionId: window?.Credential.passive_subscriptionId,
        },
        (result: any) => {
          if (result) {
            if (result.LivenessResult) {
              setType("none");
              if (
                result.LivenessResult?.LivenessAssessment?.toLowerCase() ===
                "notlive"
              ) {
                setShowNotificationPopup(true);
                setNotificationMessage("Live human being not recognized");
                setDescriptionText("Please try again");
                setOnClickNavigation(true);
              } else {
                if (props.selectedImage) {
                  props.selectedImage("data:image/jpeg;base64," + image, null);
                }
              }
            } else {
              if (result.Error) {
                if (result?.Error?.includes("Unable to detect a face")) {
                  setType("none")
                  setShowNotificationPopup(true);
                  setNotificationMessage(
                    "Human face not recognized. Please be sure you removed any glasses, hats, face masks or other facial accessories"
                  );
                  setDescriptionText("Please try again");
                  setOnClickNavigation(true);
                } else {
                  setError(result.Error);
                }
              }
            }
          }
        }
      );
    },
  };

  const retakeImage = () => {
    setType("initial");
    setShowNotificationPopup(false);
  };

  const useImage = () => {
    //TODO : need to navigate to next screen.
    if (props.selectedImage) {
      props.selectedImage(image, null);
    }
  };

  return (
    <Layout>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {type == "initial" ? (
          <>
            <div
              id="acuant-face-capture-container"
              className="face-camera-container"
            >
              <div id="face-detection-text" className="detection-state">
                {text}
              </div>
            </div>
          </>
        ) : type == "preview" ? (
          <>
            <div className="loquat-kyc-selfie-preview">
              <div className="selfie-preview-container">
                <span className="preview-selfie-text">
                  {error}
                  {error?.includes("Processing your") ? <BeatLoader /> : ""}
                </span>
              </div>
              <div className="selfie-action-buttons">
                {error?.includes("Processing your") ? (
                  ""
                ) : (
                  <Button
                    className="accuantbtnSelfie acuant-use"
                    onClick={retakeImage}
                  >
                    Retake
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <NotificationPopup
        showPopup={showNotificationPopup}
        setShowPopup={setShowNotificationPopup}
        headerText={notificationMessage}
        descriptionText={descriptionText}
        onClickNavigation={onClickNavigation}
        buttonCount={1}
        handleClick={retakeImage}
      />
    </Layout>
  );
};

export default AcuantSelfie;
