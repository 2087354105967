import React, { useContext, useEffect, useState } from "react";
import "./LetsGetReady.scss";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Flex, Layout, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { IPostLegalTypeDetails } from "../../Contracts/Contracts";

import ListItems from "./ListItems";

import Loader from "../Common/Loader/Loader";
import { applicationMessages } from "../Utility/ApplicationMessages";
import {
  getValueFromStorage,
  setValueInStorage,
  showNotification,
  getCurrentRoutePath,
} from "../Utility/Utility";
import { useNavigate, useLocation } from "react-router-dom";

import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";

import FooterMain from "../Footer/FooterMain";

const LetsGetReady = () => {
  const [listValues, setListValues] = useState<{ legalTypes: any[] }>({
    legalTypes: [],
  });
  let formattedLegalType = {
    documentType: [],
    legalTypeId: null,
    legalTypeName: null,
  };

  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<any>(formattedLegalType);
  const { getLoquatClient, setDocumentData } = useContext(ApplicationContext);
  const [highlightBorder, setHighlightBorder] = useState(false);
  const userType = getValueFromStorage("UserType");
  const navigate = useNavigate();
  const legalTypeSelected = getValueFromStorage("DocumentData");

  const location = useLocation();
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  if (legalTypeSelected) {
    const parsedLegalType = JSON.parse(legalTypeSelected);
    formattedLegalType.documentType = parsedLegalType.documentTypes || [];
    formattedLegalType.legalTypeId =
      parsedLegalType.legalType.legalTypeId || null;
    formattedLegalType.legalTypeName =
      parsedLegalType.legalType.legalTypeName || null;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    const response = {
      successResult: {
        legalTypes: [
          {
            legalTypeId: 1,
            legalTypeName: "Sole proprietorship",
            documentType: [],
          },
          {
            legalTypeId: 2,
            legalTypeName: "LLC (Limited Liability Company)",
            documentType: [
              {
                documentTypeId: 7,
                documentTypeName: "Operating agreement (if applicable)",
              },
              {
                documentTypeId: 8,
                documentTypeName: "Articles of Incorporation",
              },
              {
                documentTypeId: 9,
                documentTypeName: "Certificate of Incorporation",
              },
            ],
          },
          {
            legalTypeId: 3,
            legalTypeName: "Corporation",
            documentType: [
              {
                documentTypeId: 10,
                documentTypeName: "Articles of Incorporation",
              },
              {
                documentTypeId: 11,
                documentTypeName: "Certificate of Good Standing",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
          {
            legalTypeId: 4,
            legalTypeName: "Non Profit Corporation",
            documentType: [],
          },
          {
            legalTypeId: 5,
            legalTypeName: "Association",
            documentType: [],
          },
          {
            legalTypeId: 6,
            legalTypeName: "LLP",
            documentType: [
              {
                documentTypeId: 5,
                documentTypeName: "An LLP Agreement",
              },
              {
                documentTypeId: 6,
                documentTypeName: "A Certificate of Incorporation",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
          {
            legalTypeId: 7,
            legalTypeName: "LLLP",
            documentType: [],
          },
          {
            legalTypeId: 8,
            legalTypeName: "LP",
            documentType: [],
          },
          {
            legalTypeId: 9,
            legalTypeName: "General Partnership",
            documentType: [
              {
                documentTypeId: 1,
                documentTypeName: "Partnership Agreement",
              },
              {
                documentTypeId: 2,
                documentTypeName: "Fictitious Name Certificate or Statement",
              },
              {
                documentTypeId: 3,
                documentTypeName: "Certificate of Assumed Name",
              },
              {
                documentTypeId: 4,
                documentTypeName: "Business License",
              },
            ],
          },
        ],
      },
    };

    setListValues(response?.successResult);
    if (response?.successResult?.legalTypes?.length > 0) {
      setLoading(false);
      setDocumentData({
        documentTypes: response?.successResult?.legalTypes[0].documentType,
        legalType: {
          legalTypeId: response?.successResult?.legalTypes[0].legalTypeId,
          legalTypeName: response?.successResult?.legalTypes[0].legalTypeName,
        },
      });

      const getSelectedLegalType = getValueFromStorage("SelectedLegalType");
      if (getSelectedLegalType) {
        const selectedLegalTypeId = parseInt(getSelectedLegalType);
        const foundOption = response?.successResult.legalTypes?.find(
          (option: { legalTypeId: number }) =>
            option.legalTypeId === selectedLegalTypeId
        );
      }

      setSelectedOption(response?.successResult?.legalTypes[1]);
    }
  }, []);

  const handleSelectChange = (selectedValue: number) => {
    const selectedLegalType = listValues?.legalTypes?.find(
      (option) => option?.legalTypeId === selectedValue
    ) as any | undefined;
    if (selectedLegalType) {
      setSelectedOption(selectedLegalType);
      setDocumentData({
        documentTypes: selectedLegalType?.documentType,
        legalType: {
          legalTypeId: selectedLegalType.legalTypeId,
          legalTypeName: selectedLegalType.legalTypeName,
        },
      });
      const document = {
        documentTypes: selectedLegalType?.documentType,
        legalType: {
          legalTypeId: selectedLegalType.legalTypeId,
          legalTypeName: selectedLegalType.legalTypeName,
        },
      };
      const legalTypeId = selectedLegalType?.legalTypeId?.toString();
      setValueInStorage("SelectedLegalType", legalTypeId);
      const data = JSON.stringify(document);
      setValueInStorage("DocumentData", data);
      setHighlightBorder(false);
    }
  };

  const saveLegalType = () => {
    if (selectedOption?.legalTypeId) {
      const service = getLoquatClient().profileService();
      const postdata: IPostLegalTypeDetails = {
        legalTypesId: selectedOption.legalTypeId,
      };
      service
        .postLegalType(postdata)
        .then((response) => {
          if (response?.status === 400) {
            console.log(response, "error");
            showNotification("", applicationMessages.pleaseTryAgainMsg, true);
          } else {
            navigate(CurrentRoutePath?.Next);
          }
        })
        .catch((error) => {
          console.log(error);
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        });
    } else {
      showNotification("", "Legal Type selection is required", true);
      setHighlightBorder(true);
    }
  };

  return (
    <>
      <Layout className="lets-get-ready-content layout">
        <h1 className="lets-get-ready__title">Let’s get ready</h1>
        <p className="lets-get-ready__subtitle">
          We collect information to protect you and your business from unlawful
          activities and to comply with federal and state regulations
        </p>

        <h3 className="getting_heading">Getting to know you</h3>
        <h5 className="boldcontent">
          To open an account you will need to take a selfie and upload one of
          these documents:
        </h5>
        <div className="listitems">
          <div className="docs-list-wrapper">
            <Flex vertical className="column">
              <p>
                <span className="tickimg">
                  <CheckOutlined />
                </span>
                <span className="itemcontent">Passport (U.S.)</span>
              </p>

              <p>
                <span className="tickimg">
                  <CheckOutlined />
                </span>
                <span className="itemcontent">Driver’s License</span>
              </p>

              <p>
                <span className="tickimg">
                  <CheckOutlined />
                </span>
                <span className="itemcontent">
                  Government Issued Identity Card
                </span>
              </p>
            </Flex>

            <Flex vertical className="column">
              <p>
                <span className="tickimg">
                  <CheckOutlined />
                </span>
                <span className="itemcontent">
                  International Driver's License
                </span>
              </p>
              <p>
                <span className="tickimg">
                  <CheckOutlined />
                </span>
                <span className="itemcontent">
                  Foreign Passport and I-94 form
                </span>
              </p>
            </Flex>
          </div>
          {userType?.toLowerCase() !== "secondary" && (
            <div>
              <div className="text-wrapper">
                <h3 className="getting_heading understanding">
                  Understanding your business
                </h3>

                <Flex vertical gap="16px">
                  <p>
                    You will be required to identify any beneficial owners on
                    the business, including yourself. A beneficial owner is any
                    person or legal entity who owns more than 25% of the
                    business. Once you have identified them, we will send them a
                    link to provide their selfie, ID, and some personal
                    information.
                  </p>
                  <p>
                    You will also have the opportunity to add any Responsible
                    Individuals to the account. Responsible Individuals are
                    vested with the full authority to open and close accounts on
                    behalf of the business, and add or remove Authorized Signers
                    on behalf of the business.
                  </p>

                  <p>
                    Finally, you will be asked to identify Authorized Signers.
                    Authorized Signers are vested with the authority to complete
                    transactions on the account but are unable to make changes
                    to the account.
                  </p>
                </Flex>
              </div>

              <h5 className="boldcontent">
                Please choose your business legal type to see what documents are
                required:
              </h5>

              {listValues?.legalTypes?.length > 0 ? (
                <div className="select_limit_main ">
                  <div
                    className={`select-legal-type  ${
                      highlightBorder ? "input-error" : ""
                    }`}
                  >
                    <span className="legal-type-label">
                      Business Legal Type
                    </span>
                    {/* <Space wrap className="fullWidth info_inputTextClass" size={100}> */}
                    <Select
                      className="legal-type-select-width"
                      options={listValues.legalTypes.map((option) => ({
                        value: option.legalTypeId,
                        label: option.legalTypeName,
                      }))}
                      defaultValue={3}
                      bordered={false}
                      onChange={handleSelectChange}
                      placeholder="Select Legal Type"
                      value={selectedOption && selectedOption?.legalTypeId}
                    />
                  </div>
                </div>
              ) : (
                <div className="select_limit_main onlyLegal">
                  <div className="select-legal-type ">
                    <span className="legal-type-label">Legal Type</span>
                    <Loader
                      checkLoading={loading}
                      color={"#123abc"}
                      size={10}
                      inline={true}
                      class="loader-align"
                    />
                  </div>
                </div>
              )}
              {selectedOption && <ListItems selectedOption={selectedOption} />}
              {/* <p className="beneficial_para">
                * Beneficial owner - every individual/legal entity who
                owns 25% or more
              </p> */}
            </div>
          )}
        </div>
      </Layout>
      <FooterMain
        isProgressBarEnabled={true}
        currentStep={1}
        totalSteps={8}
        nextPath="accountselection"
        name="Let's get ready"
      />
    </>
  );
};

export default LetsGetReady;
