import * as DateUtility from "../Utility/Utility";
export const FormatDate = DateUtility.getFormattedDate;
export const setDateFormat = DateUtility.setDateFormat;
export const getNonUTCFormat = DateUtility.getNonUTCFormat;
export const setTimeFormat = DateUtility.setTimeFormat;
export const getTimeFormat = DateUtility.getTimeFormat;

export interface BaseDataTypes {
  dateFormat: string;
}

export const CommonValues: BaseDataTypes = {
  dateFormat: "MM/DD/YYYY",
};