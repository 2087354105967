import left_logo from "../../Assets/Images/left_logo.png";
import React, { useContext, useState, useEffect } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { Layout, Row, Button, Col, Divider } from "antd";
import "./PhoneValidationSample.scss";
import "../Footer/ResponsiveFooter.scss";
import arrow from "../../Assets/Images/ArrowPhone.png";
import { useNavigate, useLocation } from "react-router-dom";
import { ISendOtp } from "../../Contracts/Contracts";
import Loader from "../Common/Loader/Loader";
import { DEFAULT_CONFIG } from "../../Configuration/Config";
import { applicationMessages } from "../Utility/ApplicationMessages";
import mobilenextarrow from "../../Assets/Images/mobileNextArrow.png";
import goback from "../../Assets/Images/FooterGobackarrow.png";
import goBackArrow from "../../Assets/Images/DashboardImages/gobackarrow.png";
import {
  getTimerPromise,
  showNotification,
  handleSessionStorage,
  setValueInStorage,
  getValueFromStorage,
  handleLocationPermission,
  getCurrentRoutePath,
} from "../Utility/Utility";
import welcomeImg from "../../Assets/Images/Welcomeback2.png";
import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";
import { Header } from "antd/lib/layout/layout";
import ResponsiveNoStepsHeader from "../Header/ResponsiveNoStepsHeader";
import MobileFooter from "../MobileScreens/MobileFooter/MobileFooter";
import ResponsiveFooter from "../Footer/ResponsiveFooter";
import { useWindowSize } from "react-use";
import MobileFooterSample from "./MobileFooterSample";
import DeleteUserPopup from "../StartPage/DeleteUserPopup/DeleteUserPopup";

const { Footer, Content } = Layout;

const ResponsiveDesignSample: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const { getLoquatClient } = useContext(ApplicationContext);
  const [locationPermission, setLocationPermission] = useState("default");
  const [checkGeoLocation, setCheckGeoLocation] = useState<boolean>(false);
  const [isApiCall, setIsApiCall] = useState(false);
  const geolocationAllowed = getValueFromStorage("GeoLocationAllowed");
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [onClickNavigation, setOnClickNavigation] = useState(false);
  const [popupContentCSSChange, setPopupContentCSSChange] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [title, setTitle] = useState("Let’s get started");
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const { width } = useWindowSize();
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  const apicall = (latitude: number, longitude: number) => {
    var service = getLoquatClient().geolocationService();
    const payload = {
      latitude: latitude,
      longitude: longitude,
    };
    service
      .postAllowGeoLocation(payload)
      .then((response) => {
        if (response?.status === 400) {
          setIsApiCall(false);
          setNotificationMessage(
            applicationMessages.geoLocation.geoLocationError
          );
          setShowNotificationPopup(true);
        } else {
          setValueInStorage("CountryCode", response.successResult?.countryCode);
          // showNotification(applicationMessages.geoLocation.geoLocationSuccess);
          setNotificationMessage(
            applicationMessages.geoLocation.geoLocationSuccess
          );
          setOnClickNavigation(false);
          setShowNotificationPopup(true);
        }
      })
      .catch((error) => {
        setIsApiCall(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!isApiCall) {
      handleLocationPermission((latitude: any, longitude: any, error: any) => {
        if (error) {
          if (error === "notSelected") {
            setIsApiCall(false);
          } else {
            setLocationPermission("denied");
            // apicall(0, 0);
            setCheckGeoLocation(false);
            setIsApiCall(false);
          }
        } else {
          setLocationPermission("allowed");
          setCheckGeoLocation(true);
          setValueInStorage("GeoLocationAllowed", "true");
          apicall(latitude, longitude);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiCall]);

  useEffect(() => {
    const keysToRemove = [
      "PhoneNumber",
      "UserType",
      "AccessToken",
      "ProductKey",
      "UserId",
      "businessId",
      "businessName",
      "ScheduleCall",
      "FlowType",
      "SelectedLegalType",
    ];
    handleSessionStorage(keysToRemove, "remove");
    if (location.pathname === "/welcomeback") {
      setValueInStorage("WelcomeBack", "true");
      setTitle("Welcome back");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClick = () => {
    const geolocation = getValueFromStorage("GeoLocationAllowed");
    if (geolocation === null) {
      setNotificationMessage(applicationMessages.geoLocation.geolocationSelect);
      setOnClickNavigation(false);
      setPopupContentCSSChange(false);
      setShowNotificationPopup(true);
      return;
    }
    if (phoneNumber.length > 0 && phoneNumber.length === 14) {
      setLoading(true);
      var service = getLoquatClient().authenticationService();
      const phone = phoneNumber.replace(/[()\-\s]/g, "");
      const countryCode = getValueFromStorage("CountryCode");
      var payLoad: ISendOtp = {
        MobileNumber: DEFAULT_CONFIG.countryCode.concat(phone),
        CountryCode: countryCode && countryCode,
        // CountryCode: "In",
      };
      setValueInStorage("PhoneNumber", phone);
      service
        .getPhoneAuthenticationOtp(payLoad)
        .then((response: any) => {
          setLoading(false);
          if (response.status === 200) {
            navigate(CurrentRoutePath?.Next, {
              state: { phoneNumber: phone },
            });
          } else {
            if (
              response.status === 400 &&
              response.failureResult.code === 21614
            ) {
              setNotificationMessage("Invalid phone number, please try again");
              setOnClickNavigation(false);
              setShowNotificationPopup(true);
            } else {
              setNotificationMessage(
                "Unable to send SMS message, please try again"
              );
              setOnClickNavigation(false);
              setShowNotificationPopup(true);
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
          setNotificationMessage(
            "Unable to send SMS message, please try again"
          );
          setOnClickNavigation(false);
          setShowNotificationPopup(true);
        });
    } else {
      setPhoneNumberError(
        phoneNumber.length !== 14
          ? phoneNumber.length === 0
            ? "Please enter a valid phone number"
            : "Please enter a valid phone number "
          : ""
      );
    }
  };
  const onGoBackClick = () => {
    navigate(-1);
  };

  const handleOkClick = () => {
    const phone = phoneNumber.replace(/[()\-\s]/g, "");
    navigate(CurrentRoutePath?.Next, {
      state: { phoneNumber: phone },
    });
  };

  const handlePhoneNumberChange = (e: { target: { value: any } }) => {
    // const inputPhoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);

    setPhoneNumber(formattedPhoneNumber);
    if (formattedPhoneNumber.length !== 14) {
      setPhoneNumberError("Please enter a valid phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "e") {
      event.preventDefault(); // Prevent the "e" key from being input
    }
  };

  return (
    <Layout className="phoneValidationScreen">
      <ResponsiveNoStepsHeader
        phoneNumber={phoneNumber}
        title={title}
        titleClassName="letsgetstartedtitle"
      />
      <Content className="responsive-content">
        <Row>
          <Col flex="auto"></Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "0px"
                : width >= 1025 && width <= 1500
                ? "10%"
                : "170px"
            }
          ></Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "87%"
                : width >= 1025 && width <= 1500
                ? "60%"
                : "789px"
            }
          >
            <div className="middleContentContainer">
              {location.pathname === "/responsivedesignsample" && (
                <div className="phoneValidationContent">
                  Once you start, we will save the information you provided for
                  24 hours, so you can jump in where you left off to finalize
                  the application
                </div>
              )}
              <div className="phonenumber-input-container">
                <div className="inputtext-container">
                  <span className="phone-number-inputheading">
                    My phone number is
                  </span>
                  <br />
                  <span className="phone-number-inputtext">(000) 000-0000</span>
                  <br />
                  <span>
                    <Divider
                      style={{ borderColor: "#1d1e1e", margin: "6px 0" }}
                    />
                  </span>
                </div>
                <div>
                  <img
                    className="mobile-next-arrow"
                    src={mobilenextarrow}
                    alt="nxt"
                  />
                </div>
              </div>
              <Row className="number-container">
                <div
                  className={`phone_val_text_main  ${
                    phoneNumberError ? "input-error" : ""
                  }`}
                >
                  <span className="text_poppins phone_val_sms">
                    {!showPlaceholder ? "My Phone number is" : ""}
                  </span>
                  <div className="phone_val_center leftMargin">
                    <div className="phone_val_left_align">
                      <input
                        // type="number"
                        className="inputfield text_poppins no-spinner"
                        placeholder={
                          showPlaceholder ? "My Phone number is" : ""
                        }
                        onFocus={() => setShowPlaceholder(false)}
                        onBlur={() =>
                          !phoneNumber.length && setShowPlaceholder(true)
                        }
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                        onKeyDown={handleKeyDown}
                        maxLength={14}
                      />
                    </div>

                    <span
                      className={`cursor loaderPhone ${
                        loading ? "disabled" : "rightAlign"
                      }`}
                    >
                      {loading ? (
                        <Loader
                          checkLoading={loading}
                          color={"#123abc"}
                          size={10}
                          inline={true}
                        />
                      ) : (
                        <img
                          src={arrow}
                          alt="Logo"
                          className="header-logo img-responsive phone_val_arrow"
                          onClick={onClick}
                        />
                      )}
                    </span>
                  </div>
                </div>
                {phoneNumberError && (
                  <div className="error-message">{phoneNumberError}</div>
                )}
              </Row>

              <div className="phonevalidationconsentheading">
                Loquat, Inc. Notifications Consent
              </div>
              <div className="phonevalidationconsentdescription">
                By providing your phone number, you consent to receive messages
                and calls from Loquat, Inc. related to our services. Message and
                data rates may apply. You can opt out anytime by replying "STOP"
                to our messages. We value your privacy and won't share your
                number with third parties without your consent. Thank you for
                choosing Loquat, Inc.
              </div>
            </div>
          </Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "3%"
                : width >= 1025 && width <= 1500
                ? "20%"
                : "279px"
            }
          ></Col>

          <Col flex="auto"></Col>
        </Row>
      </Content>
      {width > 640 ? (
        <Row className="responsive-footer">
          <Col flex="auto"></Col>

          <Col
            flex={
              width > 640 && width < 1025
                ? "45%"
                : width >= 1025 && width <= 1500
                ? "45%"
                : "220px"
            }
            className="responsive-button-container"
          >
            <div
              className={
                window.location.href.includes("/letsstartphone") ||
                window.location.href.includes("/welcomeback")
                  ? "gobackbutton-container hidebutton"
                  : "gobackbutton-container"
              }
            >
              <span>
                <img className="goback_arrowimg" src={goback} alt="Logo" />
              </span>
              <span className="gobackbuttontext">Go back</span>
            </div>
          </Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "0px"
                : width >= 1025 && width <= 1500
                ? ""
                : "465px"
            }
          ></Col>
          <Col
            flex={
              width > 640 && width < 1025
                ? "45%"
                : width >= 1025 && width <= 1500
                ? "45%"
                : "558px"
            }
            className="responsive-right-button-container"
          >
            <div>
              <Button
                className="next-step-button  responsivenext-button"
                onClick={onClick}
                disabled={loading}
              >
                Next
              </Button>
            </div>
          </Col>

          <Col flex="auto"></Col>
        </Row>
      ) : (
        <MobileFooterSample />
      )}
      <NotificationPopup
        showPopup={showNotificationPopup}
        setShowPopup={setShowNotificationPopup}
        headerText={notificationMessage}
        descriptionText={descriptionText}
        buttonCount={1}
        onClickNavigation={onClickNavigation}
        handleClick={handleOkClick}
        popupContentCSSChange={popupContentCSSChange}
      />
      {isDeleteModalVisible && (
        <DeleteUserPopup
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          phoneNumber={phoneNumber}
        />
      )}
    </Layout>
  );
};

export default ResponsiveDesignSample;
