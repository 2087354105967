import React, { useContext, useEffect, useState } from "react";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import {
  Layout,
  Space,
  Row,
  Form,
  Col,
  Input,
  Select,
  DatePicker,
  Flex,
} from "antd";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./PersonalInfo.scss";
import image from "../../Assets/Images/headerImages/personalInfo.png";
import {
  FormErrorHandlingFunction,
  disabledDateRange,
  formattedSSNValue,
  getTimerPromise,
  getValueFromStorage,
  handleNumberKeyDown,
  showNotification,
  validateEmail,
  validateFieldName,
} from "../Utility/Utility";
import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import * as Base from "../Common/Base";
import { CommonValues } from "../Common/Base";
import { formatPhoneNumber } from "../Utility/Utility";
import {
  IPersonalInfoFormValues,
  IPersonalInfoPostDetails,
  IStepsHeader,
  personalInfoValues,
} from "../../Contracts/Contracts";
import StepsProgressBar from "../Common/StepsProgressBar/StepsProgressBar";
import dayjs from "dayjs";
import data from "./States.json";
import { applicationMessages } from "../Utility/ApplicationMessages";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";
import FooterMain from "../Footer/FooterMain";

const { Content } = Layout;
const size = "large";

const headerValues: IStepsHeader = {
  title: "Please review",
  titleClass: "letsheadingh5",
  img: image,
};
const PersonalInfo = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [formValues, setFormValues] = useState<IPersonalInfoFormValues | any>(
    personalInfoValues
  );
  const [loading, setLoading] = useState(false);
  const dateFormat = CommonValues.dateFormat;
  const [selectedState, setSelectedState] = useState<any>({});
  const [formattedSSN, setFormattedSSN] = useState("XXX-XX-0000");
  const [originalSSN, setOriginalSSN] = useState("");
  const [inputArray, setInputArray] = useState<any>([]);
  const { getLoquatClient } = useContext(ApplicationContext);
  const navigate = useNavigate();
  const finalStepNavigation = location?.state?.FinalStepNavigation;
  const userType = getValueFromStorage("UserType");
  const phoneNumber = getValueFromStorage("PhoneNumber");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);

  const formattedPhoneNumber = phoneNumber
    ? formatPhoneNumber(phoneNumber)
    : "";

  const [errorField, setErrorField] = useState<string[] | any>([]);

  useEffect(() => {
    const service = getLoquatClient().profileService();
    const userId = getValueFromStorage("UserId");
    // service
    //   .getPersonalInfo(userId)
    //   .then((response: any) => {
    //     if (response) {
    //       const details = response?.successResult;
    //       if (details?.ssn) {
    //         const returnValue = formattedSSNValue(response?.successResult?.ssn);
    //         setFormattedSSN(returnValue);
    //         setOriginalSSN(response?.successResult?.ssn);
    //       }

    //       // setPersonalDetails(response?.successResult);
    //       const formDetails: IPersonalInfoFormValues = {
    //         firstname: details?.firstName,
    //         lastname: details?.lastName,
    //         middlename: details?.middleName ? details?.middleName : "",
    //         SSN: details?.ssn ? details?.ssn : "",
    //         email: details?.email,
    //         phone: details?.phone
    //           ? formatPhoneNumber(details?.phone)
    //           : formattedPhoneNumber,
    //         dateofbirth: details?.dateOfBirth
    //           ? dayjs(details?.dateOfBirth)
    //           : "",
    //         zip: details?.zip,
    //         state: details?.state,
    //         city: details?.city,
    //         streetaddress1: details?.addressLine1,
    //         streetaddress2: details?.addressLine2,
    //       };
    //       setFormValues(formDetails);
    //       form.setFieldsValue(formDetails);
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.log(error);
    //     // notification.error({
    //     //   message: applicationMessages.errorMessage,
    //     //   description: applicationMessages.errorUnableToFetchPersonaInfo,
    //     // });
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = () => {
    setLoading(true);
    const service = getLoquatClient().profileService();
    const countryCode = getValueFromStorage("CountryCode");
    const updatedValues: IPersonalInfoFormValues = {
      ...formValues,
      phone: formValues.phone.replace(/[()\-\s]/g, ""),
      dateofbirth: formValues.dateofbirth
        ? Base.setDateFormat(formValues.dateofbirth)
        : "",
      SSN: formValues.SSN.replace(/-/g, ""),
    };
    const postDetails: IPersonalInfoPostDetails = {
      SSN: originalSSN,
      DateOfBirth: updatedValues.dateofbirth,
      Email: updatedValues.email,
      FirstName: updatedValues.firstname,
      LastName: updatedValues.lastname,
      MiddleName: updatedValues.middlename,
      Phone: updatedValues.phone,
      CountryCode: countryCode,
      Address: {
        AddressLine1: updatedValues.streetaddress1,
        AddressLine2: updatedValues.streetaddress2,
        State: updatedValues.state,
        City: updatedValues.city,
        Zip: updatedValues.zip,
      },
    };
    service
      .postPersonalInfo(postDetails)
      .then((response: any) => {
        setLoading(false);
        if (response?.status === 400) {
          setNotificationMessage(
            applicationMessages.personalInfo.personalInfoInvalidDesc
          );
          setShowNotificationPopup(true);
        } else {
          getTimerPromise(() => {
            navigate(
              finalStepNavigation !== undefined && finalStepNavigation !== false
                ? "/finalstep"
                : userType?.toLowerCase() === "secondary"
                ? "/beneficialthanks"
                : "/businessinfo"
            );
          });
        }
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        // navigate((finalStepNavigation !== undefined && finalStepNavigation !== false)? "/finalstep" : "/businessinfo");
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      });
  };

  const onChange = (fieldName: string, value: any) => {
    let updatedValues;
    if (fieldName.toLowerCase() === "phone") {
      updatedValues = {
        ...formValues,
        phone: formatPhoneNumber(value),
      };
    } else {
      updatedValues = {
        ...formValues,
        [fieldName]: value,
      };
    }
    if (fieldName.toLowerCase() === "state") {
      setSelectedState({ name: value });
    }
    form.setFieldsValue(updatedValues);
    setFormValues(updatedValues);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Backspace" && formValues?.phone.length <= 7) {
      event.preventDefault();
      form.setFieldsValue({ phone: "" });
      setFormValues((prevValues: IPersonalInfoFormValues) => ({
        ...prevValues,
        phone: "",
      }));
    }
  };

  // Fetch city and state by ZIP code
  // useEffect(() => {
  //   const service = getLoquatClient().masterDataService();
  //   const zipcode = formValues.zip;
  //   if (formValues?.zip?.length === 5) {
  //     service
  //       .getStateCityDetails(zipcode)
  //       .then((response) => {
  //         if (response) {
  //           if (response?.status === 400) {
  //             form.setFieldsValue({ city: "", state: "" });
  //             setFormValues((prevValues: IPersonalInfoFormValues) => ({
  //               ...prevValues,
  //               city: "",
  //               state: null,
  //             }));
  //           } else {
  //             const { city, state } = response.successResult;
  //             form.setFieldsValue({ city, state });
  //             setFormValues((prevValues: IPersonalInfoFormValues) => ({
  //               ...prevValues,
  //               city,
  //               state,
  //             }));
  //           }
  //         }
  //       })
  //       .catch((error: any) => {
  //         console.log(error);
  //         form.setFieldsValue({ city: "", state: "" });
  //         setFormValues((prevValues: IPersonalInfoFormValues) => ({
  //           ...prevValues,
  //           city: "",
  //           state: null,
  //         }));
  //       });
  //   }
  // }, [formValues.zip]);

  const handleKeyPress = (e: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) => {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault();
    }
  };

  const handleChange = (event: any) => {
    let inputValue = event.target.value;
    let newArray = inputArray;
    const lastChar = inputValue[inputValue.length - 1];
    inputArray.push(lastChar);
    setInputArray(newArray);
    const returnValue = formattedSSNValue(newArray.join(""));
    setFormattedSSN(returnValue);
    setOriginalSSN(newArray.join(""));
  };

  const handleFieldsError = () => {
    const resultArray = FormErrorHandlingFunction(form);
    setErrorField(resultArray);
  };

  return (
    <>
      <Content className="main-container layout">
        <h1 className="title">Please review</h1>

        <Form
          form={form}
          onFinish={onFinish}
          className="personalinfo-form"
          initialValues={formValues}
          onFieldsChange={handleFieldsError}
          scrollToFirstError={true}
        >
          <Flex vertical style={{ marginBottom: "52px" }}>
            <Flex justify="space-between" gap="20px">
              <Form.Item
                name="firstname"
                rules={[
                  { required: true, message: "First Name is required" },
                  {
                    // validator: validateFieldName("First Name"),
                  },
                ]}
                style={{ flex: 1 }}
              >
                <div
                  className={`info_borderText application-inputBox ${
                    errorField.length > 0 && errorField.includes("firstname")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="First Name"
                    bordered={false}
                    onChange={(e) => onChange("firstname", e.target.value)}
                    value={formValues.firstname}
                    onKeyDown={(e) => handleKeyPress(e)}
                    required
                  />
                  <span className="info_span placeholder">First Name</span>
                </div>
              </Form.Item>
              <Form.Item
                name="middlename"
                rules={[
                  {
                    // validator: validateFieldName("Middle Name"),
                  },
                ]}
                style={{ flex: 1 }}
              >
                <div
                  className={`info_borderText application-inputBox ${
                    errorField.length > 0 && errorField.includes("middlename")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="-"
                    bordered={false}
                    onChange={(e) => onChange("middlename", e.target.value)}
                    value={formValues.middlename}
                    onKeyDown={(e) => handleKeyPress(e)}
                    required
                  />
                  <span className="info_span placeholder">Middle Name</span>
                </div>
              </Form.Item>
            </Flex>

            <Flex justify="space-between" gap="20px">
              <Form.Item
                name="lastname"
                rules={[
                  { required: true, message: "Last Name is required" },
                  {
                    // validator: validateFieldName("Last Name"),
                  },
                ]}
                style={{ flex: 1 }}
              >
                <div
                  className={`info_borderText application-inputBox ${
                    errorField.length > 0 && errorField.includes("lastname")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Last Name"
                    bordered={false}
                    onChange={(e) => onChange("lastname", e.target.value)}
                    value={formValues.lastname}
                    onKeyDown={(e) => handleKeyPress(e)}
                    required
                  />
                  <span className="info_span placeholder">Last Name</span>
                </div>
              </Form.Item>

              <Form.Item
                name="dateofbirth"
                rules={[
                  { required: true, message: "Date of Birth is required" },
                ]}
                style={{ flex: 1 }}
              >
                <div
                  className={`info_borderText application-inputBox dateofbirthInput ${
                    errorField.length > 0 && errorField.includes("dateofbirth")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  {/* <span className="info_span ">Date of Birth</span> */}
                  <Space
                    direction="vertical"
                    className="fullWidth info_inputTextClass"
                  >
                    <DatePicker
                      disabledDate={disabledDateRange}
                      format={dateFormat}
                      onChange={(value) => onChange("dateofbirth", value)}
                      bordered={false}
                      value={formValues.dateofbirth}
                      placeholder="Date of Birth"
                    />
                  </Space>
                </div>
              </Form.Item>
            </Flex>

            <Flex>
              <Form.Item
                name="SSN"
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    validator: (_, value) => {
                      const formattedValue = originalSSN;
                      const isValidLength = formattedValue.length === 9;
                      const containsOnlyDigits = /^\d+$/.test(originalSSN);
                      if (isValidLength && containsOnlyDigits) {
                        return Promise.resolve();
                      } else if (formattedValue.length && !isValidLength) {
                        return Promise.reject("Invalid SSN");
                      } else if (originalSSN.length && !containsOnlyDigits) {
                        return Promise.reject("Invalid SSN");
                      } else {
                        return Promise.reject("The SSN must be 9 digits long");
                      }
                    },
                  },
                ]}
              >
                <div
                  className={`info_borderText application-inputBox ${
                    errorField.length > 0 && errorField.includes("SSN")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  <Input
                    className="info_inputTextClass"
                    bordered={false}
                    placeholder="SSN"
                    onChange={handleChange}
                    value={formattedSSN}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === 8 || e.keyCode === 46) {
                        setInputArray([]);
                        setOriginalSSN("");
                        setFormattedSSN("");
                      }
                    }}
                    maxLength={11}
                    required
                  />
                  <span className="info_span placeholder">SSN</span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>

          <Flex vertical style={{ marginBottom: "52px" }}>
            <Flex gap='20px'>
              <Form.Item name="phone">
                <div
                  className={`info_borderText application-inputBox ${
                    errorField.length > 0 && errorField.includes("phone")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  <Input
                    className="info_inputTextClass"
                    bordered={false}
                    placeholder="Phone"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => onChange("phone", e.target.value)}
                    value={formValues.phone}
                    maxLength={14}
                    required
                  />
                  <span className="info_span placeholder">Phone</span>
                </div>
              </Form.Item>

              <Form.Item name="email" rules={[{ validator: validateEmail }]}>
                <div
                  className={`info_borderText application-inputBox ${
                    errorField.length > 0 && errorField.includes("email")
                      ? "errorDiv"
                      : ""
                  }`}
                >
                  <Input
                    className="info_inputTextClass"
                    bordered={false}
                    placeholder="Email"
                    onChange={(e) => onChange("email", e.target.value)}
                    value={formValues.email}
                    required
                  />
                  <span className="info_span placeholder">Email</span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>

          <Flex>
            <Form.Item
              name="streetaddress1"
              rules={[
                { required: true, message: "Street Address 1 is required" },
              ]}
            >
              <div
                className={`info_borderText application-inputBox ${
                  errorField.length > 0 && errorField.includes("streetaddress1")
                    ? "errorDiv"
                    : ""
                }`}
              >
                <Input
                  className="info_inputTextClass"
                  bordered={false}
                  placeholder="Street Address 1"
                  onChange={(e) => onChange("streetaddress1", e.target.value)}
                  value={formValues.streetaddress1}
                  required
                />
                <span className="info_span placeholder">Street Address 1</span>
              </div>
            </Form.Item>
          </Flex>

          <Flex>
            <Form.Item name="streetaddress2">
              <div
                className={`info_borderText application-inputBox ${
                  errorField.length > 0 && errorField.includes("streetaddress2")
                    ? "errorDiv"
                    : ""
                }`}
              >
                <Input
                  className="info_inputTextClass"
                  bordered={false}
                  placeholder="Street Address 2"
                  value={formValues.streetaddress2}
                  required
                />
                <span className="info_span placeholder">Street Address 2</span>
              </div>
            </Form.Item>
          </Flex>

          <Flex vertical style={{ marginBottom: "52px" }}>
            <Flex gap="20px">
              <Flex style={{ width: "50%" }}>
                <Form.Item
                  name="city"
                  rules={[
                    { required: true, message: "City is required" },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Invalid City name",
                    },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("city")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="City"
                      onChange={(e) => onChange("city", e.target.value)}
                      value={formValues.city}
                      required
                    />
                    <span className="info_span placeholder">City</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex gap="20px" style={{ width: "50%" }}>
                <Form.Item
                  name="state"
                  rules={[{ required: true, message: "State is required" }]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("state")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    {/* <span className="info_span">State</span> */}
                    <Space
                      wrap
                      className="fullWidth info_inputTextClass select-input-padding select-dropdown"
                    >
                      <Select
                        size={size}
                        bordered={false}
                        onChange={(value) => onChange("state", value)}
                        placeholder="Select State"
                        options={
                          data?.states.length > 0
                            ? data.states.map((state) => ({
                                value: state.name,
                                label: state.name,
                              }))
                            : []
                        }
                        value={formValues.state}
                      />
                    </Space>
                  </div>
                </Form.Item>

                <Form.Item
                  name="zip"
                  rules={[
                    { required: true, message: "ZIP code is required" },
                    {
                      pattern: /^\d{5}$/,
                      message: "Invalid ZIP code",
                    },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("zip")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="ZIP"
                      onChange={(e) => onChange("zip", e.target.value)}
                      value={formValues.zip}
                      onKeyDown={handleNumberKeyDown}
                      maxLength={5}
                      required
                    />
                    <span className="info_span placeholder">Zip</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>
          </Flex>

          <Flex>
            <Form.Item name="streetaddress2">
              <div className={`info_borderText application-inputBox`}>
                <Input
                  className="info_inputTextClass"
                  bordered={false}
                  placeholder="Employer"
                  required
                  value={formValues.employer}
                />
                <span className="info_span placeholder">Employer</span>
              </div>
            </Form.Item>
          </Flex>

          <Flex style={{ marginBottom: "100px" }}>
            <Form.Item name="streetaddress2">
              <div className={`info_borderText application-inputBox`}>
                <Input
                  className="info_inputTextClass"
                  bordered={false}
                  placeholder="Job title"
                  value={formValues.jobtitle}
                  required
                />
                <span className="info_span placeholder">
                  Job title / Position with the company you are applying for
                </span>
              </div>
            </Form.Item>
          </Flex>
        </Form>
      </Content>
      <FooterMain
        name="Personal info review"
        nextPath="businessinfo"
        currentStep={4}
        totalSteps={8}
        isProgressBarEnabled={true}
      />
    </>
  );
};

export default PersonalInfo;
