import { Row, Col, } from "antd";
import "./ResponsivePrivacyPolicyFooter.scss";
import { useWindowSize } from "react-use";
import { Footer } from "antd/lib/layout/layout";

const ResponsivePrivacyPolicyFooter = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { width } = useWindowSize();


  return (
    <Footer className="responsivepolicyfooter">
      <Row>
        <Col flex="auto"></Col>
        <Col
          flex={
            width > 640 && width < 1025
              ? "0px"
              : width >= 1025 && width <= 1500
              ? "10%"
              : "190px"
          }
        ></Col>
        <Col
          flex={
            width > 640 && width < 1025
              ? "87%"
              : width >= 1025 && width <= 1500
              ? "60%"
              : "671px"
          }
        >
          <div>
            <div className="privacypolicyfooter">
              <div className="responsiveloquattext">Loquat Inc. © {year}</div>

              <div className="responsiveprivacypolicytext"> Privacy Policy</div>
            </div>
          </div>
        </Col>
        <Col
          flex={
            width > 640 && width < 1025
              ? "3%"
              : width >= 1025 && width <= 1500
              ? "20%"
              : "238px"
          }
        ></Col>

        <Col flex="auto"></Col>
      </Row>
    </Footer>
  );
};

export default ResponsivePrivacyPolicyFooter;



// import React from "react";
// import "../StartPage/StartPage.scss";
// import { Layout } from "antd";

// const { Footer } = Layout;

// const PrivacyPolicy = () => {
//   const date=new Date();
//   const year =date.getFullYear();
//   return (
//     <Footer className="footer">
//     <div className="button-container">
//       <div className="button-group">
//         <span className="float-left left-footer-text">Loquat Inc. © {year} </span>
//         <span className="float-right right-footer-text ">
//           {/* <span className="margin-right"> Privacy Policy</span> */}
//           <span className=""> Privacy Policy</span>
//         </span>
//       </div>
//     </div>
//   </Footer>
//   );
// };

// export default PrivacyPolicy;

