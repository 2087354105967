import React, { useContext, useState, useEffect } from "react";
import "./FrontDocument.scss";
import { Layout } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import { applicationMessages } from "../../Utility/ApplicationMessages";
import AcuantCamera from "../../Acuant/AcuantCamera";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import {
  getTimerPromise,
  showNotification,
  getCurrentRoutePath,
} from "../../Utility/Utility";
import CameraPermission from "../../Common/CameraPermission/CameraPermission";
import { getValueFromStorage } from "../../Utility/Utility";
import { IKYCFrontData } from "../../../../Web/Contracts/Contracts";

import { RouteList } from "../../../../Redux/Selector";
import { useSelector } from "react-redux";

const FrontDocument: React.FC = () => {
  const navigate = useNavigate();
  const { getLoquatClient } = useContext(ApplicationContext);
  const [isCameraPermission, setCameraPermission] = useState<boolean | null>(
    null
  );
  const [cardType, setcardType] = useState<number | null>(null);
  const location = useLocation();
  const routePaths = useSelector(RouteList);
  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  const isFinalStep = getValueFromStorage("IsFinalStepNavigation");

  const selectedImage = (image: any, callBack: any) => {
    const kycFrontData: IKYCFrontData = {
      base64: image,
      documentTypeId: cardType?.toString(),
    };

    var service = getLoquatClient().kycService();
    service
      .postKYCFronDocUpload(kycFrontData)
      .then((response) => {
        // showNotification(applicationMessages.kyc.front);
        getTimerPromise(() => {
          if (cardType === 2) {
            if (isFinalStep && isFinalStep === "true") {
              navigate("/verifying");
            } else {
              navigate("/uploadmobileselfie", {
                state: {
                  cardType: cardType,
                },
              });
            }
          } else {
            navigate(CurrentRoutePath?.Next);
          }
        });
      })
      .catch(async (error) => {
        showNotification(applicationMessages.errorFileUpload, "", true);
        callBack();
      });
  };

  const handleToggle = (value: any) => {
    setCameraPermission(value);
  };

  return (
    <Layout className="frontdoc-layout">
      {isCameraPermission === true ? (
        <AcuantCamera
          selectedImage={selectedImage}
          title="front"
          setcardType={setcardType}
        />
      ) : (
        <CameraPermission
          cameraPermission={isCameraPermission}
          onToggle={handleToggle}
        />
      )}
    </Layout>
  );
};

export default FrontDocument;
