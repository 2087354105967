import React, { useState } from "react";
import "./UnderstandingBusinessConnected.scss";
import { Layout, Row, Col, Flex } from "antd";
import connected from "../../Assets/Images/connected.png";
import check from "../../Assets/Images/check.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import FooterMain from "../Footer/FooterMain";
import { useNavigate } from "react-router-dom";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


const { Content } = Layout;

const UnderstandingBusinessConnected: React.FC = () => {

  interface IWelinkStatus {
    isKycCompleted: boolean;
    isWebLinkAccessed: boolean;
  }

  const initialValues: IWelinkStatus = {
    isKycCompleted: false,
    isWebLinkAccessed: false,
  };

  const [loadingStates, setLoadingStates] =
    useState<IWelinkStatus>(initialValues);
  const navigate = useNavigate();

  const handleClick = () => {

    if (!loadingStates.isWebLinkAccessed) {
      setLoadingStates((prev) => ({ ...prev, isWebLinkAccessed: true }))
    } else if (!loadingStates.isKycCompleted) {
      setLoadingStates((prev) => ({ ...prev, isKycCompleted: true }))
    } else {
      navigate("/understandingbusinessthankyou");
    }


  }

  return (
    <>
      <Content className="content-UnderstandingBusinessConnected" onClick={handleClick}>
        <h1 className="getting-to-know-title">Understanding your business</h1>
        <Flex justify='center'>

          <div className="getting_left">
            <img className="connectedimg" src={connected} alt="Logo" />
          </div>
          <div className="getting_right">
            <h3 className="Identification_heading">
            Please keep this window open while using your mobile device
            </h3>
          
            <div className="listtick">
              <div className="verifying-details-content">
                <span className="vefifypara">
                  {!loadingStates.isWebLinkAccessed ? (
                    <Spin indicator={antIcon} className="custom-spin" />
                  ) : (
                    <img src={check} alt="check-icon" />
                  )}
                </span>
                <span className="tick_para">
                  {!loadingStates.isWebLinkAccessed
                    ? "Connecting "
                    : "Connected "}
                  to your phone
                </span>
              </div>
              <div className="verifying-details-content">
                <span className="vefifypara">
                  {!loadingStates.isKycCompleted ? (
                    <Spin indicator={antIcon} className="custom-spin" />
                  ) : (
                    <img src={check} alt="check-icon" />
                  )}
                </span>
                <span className="tick_para">
                  {!loadingStates.isKycCompleted
                    ? "Verifying "
                    : "Verified "}{" "}
                  your information
                </span>
              </div>
            </div>

          </div>
        </Flex>
      </Content>
      <FooterMain nextPath="understandingbusinessthankyou" isConfirmBtnDisabled={true} currentStep={5} totalSteps={8} isProgressBarEnabled={true} />

    </>
  );
};

export default UnderstandingBusinessConnected;
