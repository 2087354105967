import { useEffect, useRef, useState, useContext } from "react";
import { Modal, Button, Row, Col } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import "./IdleTimerPopup.scss";

import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import {
  clearStorage,
  getValueFromStorage,
  showNotification,
} from "../../Utility/Utility";
import { applicationMessages } from "../../Utility/ApplicationMessages";

const IdleTimerPopup = () => {
  const { getLoquatClient } = useContext(ApplicationContext);
  const accessToken = getValueFromStorage("AccessToken");

  const navigate = useNavigate();
  const location = useLocation();

  let timer = useRef<NodeJS.Timeout>();
  let timerIdPopUp: NodeJS.Timeout;

  const [showPopup, setShowPopup] = useState(false);
  const [initialCountdown, setInitialCountdown] = useState<{
    userInactiveLimitInSeconds: number;
    userInactivePopupTimeInSeconds: number;
  }>();

  useEffect(() => {
    if (
      location.pathname === "/letsstartphone" ||
      location.pathname === "/letsstartsms" ||
      location.pathname === "/"
    ) {
      return;
    }

    if (
      (!initialCountdown?.userInactiveLimitInSeconds ||
        !initialCountdown?.userInactivePopupTimeInSeconds) &&
      accessToken
    ) {
      var service = getLoquatClient().masterDataService();
      const countryCode = getValueFromStorage("CountryCode");
      service
        .getCountryConfigurations(countryCode)
        .then((response) => {
          if (response?.status === 400) {
            showNotification("", applicationMessages.pleaseTryAgainMsg, true);
          } else {
            setInitialCountdown({
              userInactiveLimitInSeconds:
                response?.successResult?.userInactiveLimitInSeconds || 0,
              userInactivePopupTimeInSeconds:
                response?.successResult?.userInactivePopupTimeInSeconds || 0,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        });
    }

    if (initialCountdown?.userInactiveLimitInSeconds) {
      resetTimer();
    }

    if (showPopup && initialCountdown?.userInactivePopupTimeInSeconds) {
      clearTimeout(timerIdPopUp);
      timerIdPopUp = setTimeout(() => {
        handleLogout();
      }, initialCountdown?.userInactivePopupTimeInSeconds * 1000);
    }

    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      clearTimeout(timer.current!);
    };
  }, [location.pathname, showPopup, initialCountdown]);

  const handleUserActivity = () => {
    if (!showPopup) {
      resetTimer();
    }
  };

  const resetTimer = () => {
    clearTimeout(timer.current);
    clearTimeout(timerIdPopUp);
    if (initialCountdown?.userInactiveLimitInSeconds) {
      const timerDuration = initialCountdown?.userInactiveLimitInSeconds * 1000;
      timer.current = setTimeout(() => {
        clearTimeout(timer.current);
        setShowPopup(true);
      }, timerDuration);
    }
  };

  const handleLogout = () => {
    clearTimeout(timer.current);
    clearTimeout(timerIdPopUp);
    setShowPopup(false);
    clearStorage();
    navigate("/letsstartphone");
  };

  const handleContinueClick = () => {
    setShowPopup(false);
    resetTimer();
  };

  return (
    <Modal
      centered
      open={showPopup}
      width={900}
      maskClosable={false}
      closeIcon={true}
      className="idleTimerPopup"
      onCancel={handleContinueClick}
      footer={[
        <Row className="idleTimerPopupButtons">
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
          >
            <Button
              className="idleTimer-button idleTimerlogout-button"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            className="right-aligned-button"
          >
            <Button
              className="idleTimer-button idleTimerContinue-button"
              onClick={handleContinueClick}
            >
              Continue
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <div className="idleTimerContainer">
        <div className="idleTimer-Content">
          <div className="idleTimer-Header">
            Your session is about to expire
          </div>
          <div className="idleTimer-text">
            Please click continue to remain logged in
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IdleTimerPopup;
