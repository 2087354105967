import { IVirtualAccountValue} from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class VirtualAccountService
  extends ServiceBase
  implements IVirtualAccountService
{
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async getAddCard(body: IVirtualAccountValue) {
    let url = ApiEndpoints.VirtualAccount.getAddCard();
    const response: any = await this.doPost(url, body);
    return response;
  }

}

export interface IVirtualAccountService {
    getAddCard(body: IVirtualAccountValue): Promise<any>;
}
