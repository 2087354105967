import React, { useState } from "react";
import logo from "../../../Assets/Images/Dashboard/logo.png";
import signoutpic from "../../../Assets/Images/Dashboard/defaultProfileImg.png";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../Utility/Utility";
import { Modal, Button, Row, Col } from "antd";
import { handleImages } from "../../Utility/Utility";

const DashboardHeader: React.FC<any> = ({ userProfileDetails }) => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const handleSignOut = () => {
    setShowPopup(true);
  };

  const confirmSignOut = () => {
    clearStorage();
    navigate("/letsstartphone");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  return (
    <div className="dashboard_header">
      <div className="dashboard_header_left">
        <img className="logo_image" src={logo} alt="Logo" />
      </div>
      <div className="dashboard_header_right">
        {userProfileDetails?.profileImage ? (
          <img
            className="profileImg"
            src={handleImages(userProfileDetails?.profileImage)}
            alt="Logo"
          />
        ) : (
          <img className="profileImg" src={signoutpic} alt="Logo" />
        )}

        <span className="signout" onClick={handleSignOut}>
          Sign out
        </span>
      </div>
      <Modal
        centered
        open={showPopup}
        width={900}
        maskClosable={false}
        closeIcon={true}
        className="NotificationPopup"
        onCancel={handleCancel}
        footer={[
          <Row className="idleTimerPopupButtons">
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Button
                className="popup-button idleTimerlogout-button"
                onClick={handleCancel}
              >
                No
              </Button>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="right-aligned-button"
            >
              <Button
                className="popup-button idleTimerContinue-button"
                onClick={confirmSignOut}
              >
                Yes
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <div className="popup-container">
          <div className="popup-content">
            <div className="idleTimer-Header">
              Are you sure you want to sign out?
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardHeader;
