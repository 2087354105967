import React from "react";
import { Layout } from "antd";
import call from "../../Assets/Images/call.png";
import image from "../../Assets/Images/headerImages/finalsupport.png";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./FinalSupport.scss";

import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import { IStepsHeader } from "../../Contracts/Contracts";

const {  Content } = Layout;
const headerValues: IStepsHeader = {
  title: "Support",
  titleClass: "letsheadingfinalsupport",
  img:image
}
const FinalSupport = () => {
  return (
    <Layout className="confirm-layout finalsupportscreen">
      <StepsHeader  {...headerValues} />
      <Content className="finalsupport-content">
      <div className="support-container">
          <div className="support-group">
            {/* <span className="closeicon">
            <img className="closecorner" src={close} alt="callicon" /> 
            </span> */}
            {/* <h3 className="suppport_heading">Please contact our <br/>Customer support to provide <br/>further information</h3> */}
           <p className="support_para">Support 24/7</p>
           <div className="callsupport">
             <span className="callmetext">Call me</span>
             <span className="callmeimg"><img className="supportcornerimg" src={call} alt="callicon" />   </span>
            </div>
            <div className="call_supportus">
              <span className="callus">Call us</span>
              <span className="call_num">(234) 567-8900</span>
            </div>
            <div className="call_supportus">
              <span className="callus">Write us</span>
              <span className="call_num">help@loquatinc.io</span>
            </div>
          </div>
        </div>
      </Content>
      <PrivacyPolicy />
      <StpesFooter nextPath="/gettingthankyou"/>

    </Layout>
  );
};

export default FinalSupport;
