import React from "react";
import "./MobileFooter.scss";
import { Layout, Row, Col, Button } from "antd";

const { Footer } = Layout;

type IMobileFooterprops = {
  onClick?: () => void;
  buttonName?: string;
};

const MobileFooter = (props: IMobileFooterprops) => {
  const date=new Date();
  const year =date.getFullYear();
  const onButtonClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <Footer className="mobile-footer">
      {props.buttonName && (
        <div className="mobileotp-button-container">
          <Button className="mobile-next-button" block onClick={onButtonClick}>
            {props.buttonName}
          </Button>
        </div>
      )}
      <Row align="middle" justify="space-between">
        <Col className="colored-text">Loquat Inc. © {year}</Col>
        {/* <Col className="link-text">Privacy Policy</Col> */}
        <Col className="link-text"> Privacy Policy</Col>
      </Row>
    </Footer>
  );
};

export default MobileFooter;
