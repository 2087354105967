import { Modal, Button, Row, Col } from "antd";
import "./NotificationPopup.scss";
import { useNavigate } from "react-router-dom";

type NotificationPopupProps = {
  showPopup: boolean;
  setShowPopup: (value: boolean) => void;
  headerText: string;
  descriptionText?: string;
  buttonCount: number;
  handleClick?: () => void;
  onClickNavigation?: boolean;
  popupContentCSSChange?: boolean;
};
const NotificationPopup = (props: NotificationPopupProps) => {
  const handleCancel = () => {
    props.setShowPopup(false);
  };

  const handleOkClick = () => {
    if (props.onClickNavigation) {
      if (props.handleClick) {
        props.setShowPopup(false);
        props.handleClick();
      }
    } else {
      props.setShowPopup(false);
    }
  };

  return (
    <Modal
      centered
      open={props.showPopup}
      width={900}
      maskClosable={false}
      closeIcon={true}
      className="NotificationPopup"
      onCancel={handleCancel}
      footer={
        props.buttonCount === 1
          ? [
              <Row>
                <Col span={24}>
                  <Button
                    className="single-popup-button"
                    onClick={handleOkClick}
                  >
                    OK
                  </Button>
                </Col>
              </Row>,
            ]
          : [
              <Row className="NotificationPopupButtons">
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                >
                  <Button className="popup-button idleTimerlogout-button">
                    Logout
                  </Button>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  className="right-aligned-button"
                >
                  <Button className="popup-button idleTimerContinue-button">
                    Continue
                  </Button>
                </Col>
              </Row>,
            ]
      }
    >
      <div className="popup-container">
        <div
          className={
            props.popupContentCSSChange
              ? "sentcodepopup-content"
              : "popup-content"
          }
        >
          <div className="idleTimer-Header">{props.headerText}</div>
          {props.descriptionText && props.descriptionText?.length > 0 && (
            <div className="description-text">{props.descriptionText}</div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NotificationPopup;
