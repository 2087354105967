import React, { useState } from "react";
import left_logo from "../../Assets/Images/left_logo.png";
import "./NoStepsHeader.scss";
import { INoStepsHeader } from "../../Contracts/NoStepheader";
import DeleteUserPopup from "../StartPage/DeleteUserPopup/DeleteUserPopup";

const NoStepsHeader: React.FC<INoStepsHeader> = (
  titleProps: INoStepsHeader
) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const leftimgClassName =
    titleProps.phoneNumberScreen && titleProps.phoneNumber?.length === 14
      ? "leftimg cursor"
      : "leftimg";

  const handleTripleClick = () => {
    if (titleProps.phoneNumberScreen && titleProps.phoneNumber?.length === 14) {
      setClickCount(clickCount + 1);
      if (clickCount === 2) {
        setIsDeleteModalVisible(true);
        setClickCount(0);
      }
    }
  };

  return (
    <div className="no-steps-header">
      <div className="header_position">
        <span className={leftimgClassName} onClick={handleTripleClick}>
          <img className="leftcornerimg" src={left_logo} alt="Logo" />
        </span>
        <div
          // className="Noletsheading"
          className={
            titleProps.img ? "Noletsheading" : "Noletsheading textheading"
          }
        >
          {titleProps.img ? (
            <img
              className="img-responsive marginHeader"
              src={titleProps.img}
              alt="step-header"
            />
          ) : (
            <h4 className=" img-responsive marginHeader welcomebackTitle">
              Welcome back
            </h4>
          )}
        </div>
      </div>
      {isDeleteModalVisible && (
        <DeleteUserPopup
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          phoneNumber={titleProps.phoneNumber}
        />
      )}
    </div>
  );
};

export default NoStepsHeader;
