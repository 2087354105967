import React, { useEffect, useState } from "react";
import { BeatLoader, RingLoader } from "react-spinners";
import "./Loader.scss";

const Loader = (props: {
  key?: number;
  checkLoading?: any;
  color?: string;
  size?: number;
  inline?: boolean;
  class?:string;
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(props.checkLoading);
    // const timeout = setTimeout(() => {
    //   setLoading(props.checkLoading);
    // }, 10);

    // return () => clearTimeout(timeout);
  }, []);

  return !props.inline ? (
    <div className="progress-overlay">
    <RingLoader
      key={props.key}
      loading={loading}
      color={"#0A3E40"}
      size={props.size}
    />
    </div>
  ) : (
    <BeatLoader
      key={props.key}
      loading={loading}
      color={props.color}
      size={props.size}
      className={props.class}
    />
  );
};

export default Loader;
