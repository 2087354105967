import React from "react";
import { Layout, Row, Col ,Button} from "antd";
import logo from "../../../Assets/Images/mobilelogo.png";
import closeIcon from "../../../Assets/Images/closeIcon.png";
import "./MobileHeader.scss"

const { Header } = Layout;

const MobileHeader = () => {
  return (
    <Header className="mobile-header">
    <Row
      justify="space-between"
      align="middle"
      className="mobile-header-content"
    >
      <Col>
        <img src={logo} className="mobile-header-image" alt="Logo" />
      </Col>
      <Col>
        {/* <Button className="mobile-close-icon">
          <img src={closeIcon} alt="close"/>
        </Button> */}
      </Col>
    </Row>
  </Header>
  );
};

export default MobileHeader;
