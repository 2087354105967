import React, { useEffect, useContext } from "react";
import { Layout } from "antd";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import "./BeneficialThanks.scss";
import StepsHeader from "../Header/StepsHeader";
import { IStepsHeader } from "../../Contracts/Contracts";
import BeneficialThanksImage from "../../Assets/Images/BeneficialThanksImage.png";
import { useNavigate } from "react-router-dom";
import image from "../../Assets/Images/headerImages/beneficialThanks.png";
import { getValueFromStorage } from "../Utility/Utility";

const { Content } = Layout;
const headerValues: IStepsHeader = {
  title: "Please Confirm",
  titleClass: "letsheadingh5",
  img: image,
};
const BeneficialThanks = () => {
  const userType = getValueFromStorage("UserType");
  const navigate = useNavigate();
  const businessName = getValueFromStorage("businessName");
  const { getLoquatClient } = useContext(ApplicationContext);

  useEffect(() => {
    const fetchSecondaryUserStatus = () => {
      const service = getLoquatClient().profileService();
      service.getUserApplicationStatus().then((response: any) => {
        if (response) {
          const res = response?.successResult;
          if (
            res.business[0].userTypeId != null &&
            res.business[0].userTypeId === "1"
          ) {
            if (
              res.business[0].isBusinessActivated === true &&
              res.business[0].isApplicationCompleted !== true
            ) {
              navigate("/finalstep");
            }
          }
        }
      });
    };

    // // Initial API call
    // fetchSecondaryUserStatus();

    // Set up a timer to make the API call every 30 seconds (30000 milliseconds)
    const timerId = setInterval(fetchSecondaryUserStatus, 30000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="thanks-confirm-layout">
      <div className="headerBgwithStep">
        <StepsHeader {...headerValues} />
      </div>
      <Content className="thanks-confirm-content">
        {userType?.toLowerCase() !== "secondary" ? (
          <div>
            <p className="thanks-confirm-text">
              Other beneficial owners have been invited to XYZ, and we are
              waiting for them to confirm their identity.
            </p>
            <p className="thanks-confirm-text">
              Once confirmed, we will notify you via Email.
            </p>
          </div>
        ) : (
          <p className="thanks-confirm-text">
            Your beneficial ownership has been successfully verified.
          </p>
        )}
        <div className="wait-image-container">
          <img
            src={BeneficialThanksImage}
            alt="waitlogo"
            className="img-responsive beneficialImage"
          />
          {/* <span className="timer-content">{formatTime(timeLeft)} </span> */}
        </div>
      </Content>
      <PrivacyPolicy />
    </Layout>
  );
};

export default BeneficialThanks;
