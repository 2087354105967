import React, { useState } from "react";
import { Layout, Row, Col, Button } from "antd";
import logo from "../../../Assets/Images/LoquatLogo.png";
import goback from "../../../Assets/Images/goback.png";
import chatIcon from "../../../Assets/Images/MobileChatIcon.png";
import "./UploadScreenHeader.scss";
import FinalSupportPopup from "../../FinalSupportPopup/FinalSupportPopup";
import { IUploadScreenHeaderProps } from "../../../Contracts/Contracts";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

const UploadScreenHeader: React.FC<IUploadScreenHeaderProps> = (
  props: IUploadScreenHeaderProps
) => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const handleSupportPopup = () => {
    setShowPopup(true);
  };

  const handleGoBack = () => {
    if (props?.gobackpath) {
      const cardtype = props?.cardType ? props?.cardType.toString() : undefined;
      const gobackpath =
        cardtype === "2" ? "/uploadfrontdoc" : props?.gobackpath;
      navigate(gobackpath);
    }
  };
  return (
    <Header className="upload-screen-header">
      <Row
        justify="space-between"
        align="middle"
        className="mobile-header-content"
      >
        <Col>
          <img
            className="goback_img"
            src={goback}
            alt="Logo"
            onClick={handleGoBack}
          />
        </Col>
        <Col className="icons-container">
          <img className="logoImg" src={logo} alt="logo" />
          <img
            className="chatIcon"
            src={chatIcon}
            alt="chatlogo"
            onClick={handleSupportPopup}
          />
        </Col>
      </Row>
      <FinalSupportPopup showPopup={showPopup} setShowPopup={setShowPopup} />
    </Header>
  );
};

export default UploadScreenHeader;
