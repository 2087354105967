import { combineReducers } from 'redux';
import userApplicationStatusReducer from './UserApplicationStatus';
import RoutesReducer from './Routes';
import IdleTimer from './IdleTimer';

const rootReducer = combineReducers({
    userApplicationStatus: userApplicationStatusReducer,
    Routes: RoutesReducer,
    IdleTimer: IdleTimer,
    // Add more reducers here if needed
});

export default rootReducer;