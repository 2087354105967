import React from "react";
import "../StartPage/StartPage.scss";
import { Layout } from "antd";

const { Footer } = Layout;

const PrivacyPolicy = () => {
  const date=new Date();
  const year =date.getFullYear();
  return (
    <Footer className="footer">
    <div className="button-container">
      <div className="button-group">
        <span className="float-left left-footer-text">Loquat Inc. © {year} </span>
        <span className="float-right right-footer-text ">
          {/* <span className="margin-right"> Privacy Policy</span> */}
          <span className=""> Privacy Policy</span>
        </span>
      </div>
    </div>
  </Footer>
  );
};

export default PrivacyPolicy;
