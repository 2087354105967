import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ApplicationContextProvider } from "./Web/Context/ApplicationContextProvider";
import AppRoute from "./Web/Route/AppRoute";
import { IDynamicConfig, DEFAULT_CONFIG } from "./Web/Configuration/Config";
import "./Web/Assets/Scss/base.scss";
import IdleTimerPopup from "./Web/Components/Common/IdleTimerPopup/IdleTimerPopup";

function App() {
  const [config, setConfig] = useState<IDynamicConfig>(DEFAULT_CONFIG);

  return (
    <>
      <div>
        <ApplicationContextProvider config={config}>
          <BrowserRouter>
            <IdleTimerPopup />
            <AppRoute online={true} />
          </BrowserRouter>
        </ApplicationContextProvider>
      </div>
    </>
  );
}

export default App;
