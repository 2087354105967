import React, { useContext, useEffect } from "react";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { Layout } from "antd";
import MobileFooter from "../MobileFooter/MobileFooter";
import MobileHeader from "../MobileHeader/MobileHeader";
import "./SuccessfulDataSubmit.scss";
import DoneImg from "../../../Assets/Images/Done.png";
import { IUpdateWebLinkStatusDetails } from "../../../Contracts/Contracts";
import { getValueFromStorage } from "../../Utility/Utility";

const { Content } = Layout;

const SuccessfulDataSubmit = () => {
  const weblink = getValueFromStorage("WebLinkId");
  const { getLoquatClient } = useContext(ApplicationContext);
  const service = getLoquatClient().notificationService();

  useEffect(() => {
    updateWeblinkStatus(weblink);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWeblinkStatus = (linkId: string | null) => {
    const postdetails: IUpdateWebLinkStatusDetails = {
      WebLinkId: linkId,
      IsKycCompleted: true,
      IsWebLinkAccessed: false,
    };
    service
      .updateWebLinkDetailsStatus(postdetails)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Layout className="mobile-layout">
      <MobileHeader />
      <Content className="submit-screen-content">
        <div className="submit-content-header">Thank you!</div>
        <div className="submit-image-container">
          <img
            src={DoneImg}
            alt="doneImg"
            loading="lazy"
            className="success-image"
          />
        </div>
        <div className="submit-description-text">
          Your verification data has been successfully submitted. You can now
          close this window and return to your computer.
        </div>
      </Content>

      <MobileFooter />
    </Layout>
  );
};

export default SuccessfulDataSubmit;
