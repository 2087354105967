import { IUnderStandingUploadProps,IDocumentStatusProps } from "../../Web/Contracts/Contracts";
import { ApiEndpoints } from "../APIEndPoints";
import { ILoquatServiceClient } from "../LoquatClient";

import { ServiceBase } from "../ServiceBase";

export class KYBService extends ServiceBase implements IKYBService {
  constructor(private client: ILoquatServiceClient) {
    super(client.getAxiosInstance());
  }

  async postUnderstandingUpload(
    body: IUnderStandingUploadProps,
  ) {
    let url = ApiEndpoints.UnderstandingUpload.setUnderstandingUpload();
    const response: any = await this.doPost(url, body);
    return response;
  }

  async postDocumentStatus(
    body: IDocumentStatusProps,
  ) {
    let url = ApiEndpoints.DocumentStatus.setDocumentStatus();
    const response: any = await this.doPost(url, body);
    return response;
  }
}

export interface IKYBService {
  postUnderstandingUpload(
    body: any,
  ): Promise<any>;
  postDocumentStatus(
    body: any,
  ): Promise<any>;
}
