import unauthorized from "../../Assets/Images/unauthorized.svg";
import forbidden from "../../Assets/Images/forbidden.svg";
import notfound from "../../Assets/Images/notfound.svg";
import networkError from "../../Assets/Images/networkError.svg";

export const error = {
  401: {
    title: "Unauthorized Error",
    subTitle: "Your Login session expired. Please click below to login again.",
    image: unauthorized,
  },
  403: {
    title: "Forbidden Error",
    subTitle: "You are not authorized to access this screen",
    image: forbidden,
  },
  404: {
    title: "Sorry! Page not found",
    subTitle: "",
    image: notfound,
  },
  networkError: {
    title: "Network Error",
    subTitle:
      "Slow or no internet connection, Please check your internet settings.",
    image: networkError,
  },
};
