import { Checkbox, Flex, Form, Input, Layout, Radio } from "antd";

import "./BAApplication.scss";

const { Content } = Layout;

const BAApplication = () => {
  return (
    <Content className="ba-app-content">
      <div className="container">
        <h2 className="container-title">
          Business account application and account card
        </h2>

        <Flex vertical className="text-wrapper">
          <p className="separation">
            <span className="title">1. BUSINESS/ACCOUNT OWNER.</span>
            The Business/Account Owner name in this document is the complete and
            correct name of the Business. If applicable, all registered assumed
            names under which the Business does business are noted. Each
            corporate officer, partner, Customer or trustee (as applicable)
            warrants that the Business has been duly formed and currently
            exists. The Business is solely responsible for conducting any
            background check on persons they designate to represent and act on
            their behalf, and they agree to indemnify and hold [Financial
            Institution] harmless from the actions of Business agents and
            representatives.
          </p>

          <p style={{ marginBottom: 0 }}>
            <span className="title">2. AUTHORIZED PARTIES.</span>
            The persons named as Responsible Individuals on this Business
            Account Application and Account Card are authorized to act on behalf
            of the Business with respect to accounts based upon the designated
            authority and Certificate of Authority set forth below.
            <br />
          </p>
          <ol className="separation">
            <li>
              <span className="subtitle">Responsible Individuals </span>
              are vested with full authority to open and close accounts on
              behalf of the Business, add and remove Authorized Signers on
              behalf of the Business and transact any business of any nature on
              such accounts.
            </li>

            <li>
              <span className="subtitle">Authorized Signers</span>
              are vested with limited authority to transact any business on such
              accounts including the following, but may not make changes on or
              to the accounts:
              <ul>
                <li>
                  Depositing, withdrawing and transferring funds into, out of,
                  and between one or more accounts;
                </li>
                <li>
                  Signing drafts, checks and other orders for payment or
                  withdrawal;
                </li>
                <li>
                  Issuing instructions regarding order for payment or
                  withdrawal;
                </li>
                <li>
                   Endorsing any check, draft, share certificate and other
                  instrument or order for payment owned or held by the Business
                </li>
                <li>Initiating ACH Transactions and Wire Transfers ; and</li>
                <li>Receiving information of any nature about the account </li>
              </ul>
            </li>
          </ol>

          <p style={{ marginBottom: 0 }}>
            <span className="title">3. CERTIFICATE OF AUTHORITY.</span>
          </p>
          <ol className="separation">
            <li>
              The Business and each Responsible Individual named on this
              Business Account Application and Account Card certifies and agrees
              that the Business accounts and services will be governed by the
              terms set forth in the Business Customer and Account Agreement and
              Business Account Application and Account Card, and the Rate and
              Fee Schedule as amended from time to time.
            </li>
            <li>
              [Financial Institution] is directed to accept and pay without
              further inquiry, any item bearing the signature as indicated on
              the Business Account Application and Account Card drawn against
              any of the Business accounts. Any one Responsible Individual or
              Authorized Signer is expressly authorized to endorse all items
              payable to or owned by the Business for deposit with or collection
              by [Financial Institution] and to perform any other transaction
              permitted under the Agreement.
            </li>
            <li>
              The authority given to the Authorized Parties named on the
              Business Account Application and Account Card shall remain in full
              force until written notice of revocation or a new Business Account
              Application and Account Card is delivered to and received by
              [Financial Institution]. Any such notice shall not affect any
              items in process at the time notice is given. The Business shall
              notify [Financial Institution] of any change in the Business
              composition, assumed business names, or any aspect of the entity
              affecting the deposit relationship between the Business and
              [Financial Institution].
            </li>
            <li>
              The Business and each Authorized Party signing the Business
              Account Application and Account Card agree to indemnify and hold
              harmless [Financial Institution].
            </li>
            <li>
              [Financial Institution] shall have no duty to inquire as to the
              powers and duties of any Authorized Party and shall have no notice
              of any breach of fiduciary duties by any Authorized Party unless
              [Financial Institution] has actual notice of wrongdoing.
            </li>
          </ol>

          <p className="separation">
            <span className="title">4. LIABILITY.</span>
            The Business agrees that [Financial Institution] shall not be liable
            for any losses due to the Business failure to notify the [Financial
            Institution] of such changes. The Business and each Authorized Party
            signing the Business Account Application and Account Card agree to
            indemnify and hold [Financial Institution] harmless of any claim or
            liability as a result of unauthorized acts of any Authorized Party
            or former Authorized Party upon which [Financial Institution] relies
            prior to notice of any account change or change of Business
          </p>

          <p className="separation">
            <span className="title">
              BACKUP WITHOLDING AND TIN CERTIFICATION:
            </span>
            By signing below, each Business Account Owner certifies, under
            penalties of perjury, that (1) I am a U.S. person (including a U.S.
            resident alien), (2) the Social Security Number(SSN)/Tax Payer
            Identification Number (TIN) shown is my/the correct identification
            number and (3) I am NOT, unless designated below, subject to backup
            withholding because I have not been notified that I am subject to
            backup withholding as a result of a failure to report all dividends
            or interest, or because the IRS has notified me that I am no longer
            subject to backup withholding. This account is maintained in the US;
            therefore, FATCA reporting is not applicable.
          </p>

          <p>
            <span className="title">SIGNATURES.</span>
            By signing below, each Business Account Owner agrees to all terms
            contained in this Business Application and Account Card and agree
            the Business Account will be governed by the terms and conditions
            set forth in the Business Customer and Account Agreement, Funds
            Availability Policy, Privacy Policy, Electronic Funds Transfer
            Agreement and Rate and Fee Schedule and to any amendment the
            [Financial Institution] makes from time to time which are
            incorporated herein. Each Business Account Owner acknowledges
            receipt and copy of the Agreement and Disclosures applicable to the
            accounts and services requested herein
            <b>
              The Internal Revenue Service does not require you to consent to
              any provision of this Business Account Application and Account
              Card other than the certifications required to avoid backup
              withholding.horized acts of any Authorized Party or former
              Authorized Party upon which [Financial Institution] relies prior
              to notice of any account change or change of Business
            </b>
          </p>
        </Flex>

        <Flex vertical className="wrapper sinature-wrapper">
          <Flex className="form" gap="40px" vertical component={"form"}>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Adam Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Adam Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex className="form" gap="40px" vertical component={"form"}>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Jack Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Jack Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex className="form" gap="40px" vertical component={"form"}>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"John Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"John Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
          <Flex className="form" gap="40px" vertical component={"form"}>
            <Flex
              justify="space-between"
              gap="20px"
              align="center"
              className="form-wrrapper"
            >
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Jane Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Jane Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </Content>
  );
};

export default BAApplication;
