import React, { useContext, useState } from "react";
import { Modal, Form, Input, Col, Row, Button } from "antd";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { IDeleteUserValues } from "../../../Contracts/Contracts";
import { formatPhoneNumber, showNotification } from "../../Utility/Utility";
import "./DeleteUserPopup.scss";
import { applicationMessages } from "../../Utility/ApplicationMessages";
import Loader from "../../Common/Loader/Loader";
import NotificationPopup from "../../Common/NotificationPopup/NotificationPopup";

const DeleteUserPopup: React.FC<{
  isDeleteModalVisible: boolean;
  setIsDeleteModalVisible: (value: boolean) => void;
  phoneNumber: any;
}> = ({ isDeleteModalVisible, setIsDeleteModalVisible, phoneNumber }) => {
  const [form] = Form.useForm();
  const { getLoquatClient } = useContext(ApplicationContext);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [onClickNavigation, setOnClickNavigation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteUserFormvalues, setDeleteUserFormValues] =
    useState<IDeleteUserValues>({
      phonenumber: "",
      passcode: "",
    });

  const handleOk = () => {
    setIsDeleteModalVisible(false);
  };

  const handleCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteUser = () => {
    form
      .validateFields()
      .then(() => {
        const passcodeValue = deleteUserFormvalues.passcode;
        if (passcodeValue !== "test@456") {
          form.setFields([
            {
              name: "passcode",
              errors: ["Passcode is incorrect"],
            },
          ]);
          return;
        }
        var service = getLoquatClient().deleteUserService();
        const phone = phoneNumber;
        const numericPhoneNumber = phone.replace(/\D/g, "");
        const payload = {
          PhoneNumber: numericPhoneNumber,
          PassCode: deleteUserFormvalues.passcode,
          // PassCode: "",
          SecurityKey: "",
          Reason: "",
          ConnectionString: "",
          IPAddress: "",
        };
        setLoading(true);
        service
          .postDeleteUser(payload)
          .then((response) => {
            console.log(response);
            if (response?.status === 400) {
              showNotification(
                "",
                applicationMessages?.pleaseTryAgainMsg,
                true
              );
              setLoading(false);
            } else {
              setLoading(false);
              setOnClickNavigation(true);
              setNotificationMessage(
                applicationMessages.deleteUser.deleteUserSuccessMessage
              );
              setShowNotificationPopup(true);
            }
          })
          .catch((error) => {
            showNotification("", applicationMessages.pleaseTryAgainMsg, true);
            setLoading(false);
          });
      })
      .catch((errorInfo) => {
        // console.error("Form validation failed:", errorInfo);
      });
  };

  const handleDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleInputChange = (fieldName: string, value: any) => {
    let updatedValues;
    if (fieldName.toLowerCase() === "phonenumber") {
      updatedValues = {
        ...deleteUserFormvalues,
        phonenumber: formatPhoneNumber(value),
      };
    } else {
      updatedValues = {
        ...deleteUserFormvalues,
        [fieldName]: value,
      };
    }
    form.setFieldsValue(updatedValues);
    setDeleteUserFormValues(updatedValues);
  };

  return (
    <>
      <Modal
        className="deleteUser-modal"
        title="Delete User"
        open={isDeleteModalVisible}
        onOk={handleOk}
        onCancel={() => setIsDeleteModalVisible(false)}
        // okText="Delete"
        // cancelText="Cancel"
        width={500}
        footer={[
          <Row className="popBtns">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Button
                onClick={handleCancel}
                className="finalpopupbtn finalpopup-button"
              >
                Cancel
              </Button>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <Button
                className="finalpopupbtn finalpopup-button"
                onClick={handleDeleteUser}
                disabled={loading}
              >
                {!loading ? (
                  "Delete"
                ) : (
                  <Loader
                    checkLoading={loading}
                    color={"#fff"}
                    size={10}
                    inline={true}
                  />
                )}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form form={form} className="deleteUser-popup">
          <div className="">
            <Form.Item
              name="passcode"
              rules={[{ required: true, message: "Passcode is required" }]}
            >
              <div className="info_borderText-1 application-inputBox">
                <Input
                  className="info_inputTextClass"
                  bordered={false}
                  placeholder="Passcode"
                  type="password"
                  onChange={(event) =>
                    handleInputChange("passcode", event.target.value)
                  }
                  value={deleteUserFormvalues.passcode}
                />
                <span className="text_poppins placeholder">Passcode</span>
              </div>
            </Form.Item>
          </div>
        </Form>
      </Modal>
      <NotificationPopup
        showPopup={showNotificationPopup}
        setShowPopup={setShowNotificationPopup}
        headerText={notificationMessage}
        buttonCount={1}
        onClickNavigation={onClickNavigation}
        handleClick={handleDelete}
      />
    </>
  );
};

export default DeleteUserPopup;
