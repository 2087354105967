import React, { useEffect, useState, useContext } from "react";
import { Layout, Row, Col, Input, Form } from "antd";
import {
  FormErrorHandlingFunction,
  getValueFromStorage,
  setValueInStorage,
  showNotification,
  validateEmail,
  validateFieldName,
  getCurrentRoutePath,
} from "../Utility/Utility";
import "./AddIndividual.scss";
import PrivacyPolicy from "../StepsPrivacyPolicy/StepsPrivacyPolicy";
import image from "../../Assets/Images/headerImages/Pleaseconfirm.png";
import { useNavigate, useLocation } from "react-router-dom";
import StepsHeader from "../Header/StepsHeader";
import StpesFooter from "../Footer/Footer";
import { applicationMessages } from "../Utility/ApplicationMessages";
import {
  IStepsHeader,
  IAddIndividualValues,
  IBeneficialDetails,
  IBeneficiaryListDetails,
} from "../../Contracts/Contracts";
import StepsProgressBar from "../Common/StepsProgressBar/StepsProgressBar";
import {
  validatePhoneNumber,
  formatPhoneNumber,
  getTimerPromise,
} from "../Utility/Utility";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";

import { RouteList } from "../../../Redux/Selector";
import { useSelector } from "react-redux";
import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";

const { Content } = Layout;

const headerValues: IStepsHeader = {
  title: "Please Confirm",
  titleClass: "letsheadingh5",
  img: image,
};
const AddIndividual = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { getLoquatClient } = useContext(ApplicationContext);
  const [individualDetails, setIndividualDetails] = useState<
    IBeneficiaryListDetails[]
  >([]);
  const navigate = useNavigate();
  const [individualFormvalues, setIndividualFormValues] =
    useState<IAddIndividualValues>({
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
    });

  const [errorField, setErrorField] = useState<string[] | any>([]);
  const [loading, setLoading] = useState(false);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [onClickNavigation, setOnClickNavigation] = useState(false);
  const routePaths = useSelector(RouteList);

  const currentScreen = location.pathname;
  const CurrentRoutePath = getCurrentRoutePath(
    currentScreen,
    routePaths.Routes
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    getBeneficiaryDetailsList();
  }, []);

  const getBeneficiaryDetailsList = () => {
    var service = getLoquatClient().beneficiaryService();
    service
      .getBeneficiaryList()
      .then((response) => {
        if (response.status === 400) {
          showNotification("", applicationMessages.pleaseTryAgainMsg, true);
        } else {
          if (response.successResult?.length > 0) {
            setIndividualDetails(response.successResult);
          } else {
            setIndividualDetails([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showNotification("", applicationMessages.pleaseTryAgainMsg, true);
      });
  };

  const addIndividual = () => {
    const phoneRegex = /[\s()-]/g;
    const beneficiaryDetails: IBeneficialDetails = {
      FirstName: individualFormvalues?.firstname,
      LastName: individualFormvalues?.lastname,
      Phone: individualFormvalues?.phone?.replace(phoneRegex, ""),
      Email: individualFormvalues?.email,
      UserType: "2",
    };
    setLoading(true);
    var service = getLoquatClient().beneficiaryService();
    service
      .postBeneficiaryDetails(beneficiaryDetails)
      .then((response) => {
        setLoading(false);
        if (response.status === 400) {
          const message = response.failureResult;
          if (response.failureResult?.includes("exists")) {
            // Splitting the message into two parts
            const [firstSentence, secondSentence] = message.split(". ");
            setNotificationMessage(firstSentence);
            setDescriptionText(secondSentence);
          } else {
            setNotificationMessage(response.failureResult);
            setDescriptionText("");
          }

          setOnClickNavigation(false);
          setShowNotificationPopup(true);
        } else {
          navigate(CurrentRoutePath?.Next);
          setIndividualFormValues({
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        showNotification(applicationMessages.pleaseTryAgainMsg);
      });
  };

  const handleOkClick = () => {
    navigate(CurrentRoutePath?.Next);
  };

  const handleInputChange = (
    inputName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    setIndividualFormValues((prevValues) => ({
      ...prevValues,
      [inputName]: newValue,
    }));
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Backspace" && individualFormvalues?.phone.length <= 7) {
      event.preventDefault();
      form.setFieldsValue({ phone: "" });
      setIndividualFormValues((prevValues) => ({
        ...prevValues,
        phone: "",
      }));
    }
  };

  const handleFieldsError = () => {
    const resultArray = FormErrorHandlingFunction(form);
    setErrorField(resultArray);
    if (resultArray?.length > 1) {
      const fieldName = resultArray[0];
      form.scrollToField(fieldName);
    }
  };
  const handlePhoneNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    setIndividualFormValues((prevValues) => ({
      ...prevValues,
      phone: formatPhoneNumber(newValue),
    }));
  };

  const handleKeyPress = (e: {
    which: any;
    keyCode: any;
    preventDefault: () => void;
  }) => {
    const charCode = e.which || e.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      e.preventDefault(); // Prevent entering the number
    }
  };
  return (
    <Layout className="addindividual-container">
      <div className="headerBgwithStep">
        <StepsHeader {...headerValues} />
        <StepsProgressBar
          steps="Step 6/7"
          currentStep={6}
          progressBarClassName="custom-progress-bar"
        />
      </div>
      <Content className="other-content">
        <div className="other-button-container">
          <div className="other_header_desc">
            Please add other beneficial owner(s)
          </div>
          <div className="other_header_innerdesc">
            Due to legal requirements you will need to fill in the info about
            other beneficial owner(s) who own 25% or more. We will send them a
            link to confirm their identity.
          </div>
          <br />
          <br />
          <span className="OnlyText">Adding Individual</span>
          <br />
          <br />
          <Form
            form={form}
            onFinish={addIndividual}
            className="addindividual-form"
            initialValues={individualFormvalues}
            onFieldsChange={handleFieldsError}
            scrollToFirstError={true}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="firstname"
                  rules={[
                    { required: true, message: "First Name is required" },
                    {
                      validator: validateFieldName("First Name"),
                    },
                  ]}
                  // hasFeedback
                >
                  <div
                    className={`info_borderText margin-right application-inputBox ${
                      errorField.length > 0 && errorField.includes("firstname")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="First Name"
                      onChange={(event) =>
                        handleInputChange("firstname", event)
                      }
                      value={individualFormvalues.firstname}
                      onKeyDown={(e) => handleKeyPress(e)}
                      required
                    />
                    <span className="info_span placeholder">First Name</span>
                  </div>
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="lastname"
                  rules={[
                    { required: true, message: "Last Name is required" },
                    {
                      validator: validateFieldName("Last Name"),
                    },
                  ]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("lastname")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Last Name"
                      onChange={(event) => handleInputChange("lastname", event)}
                      value={individualFormvalues.lastname}
                      onKeyDown={(e) => handleKeyPress(e)}
                      required
                    />
                    <span className="info_span placeholder">Last Name</span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item
                  name="phone"
                  rules={[{ validator: validatePhoneNumber }]}
                >
                  <div
                    className={`info_borderText application-inputBox ${
                      errorField.length > 0 && errorField.includes("phone")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Phone"
                      onKeyDown={handleKeyDown}
                      onChange={(event) => handlePhoneNumberChange(event)}
                      value={individualFormvalues.phone}
                      maxLength={14}
                      required
                    />
                    <span className="info_span placeholder">Phone</span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Item name="email" rules={[{ validator: validateEmail }]}>
                  <div
                    className={`info_borderText margin-right application-inputBox ${
                      errorField.length > 0 && errorField.includes("email")
                        ? "errorDiv"
                        : ""
                    }`}
                  >
                    <Input
                      className="info_inputTextClass"
                      bordered={false}
                      placeholder="Email"
                      onChange={(event) => handleInputChange("email", event)}
                      value={individualFormvalues.email}
                      required
                    />
                    <span className="info_span placeholder">Email</span>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
      <PrivacyPolicy />
      <StpesFooter
        nextPath={CurrentRoutePath?.Next}
        buttonName="Add"
        onClick={addIndividual}
        form={form}
        loading={loading}
      />
      <NotificationPopup
        showPopup={showNotificationPopup}
        setShowPopup={setShowNotificationPopup}
        descriptionText={descriptionText}
        headerText={notificationMessage}
        buttonCount={1}
        onClickNavigation={onClickNavigation}
        handleClick={handleOkClick}
      />
    </Layout>
  );
};

export default AddIndividual;
