import { Layout, Row, Col, Button, Typography, Flex } from "antd";
import { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { applicationMessages } from "../Utility/ApplicationMessages";

import NotificationPopup from "../Common/NotificationPopup/NotificationPopup";
import styles from './TermsCondition.module.scss';
import logo from "../../Assets/Images/logo.svg";
import FooterMain from "../Footer/FooterMain";


const { Footer, Content } = Layout;

const TermsCondition = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const OnClick = () => {
    navigate('/signup');
  };

  const handleDecline = () => {
    setShowPopup(true);
  };

  const handleCancel = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Content className={styles.container}>
        <Typography.Title level={1} className={styles.title}>Terms and Conditions</Typography.Title>
        <Flex vertical gap='20px' className={styles.textWrapper}>
          <p className={styles.terms_text}>
            These Terms and Conditions constitute a legally binding agreement
            made between you, whether personally or on behalf of an entity
            (“you”) and Loquat Inc (“we,” “us” or “our”), concerning your access
            to and use of the Loquat application as well as any other media
            form, media channel, mobile website or mobile application related,
            linked, or otherwise connected thereto (collectively, the “App”).
            You agree that by accessing the App, you have read, understood, and
            agree to be bound by all of these Terms and Conditions Use. IF YOU
            DO NOT AGREE WITH ALL OF THESE TERMS and CONDITIONS, THEN YOU ARE
            EXPRESSLY PROHIBITED FROM USING THE APP AND YOU MUST DISCONTINUE USE
            IMMEDIATELY.
          </p>

          <p className={styles.terms_text}>
            Supplemental terms and conditions or documents that may be posted on
            the App from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms and Conditions at any time
            and for any reason. We will alert you about any changes by updating
            the “Last updated” date of these Terms and Conditions and you waive
            any right to receive specific notice of each such change. It is your
            responsibility to periodically review these Terms and Conditions to
            stay informed of updates. You will be subject to, and will be deemed
            to have been made aware of and to have accepted, the changes in any
            revised Terms and Conditions by your continued use of the App after
            the date such revised Terms are posted.
          </p>

          <p className={styles.terms_text}>
            The information provided on the App is not intended for distribution
            to or use by any person or entity in any jurisdiction or country
            where such distribution or use would be contrary to law or
            regulation or which would subject us to any registration requirement
            within such jurisdiction or country. Accordingly, those persons who
            choose to access the App from other locations do so on their own
            initiative and are solely responsible for compliance with local
            laws, if and to the extent local laws are applicable.
          </p>
        </Flex>
      </Content>
      <FooterMain nextPath="signup" nextButtonText="Accept"/>
    </>
  );
};

export default TermsCondition;
