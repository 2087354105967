import { useEffect, useContext, useState } from "react";
import { Layout } from "antd";
import { useNavigate } from "react-router-dom";
import "./WebLinkStatus.scss";
import { Spin } from "antd";
import { ApplicationContext } from "../../../Context/ApplicationContextProvider";
import { IWebLinkStatusValue } from "../../../Contracts/Contracts";
import { applicationMessages } from "../../Utility/ApplicationMessages";
import {
  getValueFromStorage,
  handleSessionStorage,
  setValueInStorage,
  showNotification,
} from "../../Utility/Utility";

const WebLinkStatus = () => {
  const [isSuccess, setSuccess] = useState(false);
  const { getLoquatClient } = useContext(ApplicationContext);
  const service = getLoquatClient().notificationService();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isKyb, setKyb] = useState<string | any>();
  const navigate = useNavigate();
  let phoneNum = "";
  useEffect(() => {
    var queryString = window.location.search;
    // Parse the query string to an object
    var queryParams = new URLSearchParams(queryString);
    var kyb = queryParams.get("kyb");
    var legalTypeId = queryParams.get("legalTypeId");
    // Get the values of specific parameters
    var weblinkId = queryParams.get("webLinkId") || "";
    if (!weblinkId) {
      console.log(weblinkId);
      setShowErrorMessage(true);
    } else {
      setKyb(kyb);
      const postdetails: IWebLinkStatusValue = {
        WebLinkId: weblinkId,
      };

      service
        .getWebLinkDetailsStatus(postdetails)
        .then((response) => {
          if (response && response.status == 200) {
            console.log(response, "response");
            phoneNum = response?.successResult?.mobileNumber;
            handleSessionStorage([], "set", {
              PhoneNumber: response?.successResult?.mobileNumber,
              AccessToken: response.successResult.accessToken,
              UserId: response?.successResult?.userId,
              businessId: response?.successResult?.businessId,
            });
            getLegalTypeDetails(legalTypeId);

            setSuccess(true);
          } else {
            setShowErrorMessage(true);
          }
        })
        .catch((error) => {
          console.log(error);
          showNotification("",applicationMessages.invalidWebLink, true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLegalTypeDetails = async (legalType: any) => {
    var service = getLoquatClient().masterDataService();
    const countryCode = getValueFromStorage("CountryCode");
    await service
      .getDocumentTypes(countryCode)
      .then((response) => {
        if (response?.successResult?.legalTypes?.length > 0) {
          const selectedLegalType = response?.successResult?.legalTypes?.find(
            (item: any) => item.legalTypeId === parseInt(legalType)
          );
          const document = {
            documentTypes: selectedLegalType?.documentType,
            legalType: {
              legalTypeId: selectedLegalType.legalTypeId,
              legalTypeName: selectedLegalType.legalTypeName,
            },
          };
          const data = JSON.stringify(document);
          setValueInStorage("DocumentData", data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    console.log("updateWeblinkStatus", isSuccess);

    const updateWeblinkStatus = () => {
      var linkId = getValueFromStorage("WebLinkId");
      const postdetails = {
        WebLinkId: linkId,
        IsKycCompleted: false,
        IsWebLinkAccessed: true,
      };
      service
        .updateWebLinkDetailsStatus(postdetails)
        .then((response) => {
          if (response) {
            // if (isKyb && isKyb === "true") {
            //   navigate("/kyb-verification-details");
            // } else {
            //   navigate("/verification-details");
            // }
            navigate("/mobilevalidateotp", {
              state: { phoneNumber: getValueFromStorage("PhoneNumber"), kyb: isKyb },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (isSuccess) {
      updateWeblinkStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Layout className="mobileweblink-layout">
      <div className="center-aligned-content">
        {!showErrorMessage ? (
          <>
            <div className="moblie-weblink-container">
              Processing your webLink.....
            </div>
            <div className="weblink-loader-container">
              <Spin size="large" />
            </div>
          </>
        ) : (
          <div className="moblie-weblink-container">Invalid WebLinkId.</div>
        )}
      </div>
    </Layout>
  );
};

export default WebLinkStatus;
