import { Layout as AntLayout, Button, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import logo from "../../Assets/Images/logo.svg";
import styles from './IntroPage.module.scss';

const { Content } = AntLayout;

const IntroPage = () => {
  const navigate = useNavigate();

  const OnClick = () => {
    navigate("/terms");
  };

  return (

    <Content className={styles.content}>
      <Flex vertical className={styles.wrapper}>
        <Flex vertical gap='24px' justify="center" align="center" className={styles.buttonContainer}>
          <Button className={`${styles.button} ${styles.buttonMain}` } onClick={OnClick}>
            Start a new business account application
          </Button>

          <Button className={`${styles.button} ${styles.buttonSecondary}`} onClick={OnClick}>
            Resume existing business account application
          </Button>
        </Flex>


      <img src={logo} alt="logo" className={styles.logo}/>
      </Flex>


    </Content>


  );
};

export default IntroPage;
