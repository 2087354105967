import { Layout, Row, Typography, Checkbox, Col, Flex } from "antd";

import FooterMain from "../Footer/FooterMain";
import AccountType from "./AccountType/AccountType";
import "./AccountSelection.scss";

const { Content } = Layout;

const AccountSelection = () => {
  return (
    <>
      <Content className="account-selection layout">
        <Row className="container">
          <Typography.Title level={1} className="title">
            Account selection
          </Typography.Title>
          <Row>
            <p className="description">
              Please select the bank account type you are applying for in the
              first row. Then, make as many account selections as you wish
              below. A Share Savings account will be opened automatically with
              your selection
            </p>
          </Row>
        </Row>
        <Row className="container container_lg">
          <Typography.Title level={2} className="title_second">
            Account Type
          </Typography.Title>
          <AccountType />
        </Row>
        <Row className="container">
          <Typography.Title level={2} className="title_second">
            Account Selection
          </Typography.Title>
          <Flex className="checkbox">
            <Col flex={1} className="checkbox__col">
              <Checkbox
                className="checkbox__item"
                value={1}
                checked={true}
                disabled
              >
                <span className="checkbox__title">
                  Share Savings Account (required)
                </span>
              </Checkbox>
              <Checkbox className="checkbox__item" value={2}>
                <span className="checkbox__title">
                  Business Checking Account
                </span>
              </Checkbox>
              <Checkbox className="checkbox__item" value={3}>
                <span className="checkbox__title">
                  Money Market Savings Account
                </span>
              </Checkbox>
            </Col>
            <Col flex={1} className="checkbox__col">
              <Checkbox className="checkbox__item" value={4}>
                <span className="checkbox__title">Certificate of Deposit</span>
              </Checkbox>
              <Checkbox className="checkbox__item" value={5}>
                <span className="checkbox__title">
                  Dedicated Savings Account
                </span>
              </Checkbox>
            </Col>
          </Flex>
        </Row>
      </Content>
      <FooterMain
        nextPath="productselection"
        isConfirmBtnDisabled={false}
        currentStep={2}
        totalSteps={8}
        isProgressBarEnabled={true}
        name="Product selection"
      />
    </>
  );
};

export default AccountSelection;
