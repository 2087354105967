import { Checkbox, Flex, Form, Input, Layout } from "antd";

import identificationDocument from "../../../Assets/Images/IdentificationDocument.png";
import person from "../../../Assets/Images/person4.png";
import BAApplication from "./BAApplication/BAApplication";
import Questionary from "./Questionary/Questionary";
import EnrollSignature from "./EnrollSignature/EnrollSignature";
import FooterMain from "../../Footer/FooterMain";

const { Content } = Layout;

const Part4 = () => {
  return (
    <>
      <Content className="step2-container layout">
        <h2 className="container-title">4. Authorized Signer(s)</h2>

        <Flex vertical className="wrapper">
          <Flex gap="40px" className="person-id">
            <div className="person-id__container">
              <div className="title">Identification document</div>

              <img src={identificationDocument} alt="id document" />
            </div>

            <div className="person-id__container">
              <div className="title">Selfie</div>
              <img src={person} alt="person" />
            </div>
          </Flex>

          <Form className="form">
            <Flex
              className="form-wrrapper"
              vertical
              style={{ marginBottom: 0 }}
            >
              <Flex vertical className="form-container" gap="20px">
                <Flex justify="space-between" gap="20px">
                  <Form.Item name="firstname" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="First Name"
                        bordered={false}
                        value={"Jane"}
                        required
                      />
                      <span className="info_span placeholder">First Name</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="middlename" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Middle Name"
                        bordered={false}
                        value={"-"}
                        required
                      />
                      <span className="info_span placeholder">Middle Name</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="lastname" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Last Name"
                        bordered={false}
                        value={"Smith"}
                        required
                      />
                      <span className="info_span placeholder">Last Name</span>
                    </div>
                  </Form.Item>
                </Flex>
                <Flex justify="space-between" gap="20px">
                  <Form.Item name="SSN" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="SSN"
                        bordered={false}
                        value={"XXX-XX-0000"}
                        required
                      />
                      <span className="info_span placeholder">SSN</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="DateOfBirth" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Date Of Birth "
                        bordered={false}
                        value={"09/12/1972"}
                        required
                      />
                      <span className="info_span placeholder">
                        Date Of Birth{" "}
                      </span>
                    </div>
                  </Form.Item>
                </Flex>
              </Flex>

              <Flex vertical className="form-container" gap="20px">
                <Flex justify="space-between" gap="20px">
                  <Form.Item name="phone" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Phone"
                        bordered={false}
                        value={"(000) 000-0000"}
                        required
                      />
                      <span className="info_span placeholder">Phone</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="Email" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Email"
                        bordered={false}
                        value={"jnsmith@gmail.com"}
                        required
                      />
                      <span className="info_span placeholder">Email</span>
                    </div>
                  </Form.Item>
                </Flex>
              </Flex>

              <Flex vertical className="form-container" gap="20px">
                {/* <h5>Physical address of business</h5> */}
                <Flex gap='20px'>
                  <Form.Item name="address" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Street Address"
                        bordered={false}
                        value={"420 Florida Ave NE"}
                        required
                      />
                      <span className="info_span placeholder">
                        Street Address
                      </span>
                    </div>
                  </Form.Item>
                  <Form.Item name="address" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Street Address 2"
                        bordered={false}
                        value={"-"}
                        required
                      />
                      <span className="info_span placeholder">
                        Street Address
                      </span>
                    </div>
                  </Form.Item>
                </Flex>
                <div className="city-state-zip">
                  <Form.Item name="City" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="City"
                        bordered={false}
                        value={"Miami"}
                        required
                      />
                      <span className="info_span placeholder">City</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="State" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="State"
                        bordered={false}
                        value={"FL"}
                        required
                      />
                      <span className="info_span placeholder">State</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="ZIP" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="ZIP"
                        bordered={false}
                        value={"33156"}
                        required
                      />
                      <span className="info_span placeholder">ZIP</span>
                    </div>
                  </Form.Item>
                </div>

                {/* <Flex className="checkboxContainer">
                  <label style={{ display: "flex" }}>
                    <Checkbox checked={true} />
                    <div className="checkboxLabel">
                      Mailing address and physical address are the same
                    </div>
                  </label>
                </Flex> */}
              </Flex>

              <Flex vertical gap="20px">
                <Flex justify="space-between" gap="20px">
                  <Form.Item name="Employer" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Employer"
                        bordered={false}
                        value={"Adam's Coffee House LLC"}
                        required
                      />
                      <span className="info_span placeholder">Employer</span>
                    </div>
                  </Form.Item>
                  <Form.Item name="job" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox`}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Job title / Position at the company listed on this application"
                        bordered={false}
                        value={"Barista"}
                        required
                      />
                      <span className="info_span placeholder">
                        Job title / Position at the company listed on this
                        application
                      </span>
                    </div>
                  </Form.Item>
                </Flex>
              </Flex>
            </Flex>
          </Form>
        </Flex>

        <BAApplication />
        <Questionary />
        <EnrollSignature />
      </Content>
      <FooterMain
        nextPath="endthankyou"
        nextButtonText="Submit"
        backButtonText="Back"
      />
    </>
  );
};

export default Part4;
