import dayjs from "dayjs";
export interface IStepsHeader {
  title: string;
  titleClass: string;
  img: any;
}
export interface IResponsiveNoStepsHeader {
  phoneNumber?: string;
  title: string;
  titleClassName?: string;
}

export interface IResponsiveStepsHeader {
  step: string;
  currentStep: number;
  title: string;
  titleClassName?: string;
  headerTitleWidth?: number;
}

export interface IApprouteProps {
  online: boolean;
}

export interface IFooterProps {
  inviteClick?: () => void;
  uploadDocuments?: () => void;
  gobackPath?: string;
  nextPath: string;
  onClick?: () => void;
  buttonName?: string;
  form?: any;
  loading?: boolean;
  setDisable?: boolean;
  isRedirectedStep?: boolean;
  isFinalStepNavigation?: boolean | undefined;
  isFinalStepChangePictureNavigation?: boolean | undefined;
  isFinalStepDocumentNavigation?: boolean | undefined;
  isFinalStepOtherBeneficialNavigation?: boolean | undefined;
  hideGoBack?: boolean;
  fullWidth?: boolean;
  footerWidth?: number;
}

export interface IUploadScreenHeaderProps {
  gobackpath: string;
  cardType?: string | null;
}

export interface IGeoLocProps {
  clat: number;
  clong: number;
}

export interface IUnderStandingUploadProps {
  image: any;
  documentTypeId: number;
  countrycode: string;
  legalTypeId: number;
  id: string;
}

export interface IDocumentStatusProps {
  BusinessId: string;
  CountryCode: string;
  LegalTypeId: string;
  NoBusinessLicenseRequired: boolean;
  DocumentType: string;
  DisableValidations: boolean;
}

export interface IPostLegalTypeDetails {
  legalTypesId: number;
}
export interface IValidateOtpDetails {
  Phone: string;
  SecurityKey: string;
  Reason: string;
}

export interface IPersonalInfoPostDetails {
  SSN: string;
  DateOfBirth: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  // Id: string;
  // UserId: string;
  MiddleName: string;
  CountryCode: string | null;
  Address: {
    AddressLine1: string;
    AddressLine2: string;
    State: string;
    City: string;
    Zip: string;
  };
  // User: string;
}
export interface IPersonalInfoFormValues {
  firstname: string;
  middlename: string;
  lastname: string;
  SSN: string;
  dateofbirth: string | any;
  phone: string;
  email: string;
  zip: string;
  state: string;
  city: string;
  streetaddress1: string;
  streetaddress2: string;
  employer: string;
  jobtitle: string;
}

export interface IBusinessInfoFormValues {
  name: string;
  einTin: string;
  DBA: string;
  legalTypeId: number;
  industryId: number | null;
  subIindustryId: number | null;
  phone: string;
  email: string;
  zip: string;
  state: string;
  city: string;
  purpose: string;
  employees: number;
  addressLine1: null | string;
  addressLine2: null | string;
}

export interface IBusinessPostInfoValues {
  name: null | string;
  einTin: null | string;
  email: null | string;
  phone: string;
  address: {
    AddressLine1: null | string;
    AddressLine2: null | string;
    State: null | string;
    City: null | string;
    Zip: null | string;
  };
  legalTypeId: number;
  industryId: number | null;
}
export const businessInfoInitialvalues: IBusinessInfoFormValues = {
  name: "Adam's Coffee House LLC",
  DBA: "Adam's Coffee House",
  einTin: "XX-XXX0000",
  email: "adamscoffeehouse@gmail.com",
  phone: "(000) 000-0000",
  addressLine1: "320 Florida Ave NE",
  addressLine2: "",
  state: "FL",
  city: "Miami",
  zip: "33156",
  purpose: "Sale of drinks and pastries to the guests of the establishment",
  employees: 10,
  legalTypeId: 3,
  industryId: 1,
  subIindustryId: 1,
};

export const personalInfoValues: IPersonalInfoFormValues = {
  firstname: "Adam",
  middlename: "—",
  lastname: "Smith",
  SSN: "XXX-XX-0000",
  dateofbirth: dayjs("05/14/1980"),
  phone: "(000) 000-0000",
  email: "adam_smith@gmail.com",
  zip: "33156",
  state: "FL",
  city: "Miami",
  streetaddress1: "520 Florida Ave NE",
  streetaddress2: "—",
  employer: "Adam’s Coffee House LLC",
  jobtitle: "Store manager  ",
};

export interface IPersonalInfoFormFinalStepValues {
  firstName: string;
  lastName: string;
  SSN: string;
  dateOfBirth: string | any;
  phone: string;
  ssn: any;
  email: string;
  zip: string | null;
  state: string;
  userId: string;
  city: string;
  addressLine1: string;
  addressLine2: string;
  profileImage: any;
}

export interface IDocumentsValue {
  documentType: {
    documentTypeId: number;
    documentTypeName: string;
  }[];
  legaltype: {
    legalTypeId: number;
    legalTypeName: string;
  };
}

export interface IDocumentTypes {
  documentTypeId: number;
  documentTypeName: string;
}

export interface ILegalTypes {
  legalTypeId: number;
  legalTypeName: string;
  documentType: IDocumentTypes[];
}
export const businessInfoValues: IBusinessInfoFormValues = {
  name: "",
  DBA: "",
  einTin: "",
  email: "",
  phone: "",
  addressLine1: "",
  addressLine2: "",
  state: "",
  city: "",
  zip: "",
  purpose: "",
  employees: 0,
  legalTypeId: 2,
  industryId: 1,
  subIindustryId: 1,
};

export interface IAddIndividualValues {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}
export interface IBeneficiaryListDetails {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  usertype: string;
  userid: string;
}

export interface IBeneficiaryListDetailsWithOwners {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  owner: boolean | any;
  theyown: string | any;
  individual: string | any;
  businesname: string | any;
  doingbusines: string | any;
  eintin: string | any;
  legaltype: string | any;
}

export interface ISendOtp {
  MobileNumber: string;
  CountryCode?: string | null;
}

export interface IValidateOtp {
  MobileNumber: string;
  OTP: string;
}

export interface ILinkTokenValue {
  BusinessId: string;
}

export interface IAccessTokenValue {
  BusinessId: string;
  PublicToken: string;
}

export interface IWebLinkValue {
  BusinessId: string | null;
  MobileNumber: string | null;
  SubscriptionKey: string | null;
  AccessToken: string | null;
  Url: string;
  SendSMS: boolean;
}

export interface IUpdateWebLinkStatusDetails {
  WebLinkId: string | null;
  IsKycCompleted: boolean;
  IsWebLinkAccessed: boolean;
}

export interface IWebLinkStatusValue {
  WebLinkId: string | null;
}
export interface IBeneficiaryOwnerInviteValue {
  businessUsers: IBeneficiaryOwners[];
}
export interface IBeneficiaryOwners {
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  userType: string;
  isinvitationsent?: boolean | null;
}

export interface IKYCDocProps {
  image: any;
}

// export interface IKYCFrontProps {
//   data: IKYCFrontData
// }

export interface IKYCFrontData {
  base64: String;
  documentTypeId: String | undefined | null;
}

export interface IBeneficialOwner {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}

export interface IBeneficialDetails {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  UserType: string;
}

export interface IVirtualAccountValue {
  businessId: string | null;
  userId: string | null;
  regenerateCard: boolean;
}

export interface IFinalStepPersonalInfoFormValues {
  firstName: string | any;
  middleName?: string | any;
  lastName: string | any;
  ssn?: string | any;
  dateOfBirth: string | any;
  phone: string | any;
  email: string | any;
  zip: string | any;
  state: string | any;
  city: string | any;
  addressLine1: string | any;
  addressLine2: string | any;
}

export interface IFinalStepBusinessInfoFormValues {
  legalTypesId: any;
  name: string;
  einTin: string;
  industryId: number | null;
  phone: string;
  email: string;
  zip: string;
  state: string;
  city: string;
  addressLine1: null | string;
  addressLine2: null | string;
}

export interface IDeleteUserProps {
  PhoneNumber: number;
  PassCode: number;
  SecurityKey: any;
  Reason: any;
  ConnectionString: any;
  IPAddress: any;
}

export interface IDeleteUserValues {
  phonenumber: any;
  passcode: any;
}

export interface IAcuantProps {
  selectedImage?: (image: any, callBack: any) => void;
  title: string;
  setcardType?: React.Dispatch<React.SetStateAction<number | null>>;
}

export interface IMyAccountProps {
  userName?: string;
  profileImage?: string;
  cardNumber?: string;
  regenerateCard?: (regenerate: boolean) => void;
  issuedDate?: string;
}
