import React from "react";
import "./PlaidTab.scss";
import { Layout, Tabs, Image, Input } from "antd";
import type { TabsProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PlaidHeader from "../PlaidHeader/PlaidHeader";
import PlaidAccount from "../PlaidAccount/PlaidAccount";
import mail from "../../Assets/Images/DashboardImages/mailImage.png";
import msg from "../../Assets/Images/DashboardImages/msgImage.png";

const onChange = (key: string) => {
  console.log(key);
};

const operations = (
  <div className="righticons">
    <Input
      addonBefore={<SearchOutlined />}
      placeholder="Search"
      style={{ width: 150 }}
    />
    <a href="#" className="notification">
      <Image src={mail} alt="front" loading="lazy" preview={false} />
      <span className="badge">3</span>
    </a>
    <Image
      src={msg}
      alt="front"
      loading="lazy"
      preview={false}
      className="msgImage"
    />
  </div>
);

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "My accounts",
    children: <PlaidAccount></PlaidAccount>,
  },
  {
    key: "2",
    label: "Business loan",
    children: "Content of Tab Pane 2",
    disabled: true,
  },
  {
    key: "3",
    label: "Cards",
    children: "",
  },
  {
    key: "4",
    label: "Account Management",
    children: "Content of Tab Pane 3",
    disabled: true,
  },
];

const PlaidTab = () => {
  return (
    <Layout className="plaid-tabs-container">
      <PlaidHeader />
      <div className="plaid-tabs-layout">
        {/* <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          tabBarExtraContent={operations}
        /> */}
      </div>
    </Layout>
  );
};
export default PlaidTab;
