import { Layout, Typography, Select } from "antd";
import { useState } from "react";

import styles from './DateOfBirth.module.scss';
import { Flex } from "antd/lib";
import FooterMain from "../../Footer/FooterMain";

const generateOptions = (start: any, end: any) => {
  const options = [];

  if(start > end) {
    for (let i = start; i >= end; i--) {
      options.push(i);
    }
  } else {
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
  }
  return options.map(value => (
    <Select.Option key={value} value={value}>
      {value}
    </Select.Option>
  ));
};

const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];


const { Content } = Layout;

const DateOfBirth = ({ handleNextClick }: any) => {
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);
  const [year, setYear] = useState(null);

  const days = month ? generateOptions(1, new Date(year!, month, 0).getDate()) : [];
  const years = generateOptions(new Date().getFullYear(), 1920);

  const handleMonthChange = (value: any) => {
    setMonth(value);
    setDay(null);
  };

  const handleDayChange = (value: any) => {
    setDay(value);
  };

  const handleYearChange = (value: any) => {
    setYear(value);
  };

  const months = monthNames.map((name, index) => (
    <Select.Option key={index + 1} value={index + 1}>
      {name}
    </Select.Option>
  ));

  return (
    <>
      <Content className={`${styles.container} layout`}>
        <Typography.Title level={1} className={styles.title}>Thank you for choosing <span>“Partner name” </span>for your business banking needs</Typography.Title>
        <Flex vertical gap='16px' className={styles.textWrapper}>
          <p className={styles.terms_text}>
            We will ask you to create a username and password that will be specific to this business bank account.
          </p>

          <p className={styles.terms_text}>
            Once you start, the information you provide will be saved for 30 days, during which time you can re-enter your application where you left off at any time to finish.
          </p>

          <p className={styles.terms_text}>
            To make sure you are of legal age to open a business account, please enter your date of birth.
          </p>
        </Flex>


        <Flex className={styles.dateContainer} gap='13px'>

          <div className={styles.slectWrapper}>
            {month && <div className={styles.placeholder}>Month</div>}
            <Select
              placeholder="Month"
              value={month}
              onChange={handleMonthChange}
              className={styles.select}
            >
              {months}
            </Select>

          </div>

          <div className={styles.slectWrapper}>
            {day && <div className={styles.placeholder}>Day</div>}
            <Select
              placeholder="Day"
              value={day}
              onChange={handleDayChange}
              className={styles.select}
            >
              {days}
            </Select>
          </div>

          <div className={styles.slectWrapper}>
            {year && <div className={styles.placeholder}>Year</div>}

            <Select
              placeholder="Year"
              value={year}
              onChange={handleYearChange}
              className={styles.select}
            >
              {years}
            </Select>
          </div>




        </Flex>
      </Content>

      <FooterMain onConfirmBtnClick={handleNextClick}/>

    </>
  );
};

export default DateOfBirth;
