import React, { createContext, useState } from "react";
import { LoquatClient } from "../../API/LoquatClient";

interface DocumentType {
  documentTypeId: number;
  documentTypeName: string;
  id?: number;
  value?: string;
}

interface LegalType {
  legalTypeId: number;
  legalTypeName: string;
}
interface stateI {

}
interface ApplicationContext {
  auth: { ls: boolean; loadingUser: boolean };
  setAuth: (auth: { ls: boolean; loadingUser: boolean }) => void;
  userId: string;
  setUserId: (userId: string) => void;
  documentData: {
    documentTypes: DocumentType[];
    legalType: LegalType;
  };
  setDocumentData: (documentData: {
    documentTypes: DocumentType[];
    legalType: LegalType;
  }) => void;
  getLoquatClient: () => LoquatClient;

  state: {},
  setState: (state: stateI)=> void
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <ApplicationContext Provider>."
  );
};

const applicationDefaultValue: ApplicationContext = {
  auth: { ls: false, loadingUser: true },
  setAuth: () => {
    // return void
  },

  userId: "",
  setUserId: () => {
    //return void
  },
  documentData: {
    documentTypes: [],
    legalType: { legalTypeId: 0, legalTypeName: "" },
  },
  setDocumentData: () => {},
  getLoquatClient: stub,

  state: {},
  setState: ()=>{

  },
};

export const ApplicationContext = createContext<ApplicationContext>(
  applicationDefaultValue
);

export const ApplicationContextProvider = ({ config, children }: any) => {
  const [auth, setAuth] = useState(applicationDefaultValue.auth);
  const [userId, setUserId] = useState<string>(applicationDefaultValue.userId);
  const [state, setState] = useState(applicationDefaultValue.state);
  const [documentData, setDocumentData] = useState<{
    documentTypes: DocumentType[];
    legalType: LegalType;
  }>(applicationDefaultValue.documentData);

  const liquatClient = new LoquatClient(config.baseURL, config);

  const getLoquatClient = () => {
    return liquatClient;
  };

  const value: ApplicationContext = {
    auth,
    setAuth,
    userId,
    setUserId,
    documentData,
    setDocumentData,
    getLoquatClient,
    state,
    setState
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};
