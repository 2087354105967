import React, { useState, useEffect, useContext } from "react";
import "./PlaidLinkToken.scss";
import { ApplicationContext } from "../../Context/ApplicationContextProvider";
import { IAccessTokenValue, ILinkTokenValue } from "../../Contracts/Contracts";
import {
  PlaidLink,
  PlaidLinkOnSuccess,
  PlaidLinkOnEvent,
  PlaidLinkOnExit,
  usePlaidLink,
  PlaidLinkOptions,
} from "react-plaid-link";
import { useNavigate } from "react-router";
import PlaidTab from "../PlaidTab/PlaidTab";
import { applicationMessages } from "../Utility/ApplicationMessages";
import { showNotification } from "../Utility/Utility";

const StartPlaid: React.FC = () => {
  const [token, setToken] = useState<string>("");
  const { getLoquatClient } = useContext(ApplicationContext);
  const service = getLoquatClient().digitalFinancialService();

  useEffect(() => {
    const businessId: ILinkTokenValue = {
      BusinessId: "87d4e620-471f-11ee-be56-0242ac120002",
    };
    service
      .getLinkToken(businessId)
      .then((response) => {
        if (response) {
          const currentToken = response?.successResult?.link_token;
          setToken(currentToken);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess: PlaidLinkOnSuccess = (publicToken, metadata) => {
    // send public_token to your server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    navigate("/dashboard");
    const payload: IAccessTokenValue = {
      BusinessId: "9E363383-8E42-EE11-8C08-CE5796BE5DEB",
      PublicToken: publicToken,
    };
    service
      .getAccessToken(payload)
      .then((response) => {
        if (response) {
          if (response.statusCodeDesc === 200) {
            // showNotification(applicationMessages.successMessage);
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const onEvent: PlaidLinkOnEvent = (eventName, metadata) => {
    console.log("hello", eventName, metadata);
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    //console.log(eventName, metadata);
  };

  const onExit: PlaidLinkOnExit = (error, metadata) => {
    navigate("/dashboard");
    // log onExit callbacks from Link, handle errors
    // https://plaid.com/docs/link/web/#onexit
    //console.log(error, metadata);
  };

  const config: PlaidLinkOptions = {
    // token must be the same token used for the first initialization of Link
    token,
    onSuccess,
    onEvent,
    onExit,
  };

  const {
    open,
    ready,
    // error,
    // exit
  } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (ready) {
      open();
    }
  }, [ready, open]);

  const navigate = useNavigate();

  return (
    <>
      
      <PlaidTab></PlaidTab>

      <PlaidLink
        className="plaid-link-container"
        // style={{ padding: "20px", fontSize: "16px", cursor: "pointer" }}
        token={token}
        onSuccess={onSuccess}
        onEvent={onEvent}
        onExit={onExit}
      >
        {/* Link you bank account */}
        {/* <Row className="connectsec">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
          >
            <div className="connectconn">
              <p>
                Connect your external bank account(s) to get access to our
                spending analytic tools across the account(s)
              </p>
            </div>
          </Col>
          <Col
            className="vectorright"
            xs={{ span: 24 }}
            sm={{ span: 2 }}
            md={{ span: 2 }}
            lg={{ span: 2 }}
          >
            <img className="vectorhome" src={vector} alt="vector" />
          </Col>
        </Row> */}
      </PlaidLink>
    </>
  );
};

export default StartPlaid;
