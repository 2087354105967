export const applicationMessages = {
  errorMessage: "An error occurred",
  // errorUnableToFetch: "Unable to fetch user's application status",
  // errorUnableToFetchPersonaInfo: "Unable to fetch personal Information",
  errorFileUpload: "Something went wrong, Please try again",
  // finalPopup: {
  //   finalPopupMessage: "Completed",
  //   finalPopupDescription: "Application completed successfully",
  // },
  login: {
    invalidCredentialDescription: "Incorrect username or password",
  },
  geoLocation: {
    geoLocationMessage: "Geolocation",
    geoLocationSuccess: "Location validation successful",
    geoLocationError: "Geolocation validation failed",
    geolocationSelect:
      "Loquat requires location sharing to validate account eligibility requirements",
  },
  defaultErrorMessage: "Something went wrong, Please try again",
  // businessInfoMessage: "Business Information",
  // businessInfoDescription:
  //   "Business Information has been updated successfully.",
  businessInfoInvalidDesc: "Invalid business details",
  // businessInfoDesc: "Unable to submit business information",
  webLinkSent: "The web link has been successfully sent to your mobile device",
  invalidWebLink: "Invalid WebLink",
  successMessage: "Success",
  documentStatus: " Business Document Status",
  underStandingUploadFileMessage: "Please upload all the required documents",
  otpSentMessage:
    "We have sent a secure one time passcode to your mobile number. It will be valid for 60 seconds.",
  personalInfo: {
    // personalInfoMsg: "Personal Information",
    // personalInfoDescription:
    //   "Personal Information has been updated successfully.",
    personalInfoInvalidDesc: "Invalid personal details",
  },
  phoneValidation: {
    phoneValidationInvalidDesc: "Please enter a valid mobile number",
    otpSentSuccessMsg:
      "We have sent a secure one time passcode to your mobile number",
    otpSentSuccessDescMsg: "It will be valid for 60 seconds",
  },
  underStandingUpload: {
    underStandingUploadMsg: "Understanding Upload",
    understandingUploadSuccessMsg: "Document Successfully uploaded",
  },
  smsCode: {
    smsInvalidCodeDescription: "Incorrect verification code, please try again",
  },
  fileSize: {
    fileSizeMsg: "File Size Exceeded",
    fileSizeDesc: "The file size should be less than 5 MB",
  },
  deleteUser: {
    deleteUserSuccessMessage: "You can now restart your application",
  },
  terms: {
    termsDeclineMessage:
      "Please accept the Terms and Conditions in order to proceed",
  },
  kyc: {
    front: "Upload successful",
    back: "Upload successful",
    selfie: "Upload successful",
  },
  pleaseTryAgainMsg: "Something went wrong, please try again",
  connectToCustomerMsg: "Please contact our customer support",
};
