import { Checkbox, Col, Flex, Form, Input, Layout, Row } from "antd";
import "./Part1.scss";
import identificationDocument from "../../../Assets/Images/IdentificationDocument.png";
import person from "../../../Assets/Images/person2.png";
import selfie from "../../../Assets/Images/new/selfie.png";
import doc1 from "../../../Assets/Images/Doc.png";
import doc2 from "../../../Assets/Images/Doc2.png";
import doc3 from "../../../Assets/Images/Doc3.png";
import doc4 from "../../../Assets/Images/Doc4.png";

const { Content } = Layout;

const Part1 = () => {
  return (
    <Content className="step2-container step1-container layout">
      <h1 className="finalstep__title">Final step</h1>
      <div className="finalstep__subtitle">
        Please review and confirm the accuracy of your application before
        digitally signing. All responsible individuals and authorized signers
        will receive a secure link to digitally sign the application
      </div>

      <Flex vertical className="wrapper">
        <Flex gap="100px" className="person-id">
          <div className="person-id__container">
            <div className="title">Identification document</div>

            <img src={identificationDocument} alt="id document" />
          </div>

          <div className="person-id__container">
            <div className="title">Your selfie</div>
            <img src={selfie} className="person-image" alt="person" />
          </div>
        </Flex>

        <div className="finalstep-header">Account designation information</div>
        <Form className="form">
          <Flex className="form-wrrapper" vertical>
            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="code" style={{ flex: 3 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="NAICS Code"
                      bordered={false}
                      value={"000000"}
                      required
                    />
                    <span className="info_span placeholder">NAICS Code</span>
                  </div>
                </Form.Item>
                <Form.Item name="entitytype" style={{ flex: 9 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="-"
                      bordered={false}
                      value={"LLC (Limited Liability Company)"}
                      required
                    />
                    <span className="info_span placeholder">Entity Type</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex justify="space-between" gap="20px">
                <Form.Item name="purpose" style={{ flex: 9 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="-"
                      bordered={false}
                      value={
                        "Sale of drinks and pastries to the guests of the establishment"
                      }
                      required
                    />
                    <span className="info_span placeholder">
                      What is the primary purpose or function of this business?
                    </span>
                  </div>
                </Form.Item>
                <Form.Item name="DateOfBirth" style={{ flex: 3 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Number Of Employees"
                      bordered={false}
                      value={"10"}
                      required
                    />
                    <span className="info_span placeholder">
                      Number Of Employees
                    </span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <div className="finalstep-header">Personal information</div>
            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="firstname" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="First Name"
                      bordered={false}
                      value={"Adam"}
                      required
                    />
                    <span className="info_span placeholder">First Name</span>
                  </div>
                </Form.Item>
                <Form.Item name="middlename" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="-"
                      bordered={false}
                      value={"-"}
                      required
                    />
                    <span className="info_span placeholder">Middle Name</span>
                  </div>
                </Form.Item>
                <Form.Item name="lastname" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="-"
                      bordered={false}
                      value={"Smith"}
                      required
                    />
                    <span className="info_span placeholder">Last Name</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex justify="space-between" gap="20px">
                <Form.Item name="SSN" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="SSN"
                      bordered={false}
                      value={"XXX-XX-0000"}
                      required
                    />
                    <span className="info_span placeholder">SSN</span>
                  </div>
                </Form.Item>
                <Form.Item name="DateOfBirth" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Date Of Birth "
                      bordered={false}
                      value={"05/14/1980"}
                      required
                    />
                    <span className="info_span placeholder">
                      Date Of Birth{" "}
                    </span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="phone" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Phone"
                      bordered={false}
                      value={"(000) 000-0000"}
                      required
                    />
                    <span className="info_span placeholder">Phone</span>
                  </div>
                </Form.Item>
                <Form.Item name="Email" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Email"
                      bordered={false}
                      value={"adam_smith@gmail.com"}
                      required
                    />
                    <span className="info_span placeholder">Email</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container">
              <Flex vertical className="form-container" gap="20px">
                <Flex justify="space-between" gap="20px">
                  <Form.Item name="address" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Street Address"
                        bordered={false}
                        value={"520 Florida Ave NE"}
                        required
                      />
                      <span className="info_span placeholder">
                        Street Address
                      </span>
                    </div>
                  </Form.Item>
                  <Form.Item name="address2" style={{ flex: 1 }}>
                    <div className={`info_borderText application-inputBox `}>
                      <Input
                        className="info_inputTextClass"
                        placeholder="Street Address 2"
                        bordered={false}
                        value={"-"}
                        required
                      />
                      <span className="info_span placeholder">
                        Street Address 2
                      </span>
                    </div>
                  </Form.Item>
                </Flex>
              </Flex>
              <div className="city-state-zip">
                <Form.Item name="City" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="City"
                      bordered={false}
                      value={"Miami"}
                      required
                    />
                    <span className="info_span placeholder">City</span>
                  </div>
                </Form.Item>
                <Form.Item name="State" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="State"
                      bordered={false}
                      value={"FL"}
                      required
                    />
                    <span className="info_span placeholder">State</span>
                  </div>
                </Form.Item>
                <Form.Item name="ZIP" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="ZIP"
                      bordered={false}
                      value={"33156"}
                      required
                    />
                    <span className="info_span placeholder">ZIP</span>
                  </div>
                </Form.Item>
              </div>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="Employer" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Employer"
                      bordered={false}
                      value={"Adam's Coffee House LLC"}
                      required
                    />
                    <span className="info_span placeholder">Employer</span>
                  </div>
                </Form.Item>
                <Form.Item name="job" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Job title / Position at the company listed on this application"
                      bordered={false}
                      value={"Store manager"}
                      required
                    />
                    <span className="info_span placeholder">
                      Job title / Position at the company listed on this
                      application
                    </span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>
          </Flex>

          <Flex vertical className="form-container" gap="20px">
            <Flex
              justify="space-between"
              gap="20px"
              className="beneficial-owner"
            >
              <Form.Item name="ownership" style={{ flex: 3 }}>
                <Flex className="checkboxContainer">
                  <label style={{ display: "flex", marginTop: "30px" }}>
                    <Checkbox className="checkbox__item" checked={true} />
                    <div className="checkboxLabel">Beneficial owner</div>
                  </label>
                </Flex>
              </Form.Item>
              <Form.Item name="ownership" style={{ flex: 3 }}>
                <div className={`info_borderText application-inputBox`}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Ownership %"
                    bordered={false}
                    value={"25%"}
                    required
                  />
                  <span className="info_span placeholder">Ownership %</span>
                </div>
              </Form.Item>
              <Form.Item name="ownership" style={{ flex: 6 }}></Form.Item>
            </Flex>
          </Flex>

          <Flex className="form-wrrapper cerification-card" vertical gap="12px">
            <h4 className="title">Certification of Business Owners</h4>

            <p className="text">
              REQUIRED FEDERAL CERTIFICATION. (The person opening a new account
              for a legal entity with [Financial Institution] must complete this
              certification pursuant to federal law). To help the government
              fight financial crime, federal law requires the [Financial
              Institution] to obtain, verify and record information about the
              beneficial owners of legal entity customers who own 25%, or more
              of the legal entity. Legal entities can be abused to conceal
              involvement in terrorist financing, money laundering, tax evasion,
              corruption, fraud and other financial crimes. Requiring the
              disclosure of key individuals who ultimately own or control a
              legal entity (beneficial owners) helps law enforcement investigate
              and prosecute these types of crimes.
            </p>

            <Flex justify="space-between" gap="20px" align="center">
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Adam Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Adam Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>

          {/* <Flex className="form-wrrapper" vertical gap="40px">
            <h3>Customer information</h3>

            <Flex justify="space-between" gap="20px" align="center">
              <Form.Item name="Date" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Date"
                    bordered={false}
                    value={"07/10/2024"}
                    required
                  />
                  <span className="info_span placeholder">Date</span>
                </div>
              </Form.Item>
              <Form.Item name="Company" style={{ flex: 3 }}>
                <div className={`info_borderText application-inputBox`}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Name of Legal Entity for Which the Account is Being Opened "
                    bordered={false}
                    value={"Company name"}
                    required
                  />
                  <span className="info_span placeholder">
                    Name of Legal Entity for Which the Account is Being Opened{" "}
                  </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex> */}
        </Form>
      </Flex>

      <Flex vertical className="wrapper">
        <Form className="form form-wrrapper">
          <Flex className="form-wrrapper" vertical>
            <h3 className="form-title">Business information</h3>
            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="businessname" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Business Name"
                      bordered={false}
                      value={"Adam's Coffee House LLC"}
                      required
                    />
                    <span className="info_span placeholder">Business Name</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex justify="space-between" gap="20px">
                <Form.Item name="dba" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Doing Business As (DBA)"
                      bordered={false}
                      value={"Adam's Coffee House"}
                      required
                    />
                    <span className="info_span placeholder">
                      Doing Business As (DBA)
                    </span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex justify="space-between" gap="20px">
                <Form.Item name="eintin" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="EIN / TIN"
                      bordered={false}
                      value={"XX-XXX0000"}
                      required
                    />
                    <span className="info_span placeholder">EIN / TIN</span>
                  </div>
                </Form.Item>
                <Form.Item name="legaltype" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Legal Type"
                      bordered={false}
                      value={"LLC (Limited Liability Company)"}
                      required
                    />
                    <span className="info_span placeholder">Legal Type</span>
                  </div>
                </Form.Item>
              </Flex>
              <Flex justify="space-between" gap="20px">
                <Form.Item name="myindustry" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="My Industry"
                      bordered={false}
                      value={"Accommodation and Food Services"}
                      required
                    />
                    <span className="info_span placeholder">My Industry</span>
                  </div>
                </Form.Item>
                <Form.Item name="subindustry" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Sub Industry"
                      bordered={false}
                      value={"Snack and Non Alcoholic Beverages Bars"}
                      required
                    />
                    <span className="info_span placeholder">Sub Industry</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <Flex justify="space-between" gap="20px">
                <Form.Item name="phone" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Phone"
                      bordered={false}
                      value={"(000) 000-0000"}
                      required
                    />
                    <span className="info_span placeholder">Phone</span>
                  </div>
                </Form.Item>
                <Form.Item name="email" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Email"
                      bordered={false}
                      value={"adamscoffeehouse@gmail.com"}
                      required
                    />
                    <span className="info_span placeholder">Email</span>
                  </div>
                </Form.Item>
              </Flex>
            </Flex>

            <Flex vertical className="form-container" gap="20px">
              <h5>Physical address of business</h5>
              <Flex gap='20px'>
                <Form.Item name="address" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Street Address"
                      bordered={false}
                      value={"320 Florida Ave NE"}
                      required
                    />
                    <span className="info_span placeholder">Street Address</span>
                  </div>
                </Form.Item>
                <Form.Item name="address" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="Street Address 2"
                      bordered={false}
                      value={"-"}
                      required
                    />
                    <span className="info_span placeholder">Street Address</span>
                  </div>
                </Form.Item>

              </Flex>

              <div className="city-state-zip">
                <Form.Item name="City" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox `}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="City"
                      bordered={false}
                      value={"Miami"}
                      required
                    />
                    <span className="info_span placeholder">City</span>
                  </div>
                </Form.Item>
                <Form.Item name="State" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="State"
                      bordered={false}
                      value={"FL"}
                      required
                    />
                    <span className="info_span placeholder">State</span>
                  </div>
                </Form.Item>
                <Form.Item name="ZIP" style={{ flex: 1 }}>
                  <div className={`info_borderText application-inputBox`}>
                    <Input
                      className="info_inputTextClass"
                      placeholder="ZIP"
                      bordered={false}
                      value={"33156"}
                      required
                    />
                    <span className="info_span placeholder">ZIP</span>
                  </div>
                </Form.Item>
              </div>

              <Flex className="checkboxContainer">
                <label style={{ display: "flex" }}>
                  <Checkbox className="checkbox__item" checked={true} />
                  <div className="checkboxLabel">
                    Mailing address and physical address are the same
                  </div>
                </label>
              </Flex>
            </Flex>
          </Flex>

          <Flex gap="20px" className="container-docs">
            <Col>
              <div className="doc-title">Articles of Organization</div>
              <img src={doc1} alt="doc1" />
            </Col>
            {/* <Col>
              <div className="doc-title">Certificate of Organization</div>
              <img src={doc2} alt="doc2" />
            </Col>
            <Col>
              <div className="doc-title">Certificate of Formation</div>
              <img src={doc3} alt="doc3" />
            </Col>
            <Col>
              <div className="doc-title">Business License</div>
              <img src={doc4} alt="doc4" />
            </Col> */}
          </Flex>

          <Flex className="form-wrrapper cerification-card" vertical gap="12px">
            <h4 className="title">Certification of Responsible Individual</h4>

            <p className="text">
              The Responsible Individual listed above certifies that all
              information regarding the Customer, Responsible Individual and
              Beneficial Owners set forth above is true and complete and current
              as of the date above. Responsible individual agrees to notify
              [Financial Institution] of any changes in the information.
              [Financial Institution] is authorized to obtain a credit report of
              the Customer, Responsible Individual and Beneficial Owners from
              any consumer reporting agency to support the Customer's
              eligibility for the accounts and services requested.
            </p>

            <Flex justify="space-between" gap="20px" align="center">
              <div className="action-title">
                Please put your electronic signature
              </div>
              <Form.Item name="Person" style={{ flex: 1 }}>
                <div className={`info_borderText application-inputBox `}>
                  <Input
                    className="info_inputTextClass"
                    placeholder="Person"
                    bordered={false}
                    value={"Adam Smith"}
                    required
                  />
                  <span className="info_span placeholder">Person</span>
                </div>
              </Form.Item>
              <Form.Item name="Signature" style={{ flex: 1 }}>
                <div
                  className={`info_borderText application-inputBox signature`}
                >
                  <Input
                    className="info_inputTextClass"
                    placeholder="Signature "
                    bordered={false}
                    required
                    value={"Adam Smith"}
                  />
                  <span className="info_span placeholder">Signature </span>
                </div>
              </Form.Item>
            </Flex>
          </Flex>

          {/* <Flex className="form-wrrapper" vertical>
            <h3>Eligibility information</h3>
            <Row className="checkbox">
              <Checkbox className="checkbox__item" className="checkbox__item" value={1}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">
                    The business is located within [Financial Institution]’s field of
                    membership
                  </div>
                  <div className="checkbox__title-small">See address above</div>
                </div>
              </Checkbox>
              <Checkbox className="checkbox__item" className="checkbox__item" value={2}>
                <div className="checkbox__title">
                  <div className="checkbox__title-main">
                    Each owner qualifies for membership under the field of
                    membership
                  </div>
                  <div className="checkbox__title-small">
                    Document eligibility in the{" "}
                    <b>Responsible Individual Information section</b>
                  </div>
                </div>
              </Checkbox>
            </Row>
          </Flex> */}
        </Form>
      </Flex>
    </Content>
  );
};

export default Part1;
